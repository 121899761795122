import React, { useState, useEffect } from 'react';
import { Button, Card, Input } from 'reactstrap';
import CardBody from 'reactstrap/lib/CardBody';
import CardHeader from 'reactstrap/lib/CardHeader';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Invoice_setting = () => {
    const [taxRate, setTaxRate] = useState('');
    const [price, setPrice] = useState('');
    const [taxRateFreelance, setTaxRateFreelance] = useState('');
    const [priceFreelance, setPriceFreelance] = useState('');
    const [isJobRunning, setIsJobRunning] = useState(false);

    useEffect(() => {
        const storedIsJobRunning = localStorage.getItem('isJobRunning');
        if (storedIsJobRunning) {
            setIsJobRunning(JSON.parse(storedIsJobRunning));
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('isJobRunning', JSON.stringify(isJobRunning));
    }, [isJobRunning]);

    const handleStartClick = () => {
        axios.post('/api2/start-cron-job')
            .then(response => {
                console.log(response.data);
                setIsJobRunning(true);
            })
            .catch(error => console.error(error));
    };

    const handleStopClick = () => {
        axios.post('/api2/stop-cron-job')
            .then(response => {
                console.log(response.data);
                setIsJobRunning(false);
            })
            .catch(error => console.error(error));
    };


    const handleTaxRateChange = (e) => {
        setTaxRate(e.target.value);
    };

    const handlePriceFormatChange = (e) => {
        setPrice(e.target.value);
    };

    const handleTaxRateChangeFreelance = (e) => {
        setTaxRateFreelance(e.target.value);
    };


    const handlePriceFormatChangeFreelance = (e) => {
        setPriceFreelance(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Here you would save the updated settings to a database or some other storage medium
        console.log('Tax rate:', taxRate);
        console.log('Price format:', price);

        axios.post('/api2/UpdateInvoiceSettings', { taxRate: taxRate, price: price })
            .then(res => {

                console.log(res)
                toast.success('Setting Save successfully!');

                //setInvoices(res.data)
            }).catch(error => {
                console.log(error)
                toast.error('Error loading data.');

            })

    };

    const GetInvoice = () => {

        // Here you would save the updated settings to a database or some other storage medium

        axios.get('/api2/GetInvoiceSettings', { taxRate: taxRate, price: price })
            .then(res => {
                console.log(res)
                //setInvoices(res.data)
                setPrice(res.data[0].priceRate)
                setTaxRate(res.data[0].taxRate)
            }).catch(error => {
                console.log(error)
            })
    };


    const GetInvoiceFreelance = () => {

        // Here you would save the updated settings to a database or some other storage medium

        axios.get('/api2/GetInvoiceSettingsFreelance', { taxRate: taxRate, price: price })
            .then(res => {
                console.log(res)
                //setInvoices(res.data)
                setPriceFreelance(res.data[0].priceRate)
                setTaxRateFreelance(res.data[0].taxRate)
            }).catch(error => {
                console.log(error)
            })
    };

    const handleSubmitFreelance = (e) => {
        e.preventDefault();
        // Here you would save the updated settings to a database or some other storage medium

        axios.post('/api2/UpdateInvoiceSettingsFreelance', { taxRate: taxRateFreelance, price: priceFreelance })
            .then(res => {

                console.log(res)
                toast.success('Setting Save successfully!');

                //setInvoices(res.data)
            }).catch(error => {
                console.log(error)
                toast.error('Error loading data.');

            })

    };

    useEffect(() => {
        GetInvoice()
        GetInvoiceFreelance()

        return () => {
            // setPrice('')
            // setTaxRate('')
            // setPriceFreelance('')
            // setTaxRateFreelance('')
        }
    }, [])




    return (
        <div className="content">
            <ToastContainer />
            <Card>
                <CardHeader>
                    <h2>Preis Einstellungen - Nutzungsgebühr</h2>
                </CardHeader>
                <CardBody>
                    <form onSubmit={handleSubmit}>
                        <div>
                            <label>Steuer :</label>
                            <Input
                                type="number"

                                value={taxRate}
                                onChange={handleTaxRateChange}
                            />
                        </div>
                        <div>
                            <label>Preis :</label>
                            <Input value={price} type="number" onChange={handlePriceFormatChange} />

                        </div>
                        <Button color="primary" type="submit">Speichern</Button>
                        {isJobRunning
                            ? <Button color="warning" onClick={handleStopClick}>Rechnungen Starten</Button>
                            : <Button color="primary" onClick={handleStartClick}>Rechnungen Pausieren</Button>
                        }
                    </form>
                </CardBody>
            </Card>

            <Card>
                <CardHeader>
                    <h2>Preis Einstellungen - Befristete Jobs</h2>
                </CardHeader>
                <CardBody>
                    <form onSubmit={handleSubmitFreelance}>
                        <div>
                            <label>Steuer :</label>
                            <Input
                                type="number"

                                value={taxRateFreelance}
                                onChange={handleTaxRateChangeFreelance}
                            />
                        </div>
                        <div>
                            <label>Preis :</label>
                            <Input value={priceFreelance} type="number" onChange={handlePriceFormatChangeFreelance} />

                        </div>
                        <Button color="primary" type="submit">Speichern</Button>
                       
                    </form>
                </CardBody>
            </Card>

        </div>
    );
};

export default Invoice_setting;