import React from "react";
import axios from 'axios';
import { Link } from "react-router-dom";
import "./style1.css";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Table,
} from "reactstrap";
class Imprint extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      salutationSelect: null,
      titleSelect: null,
      cand : []
    };
  }
  deleteHandle(jobid, e){
    console.log(jobid);
    axios
      .post('/api2/deletelogo',{...this.state,jobid})
      .then(response =>{
        console.log(response)
        var resmsg = response.data.msg;
        this.setState({ resmsg });
        window.location.reload();
      })
      .catch(error =>{
        console.log(error)
        var reserrmsg = error.response.data.msg;
        this.setState({ reserrmsg });
      })
  }
  componentWillMount() {
    
  }
  componentDidMount(){
    const roles = localStorage.getItem('roles')
    const user_id = localStorage.getItem("user_id")
    this.setState({roles})
    console.log("logo start",user_id)
    axios
			.post('/api2/getreferals1',{...this.state,user_id})
			.then(response => {
        console.log(response)
        const cand = response.data;
        this.setState({ cand });
        console.log(cand);
  })
			.catch(error => {
        console.log(error);
    	})
  }

  payRef(refCode, e) {
    console.log(refCode)
      axios
      .post('/api2/payRefBonus',{...this.state,refCode})
      .then(response =>{
        console.log(response)
        this.intervalID = setTimeout(this.componentDidMount.bind(this), 100);
      })
      .catch(error =>{
        //console.log(error)
      })
  }
  render() {
    return (
      <>
     <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h5 className="cstm-heading-backend"><b>Referals</b></h5>
                  <hr></hr>
                </CardHeader>
                <CardBody>
                  <Table responsive>
                    <thead className="text-primary">
                      <tr>
                        <th className="text-left">Refered by</th>
                        <th className="text-left">Refered to</th>
                        <th className="text-left">Bonus Status</th>
                        {/* <th className="text-right">Aktionen</th> */}
                      </tr>
                    </thead>
                    <tbody>
                    {this.state.cand.map((item) => {
                          return (
                            <>
                    <tr>
                             <td>
                                  <label className="text-ubold">
                                    <Link to={`/admin/detail-kandidate/${item.app1app_id}`} target="_blank" rel="noopener noreferrer">
{item.app1fname} {item.app1lname}<br></br>
                                    </Link>
                                    {item.app1username}
                          
                                    </label>
                            </td> 
                            <td className="text-left">
                           
                                  <label className="text-ubold">
                                    <Link to={`/admin/detail-kandidate/${item.app2app_id}`}  target="_blank" rel="noopener noreferrer">
                                    {item.app2fname} {item.app2lname}<br></br>
                                    </Link>  
                                    {item.app2username}
                                     </label>
                        </td>

                        <td className="text-left">
                           
                           <label className="text-ubold">
                        
                        {item.ref_status === "unpaid"? 
                        <> {this.state.roles === 'admin' ?
                      <> 
                      <Button color="warning" onClick={this.payRef.bind(this, item.ref_code)}>
                          Pay Referal bonus</Button>
                      </>
                      :
                      'Unpaid'
                      }</>
                       
                          :
                          item.ref_status} 
                                    </label>
                       </td>
                       
                        </tr>
                        </>
                          )
                        })} 
                    </tbody>
         
                  </Table>
                </CardBody>
                </Card>  
            </Col>
          </Row> 
        </div>
      </>
    );
  }
}

export default Imprint;
