import React from 'react'
// react plugin used to create DropdownMenu for selecting items
//import { Link } from "react-router-dom";
// reactstrap components
import {
  Card,
  CardBody,
  CardFooter,
  Row,
  Col,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownMenu
} from "reactstrap";

const BoxFilter = (prop) => {
  return (
    
    <Col md="">
  <Card className="card-user">
    <div className="image">
      <img
        alt="..."
        src={require("assets/img/bg/damir-bosnjak.jpg")}
      />
    </div>
    <CardBody>
      <div className="author">
        <a href="#pablo" onClick={e => e.preventDefault()}>
          <img
            alt="..."
            className="avatar border-gray"
            src={require("assets/img/faces/HRD.png")}
          />
          <h5 className="title">{prop.contact.title} {prop.contact.lastName}</h5>
        </a>
        <p className="description"><b>E-Mail:</b> {prop.contact.email} </p>
        <p className="description"><b>Telefon:</b> {prop.contact.telefon} </p>
      </div>
      <p className="description text-center">
        <b>Status:</b> 
        {prop.contact.isAktiv ? " Aktiv" : " Gesperrt"}
      </p>
      <p className="description text-center">
        <b>Registrierung:</b> 
        {prop.contact.activationEmail ? " Bestätigt" : " Nicht bestätigt"}
      </p>
      <p className="description text-center">
        <b>Position: </b> 
        {prop.contact.position}
      </p>
      <p className="description text-center">
        <b>Einrichtung:</b> {" "} <br/>
        {prop.contact.institutions.map((i) => {
          return (
            <>
            <span className="">
              {i.label} 
            </span> <br/>
            </>
          )
        })}
        </p>
    </CardBody>
    <CardFooter>
      <hr />
      <div className="button-container">
        <Row className="justify-content-center">
          <Col className="text-center" lg="3" md="12" xs="12">
            <UncontrolledDropdown>
                            <DropdownToggle
                              caret
                              className="btn-round"
                              color="info"
                              data-toggle="dropdown"
                              type="button"
                            >
                              Bearbeiten
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem
                                href="#pablo"
                                onClick={e => e.preventDefault()}
                              >
                                Email
                              </DropdownItem>
                              {prop.contact.isAktiv ? 
                              <DropdownItem
                              href="#pablo"
                              onClick={e => e.preventDefault()}
                            >
                              Sperren
                            </DropdownItem>
                                :
                                <DropdownItem
                                href="#pablo"
                                onClick={e => e.preventDefault()}
                              >
                                Freigeben
                              </DropdownItem>
                                }
                                {prop.contact.handleJob === 0 ? 
                                <DropdownItem
                                href="#pablo"
                                onClick={e => e.preventDefault()}
                              >
                                Löschen
                              </DropdownItem>
                                : 
                                ""
                                }
                                {!prop.contact.activationEmail ? 
                                <DropdownItem
                                href="#pablo"
                                onClick={e => e.preventDefault()}
                              >
                                Registrierungs-Email
                              </DropdownItem>
                                :
                                ""
                                }
                            </DropdownMenu>
                          </UncontrolledDropdown>
            {/* <Link to={"/admin/contact-edit"}>
            <Button color="primary">Bearbeiten</Button>
            </Link> */}
          
          </Col>
        </Row>
      </div>
    </CardFooter>
  </Card>
</Col>
  
  )
}

export default BoxFilter