import React from "react";
import axios from 'axios';
// react plugin used to create charts
// import { Line, Bar, Doughnut } from "react-chartjs-2";
// // react plugin for creating vector maps
//import ReactPaginate from 'react-paginate';

 import { Link,Redirect } from "react-router-dom"
// reactstrap components
import {
  Badge,
  //Input,
  Button,
  Card,
  //CardHeader,
  CardBody,
  Table,
  UncontrolledTooltip
} from "reactstrap";

// import {
//   chartExample1,
//   chartExample2,
//   chartExample3,
//   chartExample4,
//   chartExample5,
//   chartExample6,
//   chartExample7,
//   chartExample8
// } from "variables/charts.jsx";
// import {chartProvision, chartJobs, chartInstitutions} from "variables/data_charts_hm.jsx"
//import { monthNames} from "variables/general.jsx"

// import TableTop from "building_block/table_top"


// import FixedMessage from "components/FixedMessage";
// import { isTemplateMiddle } from "typescript";

// var mapData = {
//   AU: 760,
//   BR: 550,
//   CA: 120,
//   DE: 1300,
//   FR: 540,
//   GB: 690,
//   GE: 200,
//   IN: 200,
//   RO: 600,
//   RU: 300,
//   US: 2920
// };



class JobsByStellen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      countJobs: 0,
      data : [],
      data1 : [],
      data2 : [],
      data3 : [],
      data4 : [],
      msg : '',
      offset: 0,
      perPage: 5,
      currentPage: 0
    };
  }
componentDidMount(){
  console.log(this.props.match.params)
    const { app_id } = this.props.match.params;
   console.log(app_id)
   this.setState({app_id})
   axios.post('/api2/candidatejobsbystellen',{...this.state,app_id})
    .then(response => {
      console.log(response)
      const fname = response.data[0].f_name;
      this.setState({fname})
      const lname = response.data[0].l_name;
      this.setState({lname})
      const haus_no = response.data[0].haus_no;
      this.setState({haus_no})
      const post_code = response.data[0].post_code;
      this.setState({post_code})
      const city = response.data[0].city;
      this.setState({city})
      const telephone = response.data[0].telephone;
      this.setState({telephone})
      const data = response.data
      this.setState({data})
      console.log(data)
    
    })
    .catch(error => {
      console.log(error.response);
    })
   /////////////////////////////////////////
   axios.post('/api2/jobsbystellen',{...this.state,app_id})
    .then(response => {
      console.log(response)
      const data = response.data.jobs;
      this.setState({ data });
      console.log(data)
      ////////////
      const data1 = response.data.appliedjobs;
      // localStorage.setItem('hosp', JSON.stringify(this.state.hosp));
      this.setState({ data1 });
      console.log(data1)
    })
    .catch(error => {
      console.log(error.response);
    })
    //////////////////////////////////////////////////
    axios.post('/api2/jobsbysearch',{...this.state,app_id})
    .then(response => {
      console.log(response)
      const data2 = response.data
      // localStorage.setItem('hosp', JSON.stringify(this.state.hosp));
      this.setState({ data2 });
      console.log(data2)
    })
    .catch(error => {
      console.log(error.response);
    })
    ///////////////////////////
    axios.post('/api2/jobsbyadmin',{...this.state,app_id})
    .then(response => {
      console.log(response)
      const data4 = response.data
      // localStorage.setItem('hosp', JSON.stringify(this.state.hosp));
      this.setState({ data4 });
      console.log(data4)
      const data5 = response.data.appliedjobs;
      // localStorage.setItem('hosp', JSON.stringify(this.state.hosp));
      this.setState({ data5 });
      console.log(data5)
    })
    .catch(error => {
      console.log(error.response);
    })
    ///////////////////////////
    axios.get('/api2/getnotes2')
    .then(response => {
      console.log(response)
      //this.setState({redirect : true})
      const data3 = response.data
      // localStorage.setItem('hosp', JSON.stringify(this.state.hosp));
      this.setState({ data3 });
      console.log(data3)
    })
    .catch(error => {
      console.log(error.response);
    })
}
 deleteHandler (noteid,e){
//  // e.preventDefault();
   console.log("delete called",noteid)
  axios.post('/api2/deljob',{...this.state,noteid})
    .then(response => {
      console.log(response)
      this.setState({redirect : true})
      //const data2 = response.data
      // localStorage.setItem('hosp', JSON.stringify(this.state.hosp));
      //this.setState({ data2 });
      //console.log(data2)
    })
    .catch(error => {
      console.log(error.response);
    })
 }
 closeHandler (appid,jobid,e){
  //  // e.preventDefault();
     console.log("close called",appid,jobid)
    axios.post('/api2/closematchjob',{...this.state,appid,jobid})
      .then(response => {
        console.log(response)
        window.location.reload();
        //this.setState({redirect : true})
        //const data2 = response.data
        // localStorage.setItem('hosp', JSON.stringify(this.state.hosp));
        //this.setState({ data2 });
        //console.log(data2)
      })
      .catch(error => {
        console.log(error.response);
      })
  }
   closeHandler1 (appid,jobid,e){
  //  // e.preventDefault();
     console.log("close called",appid,jobid)
    axios.post('/api2/closematchjob1',{...this.state,appid,jobid})
      .then(response => {
        console.log(response)
        window.location.reload();
        //this.setState({redirect : true})
        //const data2 = response.data
        // localStorage.setItem('hosp', JSON.stringify(this.state.hosp));
        //this.setState({ data2 });
        //console.log(data2)
      })
      .catch(error => {
        console.log(error.response);
      })
   }
handlePageClick = (e) => {
  const selectedPage = e.selected;
  const offset = selectedPage * this.state.perPage;

  this.setState({
      currentPage: selectedPage,
      offset: offset
  }, () => {
      this.componentDidMount()
  });

}; 
  render() {
    // var d = new Date()
    // var actualMonth = monthNames[d.getMonth()]
    const { redirect } = this.state;
     if (redirect) {
       return <Redirect to='/admin/candidatebystellen'/>;
     }
    return (
      <>
        <div className="content">
        <Card><CardBody>
        <h5 className="cstm-heading-backend"><b>Kandidat</b></h5>
        {this.state.fname} {this.state.lname}<br />
        {this.state.haus_no}<br />
        {this.state.post_code}, {this.state.city}<br />
        {this.state.telephone}<br />
        </CardBody>
               <CardBody>
                  <h5 className="cstm-heading-backend"><b>Jobs anhand Stellenpräferenzen </b></h5>
                  <br></br>
                  <Table responsive>
                    <thead className="text-primary">
                      <tr>
                        <th className="text-left">Online Seit</th>
                        <th className="text-left">Online Bis</th>
                        <th className="text-left">Job Title</th>
                        <th className="text-left">Unternehmen</th>
                        <th className="text-left">Bewerbungsstatus</th>
                        <th className="text-left">gesendet am</th>
                        <th className="text-left">Status</th>
                        <th className="text-left">Aktion</th>
                      
                      </tr>
                    </thead>
                    <tbody>
                  
                    {this.state.data.map(item => {
                      return (
                        <>  
                   
                        <tr>
                               <td className="text-left">

<medium className="">
{item.created_date}
</medium> 
<br/>

</td>

<td className="text-left">

<medium className="">
{/* <Badge className="mr-1" color="success" pill>
{item.job_status} 
</Badge>
<br></br> bis  */}
{item.valid_till}
</medium> 
<br/>

</td>

<br/>
<Link to = {`/admin/job-profile/${item.job_id}/${item.app_id}`} target="_blank"> 
<Badge className="mr-1" color="primary" pill>
{item.job_function} <br></br> (m/w/d) <br></br> {item.profession}
</Badge></Link>
<td className="text-left">

<Link to = {`/admin/kontaktpersondetails/${item.hosp_id}`} target="_blank"> <medium>
  <b>{item.company_name}</b> <br></br>
   {item.address}  {item.haus_no}, {item.zip_code} {item.city}
  </medium></Link>
  

<br/>

</td>
<td className="text-left">
{this.state.data1.map(item1 => {
                      return (
                        <>  
                        {item.job_id === item1.job_id ? 
                        <span>
                        <Badge className="mr-1" color="success" pill>
                        {item1.candidate_status} am {item1.action_date}
                          </Badge><br />
                          {item1.hosp_contract} {item1.contract_date_hosp}<br />
                          {item1.app_contract} {item1.contract_date_candid}
                          </span>                     
                        :                    
                        null
                        }
</>
                                 )
                               })} 
</td>  
<td className="text-left">
{item.sent_date}
</td>
<td className="text-left">
{item.match_status}
<br></br>
{this.state.data3.map(item1 => {
                      return (
                        <>  
                        {item.job_id === item1.job_id ? 
                        <span>
                        <Badge className="mr-1" color="success" pill>
                        {item1.cnt} 
                          </Badge><br />
                          </span>                     
                        :                    
                        null
                        }
</>
                                 )
                               })} 
</td>
<td>
<Button
                            className="btn-icon"
                            color="dark"
                            id={"tooltip366246640"+item.job_id}
                            size="sm"
                            type="button"
                            onClick={this.closeHandler.bind(this,this.state.app_id,item.job_id)}
                          >
                            <i className="fa fa-toggle-off" />
                          </Button>{" "}
                          <UncontrolledTooltip
                            delay={0}
                            target={"tooltip366246640"+item.job_id}
                          >
                            Close
                          </UncontrolledTooltip>
                          <Link to={`/admin/notes1/${item.job_id}/${this.state.app_id}`}>
                          <Button
                            className="btn-icon"
                            color="success"
                            id={"tooltip366246651"+item.job_id}
                            size="sm"
                            type="button"
                            //onClick={this.acceptHandle.bind(this, item.user_id)}
                          >
                            <i className="fa fa-edit" />
                          </Button>{" "}
                          <UncontrolledTooltip
                            delay={0}
                            target={"tooltip366246651"+item.job_id}
                          >
                            Add Note
                          </UncontrolledTooltip>
                          </Link>
                      </td> 
                              </tr>
                                 </>
                                 )
                               })} 
                    </tbody>
                    
                  </Table>
               </CardBody>
                </Card>
            
                <Card><CardBody>
       
        </CardBody>
               <CardBody>
                  <h5 className="cstm-heading-backend"><b>Jobs durch die Jobsuche </b></h5>
                  <br></br>
                  <Table responsive>
                    <thead className="text-primary">
                      <tr>
                        <th className="text-left">Online Seit</th>
                        <th className="text-left">Online Bis</th>
                        <th className="text-left">Job Title</th>
                        <th className="text-left">Unternehmen</th>
                        <th className="text-left">Bewerbungsstatus</th>
                        <th className="text-left">Aktion</th>
                       
                      
                      </tr>
                    </thead>
                    <tbody>
                  
                    {this.state.data2.map(item => {
                      return (
                        <>  
                   
                        <tr>
                               <td className="text-left">

<medium className="">
{item.created_date}
</medium> 
<br/>

</td>


<td className="text-left">

<medium className="">
{/* <Badge className="mr-1" color="success" pill>
{item.job_status} 
</Badge>
<br></br> bis  */}
{item.valid_till}
</medium> 
<br/>

</td>

<br/>
<Link to = {`/admin/job-profile/${item.job_id}/${item.app_id}`} target="_blank"> 
<Badge className="mr-1" color="primary" pill>
{item.job_function} (m/w/d)  {item.profession}
</Badge></Link>
<td className="text-left">

<Link to = {`/admin/kontaktpersondetails/${item.hosp_id}`} target="_blank"> <medium>
  <b>{item.company_name}</b> <br></br>
   {item.address}  {item.haus_no}, {item.zip_code} {item.city}
  </medium></Link>
  

<br/>

</td>
<td className="text-left">

                       
                        <span>
                        <Badge className="mr-1" color="success" pill>
                        {item.candidate_status} am {item.action_date}
                          </Badge><br />
                          {item.hosp_contract} {item.contract_date_hosp}<br />
                          {item.app_contract} {item.contract_date_candid}
                          </span>                     
                       

                            </td>
                            <td>
<Button
                            className="btn-icon"
                            color="dark"
                            id={"tooltip366246640"+item.job_id}
                            size="sm"
                            type="button"
                            onClick={this.closeHandler1.bind(this,this.state.app_id,item.job_id)}
                          >
                            <i className="fa fa-toggle-off" />
                          </Button>{" "}
                          <UncontrolledTooltip
                            delay={0}
                            target={"tooltip366246640"+item.job_id}
                          >
                            Close
                          </UncontrolledTooltip>
                          {/* <Link to={`/admin/notes1/${item.job_id}/${this.state.app_id}`}>
                          <Button
                            className="btn-icon"
                            color="success"
                            id={"tooltip366246651"+item.job_id}
                            size="sm"
                            type="button"
                            //onClick={this.acceptHandle.bind(this, item.user_id)}
                          >
                            <i className="fa fa-edit" />
                          </Button>{" "}
                          <UncontrolledTooltip
                            delay={0}
                            target={"tooltip366246651"+item.job_id}
                          >
                            Add Note
                          </UncontrolledTooltip>
                          </Link> */}
                      </td>

                              </tr>
                                 </>
                                 )
                               })} 
                    </tbody>
                    
                  </Table>
               </CardBody>
                </Card>
             
                <Card><CardBody>
       
        </CardBody>
               <CardBody>
                  <h5 className="cstm-heading-backend"><b>Jobs vom Hello-Doctor-Team </b></h5>
                  <br></br>
                  <Table responsive>
                    <thead className="text-primary">
                      <tr>
                        <th className="text-left">Online Seit</th>
                        <th className="text-left">Online Bis</th>
                        <th className="text-left">Job Title</th>
                        <th className="text-left">Unternehmen</th>
                        <th className="text-left">Bewerbungsstatus</th>
                        <th className="text-left">gesendet am</th>
                        {/* <th className="text-left">job_status</th> */}
                        <th className="text-left">Aktion</th>
                      
                      </tr>
                    </thead>
                    <tbody>
                  
                    {this.state.data4.map(item => {
                      return (
                        <>  
                   
                        <tr>
                               <td className="text-left">

<medium className="">
{item.created_date}
</medium> 
<br/>

</td>

<td className="text-left">

<medium className="">
{/* <Badge className="mr-1" color="success" pill>
{item.job_status} 
</Badge>
<br></br> bis  */}
{item.valid_till}
</medium> 
<br/>

</td>

<br/>
<Link to = {`/admin/job-profile/${item.job_id}/${item.app_id}`} target="_blank"> 
<Badge className="mr-1" color="primary" pill>
{item.job_function} <br></br> (m/w/d) <br></br> {item.profession}
</Badge></Link>
<td className="text-left">

<Link to = {`/admin/kontaktpersondetails/${item.hosp_id}`} target="_blank"> <medium>
  <b>{item.company_name}</b> <br></br>
   {item.address}  {item.haus_no}, {item.zip_code} {item.city}
  </medium></Link>
  

<br/>

</td>
<td className="text-left">
{this.state.data1.map(item1 => {
                      return (
                        <>  
                        {item.job_id === item1.job_id ? 
                        <span>
                        <Badge className="mr-1" color="success" pill>
                        {item1.candidate_status} am {item1.action_date}
                          </Badge><br />
                          {item1.hosp_contract} {item1.contract_date_hosp}<br />
                          {item1.app_contract} {item1.contract_date_candid}
                          </span>                     
                        :                    
                        null
                        }
</>
                                 )
                               })} 
</td>  
<td className="text-left">
{item.sent_date}
</td>
{/* <td className="text-left">
{item.job_status}
<br></br>
{this.state.data3.map(item1 => {
                      return (
                        <>  
                        {item.job_id === item1.job_id ? 
                        <span>
                        <Badge className="mr-1" color="success" pill>
                        {item1.cnt} 
                          </Badge><br />
                          </span>                     
                        :                    
                        null
                        }
</>
                                 )
                               })} 
</td> */}
<td>
<Button
                            className="btn-icon"
                            color="danger"
                            id="tooltip366246640"
                            size="sm"
                            type="button"
                            onClick={this.deleteHandler.bind(this, item.sendjob_id)}
                          >
                            <i className="fa fa-trash" />
                          </Button>{" "}
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip366246640"
                          >
                            Delete
                          </UncontrolledTooltip>
                          {/* <Link to={`/admin/notes1/${item.job_id}/${this.state.app_id}`}>
                          <Button
                            className="btn-icon"
                            color="success"
                            id="tooltip366246651"
                            size="sm"
                            type="button"
                            //onClick={this.acceptHandle.bind(this, item.user_id)}
                          >
                            <i className="fa fa-edit" />
                          </Button>{" "}
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip366246651"
                          >
                            Add Note
                          </UncontrolledTooltip>
                          </Link> */}
                      </td> 
                              </tr>
                                 </>
                                 )
                               })} 
                    </tbody>
                    
                  </Table>
               </CardBody>
                </Card>
        </div>
      </>
    );
  }
}

export default JobsByStellen;