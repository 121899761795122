 export const skills = [
    { value: 'doctor7', label: 'Akupunktur', link: 'doctor1' },
    { value: 'doctor8', label: 'Allergologie', link: 'doctor1' },
    { value: 'doctor9', label: 'Allgemeinchirurgie', link: 'doctor1' },
    { value: 'doctor10', label: 'Allgemeinmedizin', link: 'doctor1' },
    { value: 'doctor11', label: 'Anatomie', link: 'doctor1' },
    { value: 'doctor12', label: 'Andrologie', link: 'doctor1' },
    { value: 'doctor13', label: 'Anästhesiologie', link: 'doctor1' },
    { value: 'doctor14', label: 'Arbeitsmedizin', link: 'doctor1' },
    { value: 'doctor15', label: 'Augenheilkunde', link: 'doctor1' },
    { value: 'doctor16', label: 'Biochemie', link: 'doctor1' },
    { value: 'doctor17', label: 'Chirurgie', link: 'doctor1' },
    { value: 'doctor18', label: 'Dermatohistologie', link: 'doctor1' },
    { value: 'doctor19', label: 'Diabetologie', link: 'doctor1' },
    { value: 'doctor20', label: 'Flugmedizin', link: 'doctor1' },
    { value: 'doctor21', label: 'Forensische Psychiatrie', link: 'doctor1' },
    { value: 'doctor22', label: 'Gefäßchirugie', link: 'doctor1' },
    { value: 'doctor23', label: 'Geriatrie', link: 'doctor1' },
    { value: 'doctor24', label: 'Gynäkologie und Geburtshilfe', link: 'doctor1' },
    { value: 'doctor25', label: 'Gynäkologische Endokrinologie und Reproduktionsmed', link: 'doctor1' },
    { value: 'doctor26', label: 'Gynäkologische Exfoliativ-Zytologie', link: 'doctor1' },
    { value: 'doctor27', label: 'Gynäkologische Onkologie', link: 'doctor1' },
    { value: 'doctor28', label: 'Hals-Nasen-Ohrenheilkunde (HNO)', link: 'doctor1' },
    { value: 'doctor29', label: 'Handchirurgie', link: 'doctor1' },
    { value: 'doctor30', label: 'Haut- und Geschlechtskrankheiten', link: 'doctor1' },
    { value: 'doctor31', label: 'Herzchirurgie', link: 'doctor1' },
    { value: 'doctor32', label: 'Homöopathie', link: 'doctor1' },
    { value: 'doctor33', label: 'Humangenetik', link: 'doctor1' },
    { value: 'doctor34', label: 'Hygiene und Umweltmedizin', link: 'doctor1' },
    { value: 'doctor35', label: 'Hämostaseologie', link: 'doctor1' },
    { value: 'doctor36', label: 'Infektiologie', link: 'doctor1' },
    { value: 'doctor37', label: 'Innere Medizin', link: 'doctor1' },
    { value: 'doctor38', label: 'Innere Medizin und Angiologie', link: 'doctor1' },
    { value: 'doctor39', label: 'Innere Medizin und Endokrinologie und Diabetologie', link: 'doctor1' },
    { value: 'doctor40', label: 'Innere Medizin und Gastroenterologie', link: 'doctor1' },
    { value: 'doctor41', label: 'Innere Medizin und Hämatologie und Onkologie', link: 'doctor1' },
    { value: 'doctor42', label: 'Innere Medizin und Kardiologie', link: 'doctor1' },
    { value: 'doctor43', label: 'Innere Medizin und Nephrologie', link: 'doctor1' },
    { value: 'doctor44', label: 'Innere Medizin und Pneumologie', link: 'doctor1' },
    { value: 'doctor45', label: 'Innere Medizin und Rheumatologie', link: 'doctor1' },
    { value: 'doctor46', label: 'Intensivmedizin', link: 'doctor1' },
    { value: 'doctor47', label: 'Interventionelle Kardiologie', link: 'doctor1' },
    { value: 'doctor48', label: 'Kinder- und Jugendmedizin', link: 'doctor1' },
    { value: 'doctor49', label: 'Kinder- und Jugendpsychiatrie und -psychotherapie', link: 'doctor1' },
    { value: 'doctor50', label: 'Kinder-Endokrinologie und -Diabetologie', link: 'doctor1' },
    { value: 'doctor51', label: 'Kinder-Gastroenterologie', link: 'doctor1' },
    { value: 'doctor52', label: 'Kinder-Hämatologie und -Onkologie', link: 'doctor1' },
    { value: 'doctor53', label: 'Kinder-Kardiologie', link: 'doctor1' },
    { value: 'doctor54', label: 'Kinder-Nephrologie, Kinder-Orthopädie', link: 'doctor1' },
    { value: 'doctor55', label: 'Kinder-Pneumologie', link: 'doctor1' },
    { value: 'doctor56', label: 'Kinder-Rheumatologie', link: 'doctor1' },
    { value: 'doctor57', label: 'Kinderchirurgie', link: 'doctor1' },
    { value: 'doctor58', label: 'Kinderradiologie', link: 'doctor1' },
    { value: 'doctor59', label: 'Klinische Pharmakologie', link: 'doctor1' },
    { value: 'doctor60', label: 'Krankenhaushygiene', link: 'doctor1' },
    { value: 'doctor61', label: 'Labordiagnostik-fachgebunden', link: 'doctor1' },
    { value: 'doctor62', label: 'Labormedizin', link: 'doctor1' },
    { value: 'doctor63', label: 'Magnetresonanztomographie-fachgebunden', link: 'doctor1' },
    { value: 'doctor64', label: 'Manuelle Medizin / Chirotherapie', link: 'doctor1' },
    { value: 'doctor65', label: 'Medikamentöse Tumortherapie', link: 'doctor1' },
    { value: 'doctor66', label: 'Medizinische Informatik', link: 'doctor1' },
    { value: 'doctor67', label: 'Mikrobiologie', link: 'doctor1' },
    { value: 'doctor68', label: 'Mund-, Kiefer- und Gesichtschirurgie', link: 'doctor1' },
    { value: 'doctor69', label: 'Naturheilverfahren', link: 'doctor1' },
    { value: 'doctor70', label: 'Neonatologie', link: 'doctor1' },
    { value: 'doctor71', label: 'Neurochirurgie', link: 'doctor1' },
    { value: 'doctor72', label: 'Neurologie', link: 'doctor1' },
    { value: 'doctor73', label: 'Neuropädiatrie', link: 'doctor1' },
    { value: 'doctor74', label: 'Neuroradiologie', link: 'doctor1' },
    { value: 'doctor75', label: 'Notfallmedizin', link: 'doctor1' },
    { value: 'doctor76', label: 'Nuklearmedizin', link: 'doctor1' },
    { value: 'doctor77', label: 'Orthopädie und Unfallchirurgie', link: 'doctor1' },
    { value: 'doctor78', label: 'Orthopädische Rheumatologie', link: 'doctor1' },
    { value: 'doctor79', label: 'Palliativmedizin', link: 'doctor1' },
    { value: 'doctor80', label: 'Pathologie', link: 'doctor1' },
    { value: 'doctor81', label: 'Pharmakologie', link: 'doctor1' },
    { value: 'doctor82', label: 'Pharmakologie und Texikologie', link: 'doctor1' },
    { value: 'doctor83', label: 'Phlebologie', link: 'doctor1' },
    { value: 'doctor84', label: 'Physikalische Therapie und Balneologie', link: 'doctor1' },
    { value: 'doctor85', label: 'Physikalische und Rehabilitative Medizin', link: 'doctor1' },
    { value: 'doctor86', label: 'Physiologie', link: 'doctor1' },
    { value: 'doctor87', label: 'Plastische Operationen', link: 'doctor1' },
    { value: 'doctor88', label: 'Plastische und Ästhetische Chirurgie', link: 'doctor1' },
    { value: 'doctor89', label: 'Proktologie', link: 'doctor1' },
    { value: 'doctor90', label: 'Psychiatrie und Psychotherapie', link: 'doctor1' },
    { value: 'doctor91', label: 'Psychoanalyse', link: 'doctor1' },
    { value: 'doctor92', label: 'Psychosomatische Medizin und Psychotherapie', link: 'doctor1' },
    { value: 'doctor93', label: 'Psychotherapie-fachgebunden', link: 'doctor1' },
    { value: 'doctor94', label: 'Radiologie', link: 'doctor1' },
    { value: 'doctor95', label: 'Rechtsmedizin', link: 'doctor1' },
    { value: 'doctor96', label: 'Rhytmologie', link: 'doctor1' },
    { value: 'doctor97', label: 'Schlafmedizin', link: 'doctor1' },
    { value: 'doctor98', label: 'Sozialmedizin', link: 'doctor1' },
    { value: 'doctor99', label: 'Spezielle Geburtshilfe und Perinatalmedizin', link: 'doctor1' },
    { value: 'doctor100', label: 'Spezielle Orthopädische Chirurgie', link: 'doctor1' },
    { value: 'doctor101', label: 'Spezielle Schmerztherapie', link: 'doctor1' },
    { value: 'doctor102', label: 'Spezielle Unfallchirurgie', link: 'doctor1' },
    { value: 'doctor103', label: 'Sportmedizin', link: 'doctor1' },
    { value: 'doctor104', label: 'Sprach-, Stimm- und kindliche Hörstörungen', link: 'doctor1' },
    { value: 'doctor105', label: 'Strahlentherapie', link: 'doctor1' },
    { value: 'doctor106', label: 'Suchtmedizinische Grundversorgung', link: 'doctor1' },
    { value: 'doctor107', label: 'Thoraxchirurgie', link: 'doctor1' },
    { value: 'doctor108', label: 'Transfusionsmedizin', link: 'doctor1' },
    { value: 'doctor109', label: 'Tropenmedizin', link: 'doctor1' },
    { value: 'doctor110', label: 'Urologie', link: 'doctor1' },
    { value: 'doctor111', label: 'Viszeralchirurgie', link: 'doctor1' },
    { value: 'doctor112', label: 'Zahnmedizin', link: 'doctor1' },
    { value: 'doctor113', label: 'Ärztliches Qualitätsmanagement', link: 'doctor1' },
    { value: 'doctor114', label: 'Öffentliche Gesundheitswesen', link: 'doctor1' },
  ]