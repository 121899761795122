import React from "react";

// react plugin used to create DropdownMenu for selecting items
//import Select from "react-select";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Collapse,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Row,
  Col,
  Table,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Badge
} from "reactstrap";

import TableCandidateList from "building_block/table_candidate_job_details"
import TableCandidateDatabase from "building_block/table_candidate_job_details_database"
import ContactCard from "building_block/contact"
import Ternure from "@material-ui/icons/BusinessCenter";
import Time from "@material-ui/icons/Timer";
import Hospital from "@material-ui/icons/LocalHospitalSharp";
import Money from "@material-ui/icons/MonetizationOnOutlined";
import Map from "@material-ui/icons/MapRounded";

//import data
import { jobs, candidates_table, contactPersons } from "variables/general.jsx"


class DetailJob extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      horizontalTabs: "HelloMatch",
      jobTypeSingleSelect: null,
      qualificationSingleSelect: null,
      specializationMultipleSelect: null,
      workingTimeMultipleSelect: null,
      workingTernureMultipleSelect: null,
      countrySingleSelect: null,
      citySingleSelect: null,
      radiusSingleSelect: null,
      sortSalarySelect: null,
      sortExperienceSelect: null,
      openedCollapses: ["collapseOne", "collapse1"],
    };
  }
  // with this function we create an array with the opened collapses
  // it is like a toggle function for all collapses from this page
  collapsesToggle = collapse => {
    let openedCollapses = this.state.openedCollapses;
    if (openedCollapses.includes(collapse)) {
      this.setState({
        openedCollapses: openedCollapses.filter(item => item !== collapse)
      });
    } else {
      openedCollapses.push(collapse);
      this.setState({
        openedCollapses: openedCollapses
      });
    }
  };
  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <Row>

                    {jobs.map((job) => {
                      return (
                        <>
                          <Col md="6">
                            <h1 className="text-bold">
                              {job.jobType}
                            </h1>
                            <h1 className="text-bold">
                              {job.qualification} (m/w/d) {job.speciality}
                            </h1>
                            <Badge color="success" pill className="mr-1">
                              {job.qualificationExperienced} Jahre berufserfahrung in {job.qualification}
                            </Badge> <br />
                            <Badge color="success" pill className="mr-1">
                              {job.specialityExperienced} Jahre berufserfahrung in {job.speciality}
                            </Badge>
                            <br />
                            <p className="description">
                              {job.jobDescription}
                            </p>
                            <Row>
                              <Col md="4">
                                <Ternure />
                                <br />
                                <span className="small">
                                  {job.workingTernure}
                                </span>

                              </Col>
                              <Col md="4">
                                <Time /><br />
                                <span className="small">
                                  {job.workingTime}
                                </span>
                              </Col>
                              <Col md="4">
                                <b>Veröffentlicht am:</b>  {job.dateCreated} <br />
                                <b>Gültig bis:</b>  {job.dateExpired} <br />
                                <b>Status:</b>  {job.status}
                              </Col>

                            </Row>
                            <br />
                            <Row>
                              <Col md="4">
                                <Hospital />
                                <br />
                                <span className="small">
                                  {job.institutionType}
                                </span>

                              </Col>
                              <Col md="4">
                                <Money />
                                <br />
                                <span className="small">
                                  € {job.salary},-
                                </span>

                              </Col>
                              <Col md="4">
                                <b>Approbation:</b>  {job.approbation} <br />
                                <b>Berufserlaubnis:</b>  {job.occupationPermit}

                              </Col>

                            </Row>
                            <br />
                            <Row>
                              <Col md="4">
                                <Map />
                                <br />
                                <span className="small">
                                  {job.adresse.map((a) => {
                                    return (
                                      <>
                                        {a.street}, {a.zipCode}, {a.city} <br />
                                        <i>
                                          {a.region}, {a.country}
                                        </i>
                                      </>
                                    )
                                  })}
                                </span>
                              </Col>
                            </Row>
                          </Col>
                          <Col md="6" className="pull-right text-right">
                            <UncontrolledDropdown className="text-right pull-right">
                              <DropdownToggle
                                caret
                                className="btn-round text-right pull-right"
                                color="info"
                                data-toggle="dropdown"
                                type="button"
                              >
                                Bearbeiten
                              </DropdownToggle>
                              <DropdownMenu className="text-right pull-right">
                                {job.status === "aktiv" ? <DropdownItem
                                  href="#pablo"
                                  onClick={e => e.preventDefault()}
                                >
                                  Deaktivieren
                                </DropdownItem> :
                                  <DropdownItem
                                    href="#pablo"
                                    onClick={e => e.preventDefault()}
                                  >
                                    Aktivieren
                                  </DropdownItem>}
                                {job.candidateInsterested === 0 ?
                                  <DropdownItem
                                    href="#pablo"
                                    onClick={e => e.preventDefault()}
                                  >
                                    Löschen
                                  </DropdownItem>
                                  :
                                  ""
                                }
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </Col>
                        </>
                      )
                    })}
                  </Row>
                  <hr />
                  <br />
                  <br />
                  <h5>Bewerbungen</h5>
                </CardHeader>
                <CardBody>
                  <div className="nav-tabs-navigation">
                    <div className="nav-tabs-wrapper">
                      <Nav id="tabs" role="tablist" tabs>
                        <NavItem>
                          <NavLink
                            aria-expanded={this.state.horizontalTabs === "HelloMatch"}
                            data-toggle="tab"
                            href="#pablo"
                            role="tab"
                            className={
                              this.state.horizontalTabs === "HelloMatch"
                                ? "active"
                                : ""
                            }
                            onClick={() =>
                              this.setState({ horizontalTabs: "HelloMatch" })
                            }
                          >
                            HelloMatch
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            aria-expanded={
                              this.state.horizontalTabs === "Pool Talent Database"
                            }
                            data-toggle="tab"
                            href="#pablo"
                            role="tab"
                            className={
                              this.state.horizontalTabs === "Pool Talent Database"
                                ? "active"
                                : ""
                            }
                            onClick={() =>
                              this.setState({ horizontalTabs: "Pool Talent Database" })
                            }
                          >
                            Talent Pool Database
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            aria-expanded={
                              this.state.horizontalTabs === "Self apply"
                            }
                            data-toggle="tab"
                            href="#pablo"
                            role="tab"
                            className={
                              this.state.horizontalTabs === "Self apply"
                                ? "active"
                                : ""
                            }
                            onClick={() =>
                              this.setState({ horizontalTabs: "Self apply" })
                            }
                          >
                            Direktbewerbung
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </div>
                  </div>
                  <TabContent
                    className="text-center"
                    id="my-tab-content"
                    activeTab={this.state.horizontalTabs}
                  >
                    <TabPane tabId="HelloMatch" role="tabpanel">
                      <Row>
                        <Col md="12">
                          <Card>
                            <CardHeader>
                            </CardHeader>
                            <CardBody>
                              <Table responsive>
                                <thead className="text-primary">
                                  <tr>
                                    <th className="text-left">#</th>
                                    <th className="text-left">Name</th>
                                    <th className="text-left">Kompetenzen</th>
                                    <th className="text-right">Aktionen</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {candidates_table.map((item) => {
                                    return (
                                      <tr>
                                        <TableCandidateList item={item} />
                                      </tr>
                                    )
                                  })}
                                </tbody>
                              </Table>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="Pool Talent Database" role="tabpanel">
                      <Row>
                        <Col md="12">
                          <Card>
                            <CardBody>
                              <Table responsive>
                                <thead className="text-primary">
                                  <tr>
                                    <th className="text-left">#</th>
                                    <th className="text-left">Name</th>
                                    <th className="text-left">Kompetenzen</th>
                                    <th className="text-left">Details</th>
                                    <th className="text-left">Gehaltvorstellung</th>
                                    <th className="text-right">Aktionen</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {candidates_table.map((item) => {
                                    return (
                                      <tr>
                                        <TableCandidateDatabase item={item} />
                                      </tr>
                                    )
                                  })}
                                </tbody>
                              </Table>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="Self apply" role="tabpanel">
                      <Row>
                        <Col md="12">
                          <Card>
                            <CardHeader>
                            </CardHeader>
                            <CardBody>
                              <Table responsive>
                                <thead className="text-primary">
                                  <tr>
                                    <th className="text-left">#</th>
                                    <th className="text-left">Name</th>
                                    <th className="text-left">Kompetenzen</th>
                                    <th className="text-right">Aktionen</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {candidates_table.map((item) => {
                                    return (
                                      <tr>
                                        <TableCandidateList item={item} />
                                      </tr>
                                    )
                                  })}
                                </tbody>
                              </Table>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </CardBody>
                <Card>
                  <CardBody>
                    <div
                      aria-multiselectable={true}
                      className="card-collapse"
                      id="accordion"
                      role="tablist"
                    >
                      <Card className="card-plain">
                        <CardHeader role="tab">
                          <h3 className="text-center">
                            <a
                              aria-expanded={this.state.openedCollapses.includes(
                                "collapseOne"
                              )}
                              href="#pablo"
                              data-parent="#accordion"
                              data-toggle="collapse"
                              onClick={() => this.collapsesToggle("collapseOne")}
                            >
                              Ansprechpartner{" "}
                              <i className="nc-icon nc-minimal-down" />
                            </a>
                          </h3>
                        </CardHeader>
                        <Collapse
                          role="tabpanel"
                          isOpen={this.state.openedCollapses.includes(
                            "collapseOne"
                          )}
                        >
                          <CardBody>
                            <Row className="justify-content-center">
                              {contactPersons.map((contacts) => {
                                return (
                                  <>
                                    <ContactCard contact={contacts} />
                                  </>
                                )
                              })}
                            </Row>
                          </CardBody>
                        </Collapse>
                      </Card>
                    </div>
                  </CardBody>
                </Card>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default DetailJob;
