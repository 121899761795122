import React from "react";
import Select from 'react-select'
import defaultImage from "assets/img/default-avatar.png";
import defaultAvatar from "assets/img/placeholder.jpg";
import axios from 'axios'
import {Redirect } from "react-router-dom";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import {
  Row,
  Col,
  Input,
  Form,
  Button,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  FormGroup,
  Badge
} from "reactstrap";

//react-select css
const customStyles = {
  control: base => ({
    ...base,
    height: 55,
    width: '103%',

    padding: '0px 0px',
    fontSize: '13px',
    top:'42%',
    
  }),

  
};
const states = [
  {value: 'Baden-Württemberg', label: 'Baden-Württemberg', id: "1"},
  {value: 'Bayern', label: 'Bayern', id: "2"},
  {value: 'Berlin', label: 'Berlin', id: "3"},
  {value: 'Brandenburg', label: 'Brandenburg', id: "4"},
  {value: 'Bremen', label: 'Bremen', id: "5"},
  {value: 'Hamburg', label: 'Hamburg', id: "6"},
  {value: 'Hessen', label: 'Hessen', id: "7"},
  {value: 'Mecklenburg-Vorpommern', label: 'Mecklenburg-Vorpommern', id: "8"},
  {value: 'Niedersachsen', label: 'Niedersachsen', id: "9"},
  {value: 'Nordrhein-Westfalen', label: 'Nordrhein-Westfalen', id: "10"},
  {value: 'Rhineland-Pfalz', label: 'Rhineland-Pfalz', id: "11"},
  {value: 'Saarland', label: 'Saarland', id: "12"},
  {value: 'Sachsen', label: 'Sachsen', id: "13"},
  {value: 'Sachsen-Anhalt', label: 'Sachsen-Anhalt', id: "14"},
  {value: 'Schleswig-Holstein', label: 'Schleswig-Holstein', id: "15"},
  {value: 'Thüringen', label: 'Thüringen', id: "16"},
  // {value: 'Deutschlandweit', label: 'Deutschlandweit', id: "17"}
      ]
const opt = [
  {
    id: "1",
    label: "Arzt",
    value: "A"
  }
]
const opt2 = [
  { value: 'doctor1', label: 'Assistenzarzt', link: 'A' },
  { value: 'doctor2', label: 'Facharzt', link: 'A' },
  { value: 'doctor3', label: 'Oberarzt', link: 'A' },
  { value: 'doctor4', label: 'Ltd, Oberarzt', link: 'A' },
  { value: 'doctor5', label: 'Chefarzt', link: 'A' }
];
const opt3 = [
  
  { value: 'doctor7', label: 'Akupunktur', link: 'doctor1' },
  { value: 'doctor8', label: 'Allergologie', link: 'doctor1' },
  { value: 'doctor9', label: 'Allgemeinchirurgie', link: 'doctor1' },
  { value: 'doctor10', label: 'Allgemeinmedizin', link: 'doctor1' },
  { value: 'doctor11', label: 'Anatomie', link: 'doctor1' },
  { value: 'doctor12', label: 'Andrologie', link: 'doctor1' },
  { value: 'doctor13', label: 'Anästhesiologie', link: 'doctor1' },
  { value: 'doctor14', label: 'Arbeitsmedizin', link: 'doctor1' },
  { value: 'doctor15', label: 'Augenheilkunde', link: 'doctor1' },
  { value: 'doctor16', label: 'Biochemie', link: 'doctor1' },
  { value: 'doctor17', label: 'Chirurgie', link: 'doctor1' },
  { value: 'doctor18', label: 'Dermatohistologie', link: 'doctor1' },
  { value: 'doctor19', label: 'Diabetologie', link: 'doctor1' },
  { value: 'doctor20', label: 'Flugmedizin', link: 'doctor1' },
  { value: 'doctor21', label: 'Forensische Psychiatrie', link: 'doctor1' },
  { value: 'doctor22', label: 'Gefäßchirugie', link: 'doctor1' },
  { value: 'doctor23', label: 'Geriatrie', link: 'doctor1' },
  { value: 'doctor24', label: 'Gynäkologie und Geburtshilfe', link: 'doctor1' },
  { value: 'doctor25', label: 'Gynäkologische Endokrinologie und Reproduktionsmed', link: 'doctor1' },
  { value: 'doctor26', label: 'Gynäkologische Exfoliativ-Zytologie', link: 'doctor1' },
  { value: 'doctor27', label: 'Gynäkologische Onkologie', link: 'doctor1' },
  { value: 'doctor28', label: 'Hals-Nasen-Ohrenheilkunde (HNO)', link: 'doctor1' },
  { value: 'doctor29', label: 'Handchirurgie', link: 'doctor1' },
  { value: 'doctor30', label: 'Haut- und Geschlechtskrankheiten', link: 'doctor1' },
  { value: 'doctor31', label: 'Herzchirurgie', link: 'doctor1' },
  { value: 'doctor32', label: 'Homöopathie', link: 'doctor1' },
  { value: 'doctor33', label: 'Humangenetik', link: 'doctor1' },
  { value: 'doctor34', label: 'Hygiene und Umweltmedizin', link: 'doctor1' },
  { value: 'doctor35', label: 'Hämostaseologie', link: 'doctor1' },
  { value: 'doctor36', label: 'Infektiologie', link: 'doctor1' },
  { value: 'doctor37', label: 'Innere Medizin', link: 'doctor1' },
  { value: 'doctor38', label: 'Innere Medizin und Angiologie', link: 'doctor1' },
  { value: 'doctor39', label: 'Innere Medizin und Endokrinologie und Diabetologie', link: 'doctor1' },
  { value: 'doctor40', label: 'Innere Medizin und Gastroenterologie', link: 'doctor1' },
  { value: 'doctor41', label: 'Innere Medizin und Hämatologie und Onkologie', link: 'doctor1' },
  { value: 'doctor42', label: 'Innere Medizin und Kardiologie', link: 'doctor1' },
  { value: 'doctor43', label: 'Innere Medizin und Nephrologie', link: 'doctor1' },
  { value: 'doctor44', label: 'Innere Medizin und Pneumologie', link: 'doctor1' },
  { value: 'doctor45', label: 'Innere Medizin und Rheumatologie', link: 'doctor1' },
  { value: 'doctor46', label: 'Intensivmedizin', link: 'doctor1' },
  { value: 'doctor47', label: 'Interventionelle Kardiologie', link: 'doctor1' },
  { value: 'doctor48', label: 'Kinder- und Jugendmedizin', link: 'doctor1' },
  { value: 'doctor49', label: 'Kinder- und Jugendpsychiatrie und -psychotherapie', link: 'doctor1' },
  { value: 'doctor50', label: 'Kinder-Endokrinologie und -Diabetologie', link: 'doctor1' },
  { value: 'doctor51', label: 'Kinder-Gastroenterologie', link: 'doctor1' },
  { value: 'doctor52', label: 'Kinder-Hämatologie und -Onkologie', link: 'doctor1' },
  { value: 'doctor53', label: 'Kinder-Kardiologie', link: 'doctor1' },
  { value: 'doctor54', label: 'Kinder-Nephrologie, Kinder-Orthopädie', link: 'doctor1' },
  { value: 'doctor55', label: 'Kinder-Pneumologie', link: 'doctor1' },
  { value: 'doctor56', label: 'Kinder-Rheumatologie', link: 'doctor1' },
  { value: 'doctor57', label: 'Kinderchirurgie', link: 'doctor1' },
  { value: 'doctor58', label: 'Kinderradiologie', link: 'doctor1' },
  { value: 'doctor59', label: 'Klinische Pharmakologie', link: 'doctor1' },
  { value: 'doctor60', label: 'Krankenhaushygiene', link: 'doctor1' },
  { value: 'doctor61', label: 'Labordiagnostik-fachgebunden', link: 'doctor1' },
  { value: 'doctor62', label: 'Labormedizin', link: 'doctor1' },
  { value: 'doctor63', label: 'Magnetresonanztomographie-fachgebunden', link: 'doctor1' },
  { value: 'doctor64', label: 'Manuelle Medizin / Chirotherapie', link: 'doctor1' },
  { value: 'doctor65', label: 'Medikamentöse Tumortherapie', link: 'doctor1' },
  { value: 'doctor66', label: 'Medizinische Informatik', link: 'doctor1' },
  { value: 'doctor67', label: 'Mikrobiologie', link: 'doctor1' },
  { value: 'doctor68', label: 'Mund-, Kiefer- und Gesichtschirurgie', link: 'doctor1' },
  { value: 'doctor69', label: 'Naturheilverfahren', link: 'doctor1' },
  { value: 'doctor70', label: 'Neonatologie', link: 'doctor1' },
  { value: 'doctor71', label: 'Neurochirurgie', link: 'doctor1' },
  { value: 'doctor72', label: 'Neurologie', link: 'doctor1' },
  { value: 'doctor73', label: 'Neuropädiatrie', link: 'doctor1' },
  { value: 'doctor74', label: 'Neuroradiologie', link: 'doctor1' },
  { value: 'doctor75', label: 'Notfallmedizin', link: 'doctor1' },
  { value: 'doctor76', label: 'Nuklearmedizin', link: 'doctor1' },
  { value: 'doctor77', label: 'Orthopädie und Unfallchirurgie', link: 'doctor1' },
  { value: 'doctor78', label: 'Orthopädische Rheumatologie', link: 'doctor1' },
  { value: 'doctor79', label: 'Palliativmedizin', link: 'doctor1' },
  { value: 'doctor80', label: 'Pathologie', link: 'doctor1' },
  { value: 'doctor81', label: 'Pharmakologie', link: 'doctor1' },
  { value: 'doctor82', label: 'Pharmakologie und Texikologie', link: 'doctor1' },
  { value: 'doctor83', label: 'Phlebologie', link: 'doctor1' },
  { value: 'doctor84', label: 'Physikalische Therapie und Balneologie', link: 'doctor1' },
  { value: 'doctor85', label: 'Physikalische und Rehabilitative Medizin', link: 'doctor1' },
  { value: 'doctor86', label: 'Physiologie', link: 'doctor1' },
  { value: 'doctor87', label: 'Plastische Operationen', link: 'doctor1' },
  { value: 'doctor88', label: 'Plastische und Ästhetische Chirurgie', link: 'doctor1' },
  { value: 'doctor89', label: 'Proktologie', link: 'doctor1' },
  { value: 'doctor90', label: 'Psychiatrie und Psychotherapie', link: 'doctor1' },
  { value: 'doctor91', label: 'Psychoanalyse', link: 'doctor1' },
  { value: 'doctor92', label: 'Psychosomatische Medizin und Psychotherapie', link: 'doctor1' },
  { value: 'doctor93', label: 'Psychotherapie-fachgebunden', link: 'doctor1' },
  { value: 'doctor94', label: 'Radiologie', link: 'doctor1' },
  { value: 'doctor95', label: 'Rechtsmedizin', link: 'doctor1' },
  { value: 'doctor96', label: 'Rhytmologie', link: 'doctor1' },
  { value: 'doctor97', label: 'Schlafmedizin', link: 'doctor1' },
  { value: 'doctor98', label: 'Sozialmedizin', link: 'doctor1' },
  { value: 'doctor99', label: 'Spezielle Geburtshilfe und Perinatalmedizin', link: 'doctor1' },
  { value: 'doctor100', label: 'Spezielle Orthopädische Chirurgie', link: 'doctor1' },
  { value: 'doctor101', label: 'Spezielle Schmerztherapie', link: 'doctor1' },
  { value: 'doctor102', label: 'Spezielle Unfallchirurgie', link: 'doctor1' },
  { value: 'doctor103', label: 'Sportmedizin', link: 'doctor1' },
  { value: 'doctor104', label: 'Sprach-, Stimm- und kindliche Hörstörungen', link: 'doctor1' },
  { value: 'doctor105', label: 'Strahlentherapie', link: 'doctor1' },
  { value: 'doctor106', label: 'Suchtmedizinische Grundversorgung', link: 'doctor1' },
  { value: 'doctor107', label: 'Thoraxchirurgie', link: 'doctor1' },
  { value: 'doctor108', label: 'Transfusionsmedizin', link: 'doctor1' },
  { value: 'doctor109', label: 'Tropenmedizin', link: 'doctor1' },
  { value: 'doctor110', label: 'Urologie', link: 'doctor1' },
  { value: 'doctor111', label: 'Viszeralchirurgie', link: 'doctor1' },
  { value: 'doctor112', label: 'Zahnmedizin', link: 'doctor1' },
  { value: 'doctor113', label: 'Ärztliches Qualitätsmanagement', link: 'doctor1' },
  { value: 'doctor114', label: 'Öffentliche Gesundheitswesen', link: 'doctor1' },
  ///////////////////////////////////////////////////////////////////////////////////////////////

  { value: 'doctor115', label: 'Akupunktur', link: 'doctor2' },
  { value: 'doctor116', label: 'Allergologie', link: 'doctor2' },
  { value: 'doctor117', label: 'Allgemeinchirurgie', link: 'doctor2' },
  { value: 'doctor118', label: 'Allgemeinmedizin', link: 'doctor2' },
  { value: 'doctor119', label: 'Anatomie', link: 'doctor2' },
  { value: 'doctor120', label: 'Andrologie', link: 'doctor2' },
  { value: 'doctor121', label: 'Anästhesiologie', link: 'doctor2' },
  { value: 'doctor122', label: 'Arbeitsmedizin', link: 'doctor2' },
  { value: 'doctor123', label: 'Augenheilkunde', link: 'doctor2' },
  { value: 'doctor124', label: 'Biochemie', link: 'doctor2' },
  { value: 'doctor125', label: 'Chirurgie', link: 'doctor2' },
  { value: 'doctor126', label: 'Dermatohistologie', link: 'doctor2' },
  { value: 'doctor127', label: 'Diabetologie', link: 'doctor2' },
  { value: 'doctor128', label: 'Flugmedizin', link: 'doctor2' },
  { value: 'doctor129', label: 'Forensische Psychiatrie', link: 'doctor2' },
  { value: 'doctor130', label: 'Gefäßchirugie', link: 'doctor2' },
  { value: 'doctor131', label: 'Geriatrie', link: 'doctor2' },
  { value: 'doctor132', label: 'Gynäkologie und Geburtshilfe', link: 'doctor2' },
  { value: 'doctor133', label: 'Gynäkologische Endokrinologie und Reproduktionsmed', link: 'doctor2' },
  { value: 'doctor134', label: 'Gynäkologische Exfoliativ-Zytologie', link: 'doctor2' },
  { value: 'doctor135', label: 'Gynäkologische Onkologie', link: 'doctor2' },
  { value: 'doctor136', label: 'Hals-Nasen-Ohrenheilkunde (HNO)', link: 'doctor2' },
  { value: 'doctor137', label: 'Handchirurgie', link: 'doctor2' },
  { value: 'doctor138', label: 'Haut- und Geschlechtskrankheiten', link: 'doctor2' },
  { value: 'doctor139', label: 'Herzchirurgie', link: 'doctor2' },
  { value: 'doctor140', label: 'Homöopathie', link: 'doctor2' },
  { value: 'doctor141', label: 'Humangenetik', link: 'doctor2' },
  { value: 'doctor141', label: 'Hygiene und Umweltmedizin', link: 'doctor2' },
  { value: 'doctor142', label: 'Hämostaseologie', link: 'doctor2' },
  { value: 'doctor143', label: 'Infektiologie', link: 'doctor2' },
  { value: 'doctor144', label: 'Innere Medizin', link: 'doctor2' },
  { value: 'doctor145', label: 'Innere Medizin und Angiologie', link: 'doctor2' },
  { value: 'doctor146', label: 'Innere Medizin und Endokrinologie und Diabetologie', link: 'doctor2' },
  { value: 'doctor147', label: 'Innere Medizin und Gastroenterologie', link: 'doctor2' },
  { value: 'doctor148', label: 'Innere Medizin und Hämatologie und Onkologie', link: 'doctor2' },
  { value: 'doctor149', label: 'Innere Medizin und Kardiologie', link: 'doctor2' },
  { value: 'doctor150', label: 'Innere Medizin und Nephrologie', link: 'doctor2' },
  { value: 'doctor151', label: 'Innere Medizin und Pneumologie', link: 'doctor2' },
  { value: 'doctor152', label: 'Innere Medizin und Rheumatologie', link: 'doctor2' },
  { value: 'doctor153', label: 'Intensivmedizin', link: 'doctor2' },
  { value: 'doctor154', label: 'Interventionelle Kardiologie', link: 'doctor2' },
  { value: 'doctor155', label: 'Kinder- und Jugendmedizin', link: 'doctor2' },
  { value: 'doctor156', label: 'Kinder- und Jugendpsychiatrie und -psychotherapie', link: 'doctor2' },
  { value: 'doctor157', label: 'Kinder-Endokrinologie und -Diabetologie', link: 'doctor2' },
  { value: 'doctor158', label: 'Kinder-Gastroenterologie', link: 'doctor2' },
  { value: 'doctor159', label: 'Kinder-Hämatologie und -Onkologie', link: 'doctor2' },
  { value: 'doctor160', label: 'Kinder-Kardiologie', link: 'doctor2' },
  { value: 'doctor161', label: 'Kinder-Nephrologie, Kinder-Orthopädie', link: 'doctor2' },
  { value: 'doctor162', label: 'Kinder-Pneumologie', link: 'doctor2' },
  { value: 'doctor163', label: 'Kinder-Rheumatologie', link: 'doctor2' },
  { value: 'doctor164', label: 'Kinderchirurgie', link: 'doctor2' },
  { value: 'doctor165', label: 'Kinderradiologie', link: 'doctor2' },
  { value: 'doctor166', label: 'Klinische Pharmakologie', link: 'doctor2' },
  { value: 'doctor167', label: 'Krankenhaushygiene', link: 'doctor2' },
  { value: 'doctor168', label: 'Labordiagnostik-fachgebunden', link: 'doctor2' },
  { value: 'doctor169', label: 'Labormedizin', link: 'doctor2' },
  { value: 'doctor170', label: 'Magnetresonanztomographie-fachgebunden', link: 'doctor2' },
  { value: 'doctor171', label: 'Manuelle Medizin / Chirotherapie', link: 'doctor2' },
  { value: 'doctor172', label: 'Medikamentöse Tumortherapie', link: 'doctor2' },
  { value: 'doctor173', label: 'Medizinische Informatik', link: 'doctor2' },
  { value: 'doctor174', label: 'Mikrobiologie', link: 'doctor2' },
  { value: 'doctor175', label: 'Mund-, Kiefer- und Gesichtschirurgie', link: 'doctor2' },
  { value: 'doctor176', label: 'Naturheilverfahren', link: 'doctor2' },
  { value: 'doctor177', label: 'Neonatologie', link: 'doctor2' },
  { value: 'doctor178', label: 'Neurochirurgie', link: 'doctor2' },
  { value: 'doctor179', label: 'Neurologie', link: 'doctor2' },
  { value: 'doctor180', label: 'Neuropädiatrie', link: 'doctor2' },
  { value: 'doctor181', label: 'Neuroradiologie', link: 'doctor2' },
  { value: 'doctor182', label: 'Notfallmedizin', link: 'doctor2' },
  { value: 'doctor183', label: 'Nuklearmedizin', link: 'doctor2' },
  { value: 'doctor184', label: 'Orthopädie und Unfallchirurgie', link: 'doctor2' },
  { value: 'doctor185', label: 'Orthopädische Rheumatologie', link: 'doctor2' },
  { value: 'doctor186', label: 'Palliativmedizin', link: 'doctor2' },
  { value: 'doctor187', label: 'Pathologie', link: 'doctor2' },
  { value: 'doctor188', label: 'Pharmakologie', link: 'doctor2' },
  { value: 'doctor189', label: 'Pharmakologie und Texikologie', link: 'doctor2' },
  { value: 'doctor190', label: 'Phlebologie', link: 'doctor2' },
  { value: 'doctor191', label: 'Physikalische Therapie und Balneologie', link: 'doctor2' },
  { value: 'doctor192', label: 'Physikalische und Rehabilitative Medizin', link: 'doctor2' },
  { value: 'doctor193', label: 'Physiologie', link: 'doctor2' },
  { value: 'doctor194', label: 'Plastische Operationen', link: 'doctor2' },
  { value: 'doctor195', label: 'Plastische und Ästhetische Chirurgie', link: 'doctor2' },
  { value: 'doctor196', label: 'Proktologie', link: 'doctor2' },
  { value: 'doctor197', label: 'Psychiatrie und Psychotherapie', link: 'doctor2' },
  { value: 'doctor198', label: 'Psychoanalyse', link: 'doctor2' },
  { value: 'doctor199', label: 'Psychosomatische Medizin und Psychotherapie', link: 'doctor2' },
  { value: 'doctor200', label: 'Psychotherapie-fachgebunden', link: 'doctor2' },
  { value: 'doctor201', label: 'Radiologie', link: 'doctor2' },
  { value: 'doctor202', label: 'Rechtsmedizin', link: 'doctor2' },
  { value: 'doctor203', label: 'Rhytmologie', link: 'doctor2' },
  { value: 'doctor204', label: 'Schlafmedizin', link: 'doctor2' },
  { value: 'doctor205', label: 'Sozialmedizin', link: 'doctor2' },
  { value: 'doctor206', label: 'Spezielle Geburtshilfe und Perinatalmedizin', link: 'doctor2' },
  { value: 'doctor207', label: 'Spezielle Orthopädische Chirurgie', link: 'doctor2' },
  { value: 'doctor208', label: 'Spezielle Schmerztherapie', link: 'doctor2' },
  { value: 'doctor209', label: 'Spezielle Unfallchirurgie', link: 'doctor2' },
  { value: 'doctor210', label: 'Sportmedizin', link: 'doctor2' },
  { value: 'doctor211', label: 'Sprach-, Stimm- und kindliche Hörstörungen', link: 'doctor2' },
  { value: 'doctor212', label: 'Strahlentherapie', link: 'doctor2' },
  { value: 'doctor213', label: 'Suchtmedizinische Grundversorgung', link: 'doctor2' },
  { value: 'doctor214', label: 'Thoraxchirurgie', link: 'doctor2' },
  { value: 'doctor215', label: 'Transfusionsmedizin', link: 'doctor2' },
  { value: 'doctor216', label: 'Tropenmedizin', link: 'doctor2' },
  { value: 'doctor217', label: 'Urologie', link: 'doctor2' },
  { value: 'doctor218', label: 'Viszeralchirurgie', link: 'doctor2' },
  { value: 'doctor219', label: 'Zahnmedizin', link: 'doctor2' },
  { value: 'doctor220', label: 'Ärztliches Qualitätsmanagement', link: 'doctor2' },
  { value: 'doctor221', label: 'Öffentliche Gesundheitswesen', link: 'doctor2' },
  ////////////////////////////////////////////////////////////////////////////////////////////////////

  { value: 'docto1', label: 'Akupunktur', link: 'doctor3' },
  { value: 'docto2', label: 'Allergologie', link: 'doctor3' },
  { value: 'docto3', label: 'Allgemeinchirurgie', link: 'doctor3' },
  { value: 'docto4', label: 'Allgemeinmedizin', link: 'doctor3' },
  { value: 'docto5', label: 'Anatomie', link: 'doctor3' },
  { value: 'docto6', label: 'Andrologie', link: 'doctor3' },
  { value: 'docto7', label: 'Anästhesiologie', link: 'doctor3' },
  { value: 'docto8', label: 'Arbeitsmedizin', link: 'doctor3' },
  { value: 'docto9', label: 'Augenheilkunde', link: 'doctor3' },
  { value: 'docto10', label: 'Biochemie', link: 'doctor3' },
  { value: 'docto11', label: 'Chirurgie', link: 'doctor3' },
  { value: 'docto12', label: 'Dermatohistologie', link: 'doctor3' },
  { value: 'docto13', label: 'Diabetologie', link: 'doctor3' },
  { value: 'docto14', label: 'Flugmedizin', link: 'doctor3' },
  { value: 'docto15', label: 'Forensische Psychiatrie', link: 'doctor3' },
  { value: 'docto16', label: 'Gefäßchirugie', link: 'doctor3' },
  { value: 'docto17', label: 'Geriatrie', link: 'doctor3' },
  { value: 'docto18', label: 'Gynäkologie und Geburtshilfe', link: 'doctor3' },
  { value: 'docto19', label: 'Gynäkologische Endokrinologie und Reproduktionsmed', link: 'doctor3' },
  { value: 'docto20', label: 'Gynäkologische Exfoliativ-Zytologie', link: 'doctor3' },
  { value: 'docto21', label: 'Gynäkologische Onkologie', link: 'doctor3' },
  { value: 'docto22', label: 'Hals-Nasen-Ohrenheilkunde (HNO)', link: 'doctor3' },
  { value: 'docto23', label: 'Handchirurgie', link: 'doctor3' },
  { value: 'docto24', label: 'Haut- und Geschlechtskrankheiten', link: 'doctor3' },
  { value: 'docto25', label: 'Herzchirurgie', link: 'doctor3' },
  { value: 'docto26', label: 'Homöopathie', link: 'doctor3' },
  { value: 'docto27', label: 'Humangenetik', link: 'doctor3' },
  { value: 'docto28', label: 'Hygiene und Umweltmedizin', link: 'doctor3' },
  { value: 'docto29', label: 'Hämostaseologie', link: 'doctor3' },
  { value: 'docto30', label: 'Infektiologie', link: 'doctor3' },
  { value: 'docto31', label: 'Innere Medizin', link: 'doctor3' },
  { value: 'docto32', label: 'Innere Medizin und Angiologie', link: 'doctor3' },
  { value: 'docto33', label: 'Innere Medizin und Endokrinologie und Diabetologie', link: 'doctor3' },
  { value: 'docto34', label: 'Innere Medizin und Gastroenterologie', link: 'doctor3' },
  { value: 'docto35', label: 'Innere Medizin und Hämatologie und Onkologie', link: 'doctor3' },
  { value: 'docto36', label: 'Innere Medizin und Kardiologie', link: 'doctor3' },
  { value: 'docto37', label: 'Innere Medizin und Nephrologie', link: 'doctor3' },
  { value: 'docto38', label: 'Innere Medizin und Pneumologie', link: 'doctor3' },
  { value: 'docto39', label: 'Innere Medizin und Rheumatologie', link: 'doctor3' },
  { value: 'docto40', label: 'Intensivmedizin', link: 'doctor3' },
  { value: 'docto41', label: 'Interventionelle Kardiologie', link: 'doctor3' },
  { value: 'docto42', label: 'Kinder- und Jugendmedizin', link: 'doctor3' },
  { value: 'docto43', label: 'Kinder- und Jugendpsychiatrie und -psychotherapie', link: 'doctor3' },
  { value: 'docto44', label: 'Kinder-Endokrinologie und -Diabetologie', link: 'doctor3' },
  { value: 'docto45', label: 'Kinder-Gastroenterologie', link: 'doctor3' },
  { value: 'docto46', label: 'Kinder-Hämatologie und -Onkologie', link: 'doctor3' },
  { value: 'docto47', label: 'Kinder-Kardiologie', link: 'doctor3' },
  { value: 'docto48', label: 'Kinder-Nephrologie, Kinder-Orthopädie', link: 'doctor3' },
  { value: 'docto49', label: 'Kinder-Pneumologie', link: 'doctor3' },
  { value: 'docto50', label: 'Kinder-Rheumatologie', link: 'doctor3' },
  { value: 'docto51', label: 'Kinderchirurgie', link: 'doctor3' },
  { value: 'docto52', label: 'Kinderradiologie', link: 'doctor3' },
  { value: 'docto53', label: 'Klinische Pharmakologie', link: 'doctor3' },
  { value: 'docto54', label: 'Krankenhaushygiene', link: 'doctor3' },
  { value: 'docto55', label: 'Labordiagnostik-fachgebunden', link: 'doctor3' },
  { value: 'docto56', label: 'Labormedizin', link: 'doctor3' },
  { value: 'docto57', label: 'Magnetresonanztomographie-fachgebunden', link: 'doctor3' },
  { value: 'docto58', label: 'Manuelle Medizin / Chirotherapie', link: 'doctor3' },
  { value: 'docto59', label: 'Medikamentöse Tumortherapie', link: 'doctor3' },
  { value: 'docto60', label: 'Medizinische Informatik', link: 'doctor3' },
  { value: 'docto61', label: 'Mikrobiologie', link: 'doctor3' },
  { value: 'docto62', label: 'Mund-, Kiefer- und Gesichtschirurgie', link: 'doctor3' },
  { value: 'docto63', label: 'Naturheilverfahren', link: 'doctor3' },
  { value: 'docto64', label: 'Neonatologie', link: 'doctor3' },
  { value: 'docto65', label: 'Neurochirurgie', link: 'doctor3' },
  { value: 'docto66', label: 'Neurologie', link: 'doctor3' },
  { value: 'docto67', label: 'Neuropädiatrie', link: 'doctor3' },
  { value: 'docto68', label: 'Neuroradiologie', link: 'doctor3' },
  { value: 'docto69', label: 'Notfallmedizin', link: 'doctor3' },
  { value: 'docto70', label: 'Nuklearmedizin', link: 'doctor3' },
  { value: 'docto71', label: 'Orthopädie und Unfallchirurgie', link: 'doctor3' },
  { value: 'docto72', label: 'Orthopädische Rheumatologie', link: 'doctor3' },
  { value: 'docto73', label: 'Palliativmedizin', link: 'doctor3' },
  { value: 'docto74', label: 'Pathologie', link: 'doctor3' },
  { value: 'docto75', label: 'Pharmakologie', link: 'doctor3' },
  { value: 'docto76', label: 'Pharmakologie und Texikologie', link: 'doctor3' },
  { value: 'docto77', label: 'Phlebologie', link: 'doctor3' },
  { value: 'docto78', label: 'Physikalische Therapie und Balneologie', link: 'doctor3' },
  { value: 'docto79', label: 'Physikalische und Rehabilitative Medizin', link: 'doctor3' },
  { value: 'docto80', label: 'Physiologie', link: 'doctor3' },
  { value: 'docto81', label: 'Plastische Operationen', link: 'doctor3' },
  { value: 'docto82', label: 'Plastische und Ästhetische Chirurgie', link: 'doctor' },
  { value: 'docto83', label: 'Proktologie', link: 'doctor3' },
  { value: 'docto84', label: 'Psychiatrie und Psychotherapie', link: 'doctor3' },
  { value: 'docto85', label: 'Psychoanalyse', link: 'doctor3' },
  { value: 'docto86', label: 'Psychosomatische Medizin und Psychotherapie', link: 'doctor3' },
  { value: 'docto87', label: 'Psychotherapie-fachgebunden', link: 'doctor3' },
  { value: 'docto88', label: 'Radiologie', link: 'doctor3' },
  { value: 'docto89', label: 'Rechtsmedizin', link: 'doctor3' },
  { value: 'docto90', label: 'Rhytmologie', link: 'doctor3' },
  { value: 'docto91', label: 'Schlafmedizin', link: 'doctor3' },
  { value: 'docto92', label: 'Sozialmedizin', link: 'doctor3' },
  { value: 'docto93', label: 'Spezielle Geburtshilfe und Perinatalmedizin', link: 'doctor3' },
  { value: 'docto94', label: 'Spezielle Orthopädische Chirurgie', link: 'doctor3' },
  { value: 'docto95', label: 'Spezielle Schmerztherapie', link: 'doctor3' },
  { value: 'docto96', label: 'Spezielle Unfallchirurgie', link: 'doctor3' },
  { value: 'docto97', label: 'Sportmedizin', link: 'doctor3' },
  { value: 'docto98', label: 'Sprach-, Stimm- und kindliche Hörstörungen', link: 'doctor3' },
  { value: 'docto99', label: 'Strahlentherapie', link: 'doctor3' },
  { value: 'docto100', label: 'Suchtmedizinische Grundversorgung', link: 'doctor3' },
  { value: 'docto101', label: 'Thoraxchirurgie', link: 'doctor3' },
  { value: 'docto102', label: 'Transfusionsmedizin', link: 'doctor3' },
  { value: 'docto103', label: 'Tropenmedizin', link: 'doctor3' },
  { value: 'docto104', label: 'Urologie', link: 'doctor3' },
  { value: 'docto105', label: 'Viszeralchirurgie', link: 'doctor3' },
  { value: 'docto106', label: 'Zahnmedizin', link: 'doctor3' },
  { value: 'docto107', label: 'Ärztliches Qualitätsmanagement', link: 'doctor3' },
  { value: 'docto108', label: 'Öffentliche Gesundheitswesen', link: 'doctor3' },
  /////////////////////////////////////////////////////////////////////////////////////////////////////

  { value: '1doctor', label: 'Akupunktur', link: 'doctor4' },
  { value: '2doctor', label: 'Allergologie', link: 'doctor4' },
  { value: '3doctor', label: 'Allgemeinchirurgie', link: 'doctor4' },
  { value: '4doctor', label: 'Allgemeinmedizin', link: 'doctor4' },
  { value: '5doctor', label: 'Anatomie', link: 'doctor4' },
  { value: '6doctor', label: 'Andrologie', link: 'doctor4' },
  { value: '7doctor', label: 'Anästhesiologie', link: 'doctor4' },
  { value: '8doctor', label: 'Arbeitsmedizin', link: 'doctor4' },
  { value: '9doctor', label: 'Augenheilkunde', link: 'doctor4' },
  { value: '10doctor', label: 'Biochemie', link: 'doctor4' },
  { value: '11doctor', label: 'Chirurgie', link: 'doctor4' },
  { value: '12doctor', label: 'Dermatohistologie', link: 'doctor4' },
  { value: '13doctor', label: 'Diabetologie', link: 'doctor4' },
  { value: '14doctor', label: 'Flugmedizin', link: 'doctor4' },
  { value: '15doctor', label: 'Forensische Psychiatrie', link: 'doctor4' },
  { value: '16doctor', label: 'Gefäßchirugie', link: 'doctor4' },
  { value: '17doctor', label: 'Geriatrie', link: 'doctor4' },
  { value: '18doctor', label: 'Gynäkologie und Geburtshilfe', link: 'doctor4' },
  { value: '19doctor', label: 'Gynäkologische Endokrinologie und Reproduktionsmed', link: 'doctor4' },
  { value: '20doctor', label: 'Gynäkologische Exfoliativ-Zytologie', link: 'doctor4' },
  { value: '21doctor', label: 'Gynäkologische Onkologie', link: 'doctor4' },
  { value: '22doctor', label: 'Hals-Nasen-Ohrenheilkunde (HNO)', link: 'doctor4' },
  { value: '23doctor', label: 'Handchirurgie', link: 'doctor4' },
  { value: '24doctor', label: 'Haut- und Geschlechtskrankheiten', link: 'doctor4' },
  { value: '25doctor', label: 'Herzchirurgie', link: 'doctor4' },
  { value: '26doctor', label: 'Homöopathie', link: 'doctor4' },
  { value: '27doctor', label: 'Humangenetik', link: 'doctor4' },
  { value: '28doctor', label: 'Hygiene und Umweltmedizin', link: 'doctor4' },
  { value: '29doctor', label: 'Hämostaseologie', link: 'doctor4' },
  { value: '30doctor', label: 'Infektiologie', link: 'doctor4' },
  { value: '31doctor', label: 'Innere Medizin', link: 'doctor4' },
  { value: '32doctor', label: 'Innere Medizin und Angiologie', link: 'doctor4' },
  { value: '33doctor', label: 'Innere Medizin und Endokrinologie und Diabetologie', link: 'doctor4' },
  { value: '34doctor', label: 'Innere Medizin und Gastroenterologie', link: 'doctor4' },
  { value: '35doctor', label: 'Innere Medizin und Hämatologie und Onkologie', link: 'doctor4' },
  { value: '36doctor', label: 'Innere Medizin und Kardiologie', link: 'doctor4' },
  { value: '37doctor', label: 'Innere Medizin und Nephrologie', link: 'doctor4' },
  { value: '38doctor', label: 'Innere Medizin und Pneumologie', link: 'doctor4' },
  { value: '39doctor', label: 'Innere Medizin und Rheumatologie', link: 'doctor4' },
  { value: '40doctor', label: 'Intensivmedizin', link: 'doctor4' },
  { value: '41doctor', label: 'Interventionelle Kardiologie', link: 'doctor4' },
  { value: '42doctor', label: 'Kinder- und Jugendmedizin', link: 'doctor4' },
  { value: '43doctor', label: 'Kinder- und Jugendpsychiatrie und -psychotherapie', link: 'doctor4' },
  { value: '44doctor', label: 'Kinder-Endokrinologie und -Diabetologie', link: 'doctor4' },
  { value: '45doctor', label: 'Kinder-Gastroenterologie', link: 'doctor4' },
  { value: '46doctor', label: 'Kinder-Hämatologie und -Onkologie', link: 'doctor4' },
  { value: '47doctor', label: 'Kinder-Kardiologie', link: 'doctor4' },
  { value: '48doctor', label: 'Kinder-Nephrologie, Kinder-Orthopädie', link: 'doctor4' },
  { value: '49doctor', label: 'Kinder-Pneumologie', link: 'doctor4' },
  { value: '50doctor', label: 'Kinder-Rheumatologie', link: 'doctor4' },
  { value: '51doctor', label: 'Kinderchirurgie', link: 'doctor4' },
  { value: '52doctor', label: 'Kinderradiologie', link: 'doctor4' },
  { value: '53doctor', label: 'Klinische Pharmakologie', link: 'doctor4' },
  { value: '54doctor', label: 'Krankenhaushygiene', link: 'doctor4' },
  { value: '55doctor', label: 'Labordiagnostik-fachgebunden', link: 'doctor4' },
  { value: '56doctor', label: 'Labormedizin', link: 'doctor4' },
  { value: '57doctor', label: 'Magnetresonanztomographie-fachgebunden', link: 'doctor4' },
  { value: '58doctor', label: 'Manuelle Medizin / Chirotherapie', link: 'doctor4' },
  { value: '59doctor', label: 'Medikamentöse Tumortherapie', link: 'doctor4' },
  { value: '60doctor', label: 'Medizinische Informatik', link: 'doctor4' },
  { value: '61doctor', label: 'Mikrobiologie', link: 'doctor4' },
  { value: '62doctor', label: 'Mund-, Kiefer- und Gesichtschirurgie', link: 'doctor4' },
  { value: '63doctor', label: 'Naturheilverfahren', link: 'doctor4' },
  { value: '64doctor', label: 'Neonatologie', link: 'doctor4' },
  { value: '65doctor', label: 'Neurochirurgie', link: 'doctor4' },
  { value: '66doctor', label: 'Neurologie', link: 'doctor4' },
  { value: '67doctor', label: 'Neuropädiatrie', link: 'doctor4' },
  { value: '68doctor', label: 'Neuroradiologie', link: 'doctor4' },
  { value: '69doctor', label: 'Notfallmedizin', link: 'doctor4' },
  { value: '70doctor', label: 'Nuklearmedizin', link: 'doctor4' },
  { value: '71doctor', label: 'Orthopädie und Unfallchirurgie', link: 'doctor4' },
  { value: '72doctor', label: 'Orthopädische Rheumatologie', link: 'doctor4' },
  { value: '73doctor', label: 'Palliativmedizin', link: 'doctor4' },
  { value: '74doctor', label: 'Pathologie', link: 'doctor4' },
  { value: '75doctor', label: 'Pharmakologie', link: 'doctor4' },
  { value: '76doctor', label: 'Pharmakologie und Texikologie', link: 'doctor4' },
  { value: '77doctor', label: 'Phlebologie', link: 'doctor4' },
  { value: '78doctor', label: 'Physikalische Therapie und Balneologie', link: 'doctor4' },
  { value: '79doctor', label: 'Physikalische und Rehabilitative Medizin', link: 'doctor4' },
  { value: '80doctor', label: 'Physiologie', link: 'doctor4' },
  { value: '81doctor', label: 'Plastische Operationen', link: 'doctor4' },
  { value: '82doctor', label: 'Plastische und Ästhetische Chirurgie', link: 'doctor4' },
  { value: '83doctor', label: 'Proktologie', link: 'doctor4' },
  { value: '84doctor', label: 'Psychiatrie und Psychotherapie', link: 'doctor4' },
  { value: '85doctor', label: 'Psychoanalyse', link: 'doctor4' },
  { value: '86doctor', label: 'Psychosomatische Medizin und Psychotherapie', link: 'doctor4' },
  { value: '87doctor', label: 'Psychotherapie-fachgebunden', link: 'doctor4' },
  { value: '88doctor', label: 'Radiologie', link: 'doctor4' },
  { value: '89doctor', label: 'Rechtsmedizin', link: 'doctor4' },
  { value: '90doctor', label: 'Rhytmologie', link: 'doctor4' },
  { value: '91doctor', label: 'Schlafmedizin', link: 'doctor4' },
  { value: '92doctor', label: 'Sozialmedizin', link: 'doctor4' },
  { value: '93doctor', label: 'Spezielle Geburtshilfe und Perinatalmedizin', link: 'doctor4' },
  { value: '94doctor', label: 'Spezielle Orthopädische Chirurgie', link: 'doctor4' },
  { value: '95doctor', label: 'Spezielle Schmerztherapie', link: 'doctor4' },
  { value: '96doctor', label: 'Spezielle Unfallchirurgie', link: 'doctor4' },
  { value: '97doctor', label: 'Sportmedizin', link: 'doctor4' },
  { value: '98doctor', label: 'Sprach-, Stimm- und kindliche Hörstörungen', link: 'doctor4' },
  { value: '99doctor', label: 'Strahlentherapie', link: 'doctor4' },
  { value: '100doctor', label: 'Suchtmedizinische Grundversorgung', link: 'doctor4' },
  { value: '101doctor', label: 'Thoraxchirurgie', link: 'doctor4' },
  { value: '102doctor', label: 'Transfusionsmedizin', link: 'doctor4' },
  { value: '103doctor', label: 'Tropenmedizin', link: 'doctor4' },
  { value: '104doctor', label: 'Urologie', link: 'doctor4' },
  { value: '105doctor', label: 'Viszeralchirurgie', link: 'doctor4' },
  { value: '106doctor', label: 'Zahnmedizin', link: 'doctor4' },
  { value: '107octor', label: 'Ärztliches Qualitätsmanagement', link: 'doctor4' },
  { value: '108doctor', label: 'Öffentliche Gesundheitswesen', link: 'doctor4' },
  ///////////////////////////////////////////////////////////////////////////////////////////////

  { value: '109doctor', label: 'Akupunktur', link: 'doctor5' },
  { value: '110doctor', label: 'Allergologie', link: 'doctor5' },
  { value: '111doctor', label: 'Allgemeinchirurgie', link: 'doctor5' },
  { value: '112doctor', label: 'Allgemeinmedizin', link: 'doctor5' },
  { value: '113doctor', label: 'Anatomie', link: 'doctor5' },
  { value: '114doctor', label: 'Andrologie', link: 'doctor5' },
  { value: '115doctor', label: 'Anästhesiologie', link: 'doctor5' },
  { value: '116doctor', label: 'Arbeitsmedizin', link: 'doctor5' },
  { value: '117doctor', label: 'Augenheilkunde', link: 'doctor5' },
  { value: '118doctor', label: 'Biochemie', link: 'doctor5' },
  { value: '119doctor', label: 'Chirurgie', link: 'doctor5' },
  { value: '120doctor', label: 'Dermatohistologie', link: 'doctor5' },
  { value: '121doctor', label: 'Diabetologie', link: 'doctor5' },
  { value: '122doctor', label: 'Flugmedizin', link: 'doctor5' },
  { value: '123doctor', label: 'Forensische Psychiatrie', link: 'doctor5' },
  { value: '124doctor', label: 'Gefäßchirugie', link: 'doctor5' },
  { value: '125doctor', label: 'Geriatrie', link: 'doctor5' },
  { value: '126doctor', label: 'Gynäkologie und Geburtshilfe', link: 'doctor5' },
  { value: '127doctor', label: 'Gynäkologische Endokrinologie und Reproduktionsmed', link: 'doctor5' },
  { value: '128doctor', label: 'Gynäkologische Exfoliativ-Zytologie', link: 'doctor5' },
  { value: '129doctor', label: 'Gynäkologische Onkologie', link: 'doctor5' },
  { value: '130doctor', label: 'Hals-Nasen-Ohrenheilkunde (HNO)', link: 'doctor5' },
  { value: '131doctor', label: 'Handchirurgie', link: 'doctor5' },
  { value: '132doctor', label: 'Haut- und Geschlechtskrankheiten', link: 'doctor5' },
  { value: '133doctor', label: 'Herzchirurgie', link: 'doctor5' },
  { value: '134doctor', label: 'Homöopathie', link: 'doctor5' },
  { value: '135doctor', label: 'Humangenetik', link: 'doctor5' },
  { value: '136doctor', label: 'Hygiene und Umweltmedizin', link: 'doctor5' },
  { value: '137doctor', label: 'Hämostaseologie', link: 'doctor5' },
  { value: '138doctor', label: 'Infektiologie', link: 'doctor5' },
  { value: '139doctor', label: 'Innere Medizin', link: 'doctor5' },
  { value: '140doctor', label: 'Innere Medizin und Angiologie', link: 'doctor5' },
  { value: '141doctor', label: 'Innere Medizin und Endokrinologie und Diabetologie', link: 'doctor5' },
  { value: '142doctor', label: 'Innere Medizin und Gastroenterologie', link: 'doctor5' },
  { value: '143doctor', label: 'Innere Medizin und Hämatologie und Onkologie', link: 'doctor5' },
  { value: '144doctor', label: 'Innere Medizin und Kardiologie', link: 'doctor5' },
  { value: '145doctor', label: 'Innere Medizin und Nephrologie', link: 'doctor5' },
  { value: '146doctor', label: 'Innere Medizin und Pneumologie', link: 'doctor5' },
  { value: '147doctor', label: 'Innere Medizin und Rheumatologie', link: 'doctor5' },
  { value: '148doctor', label: 'Intensivmedizin', link: 'doctor5' },
  { value: '149doctor', label: 'Interventionelle Kardiologie', link: 'doctor5' },
  { value: '150doctor', label: 'Kinder- und Jugendmedizin', link: 'doctor5' },
  { value: '151doctor', label: 'Kinder- und Jugendpsychiatrie und -psychotherapie', link: 'doctor5' },
  { value: '152doctor', label: 'Kinder-Endokrinologie und -Diabetologie', link: 'doctor5' },
  { value: '153doctor', label: 'Kinder-Gastroenterologie', link: 'doctor5' },
  { value: '154doctor', label: 'Kinder-Hämatologie und -Onkologie', link: 'doctor5' },
  { value: '155doctor', label: 'Kinder-Kardiologie', link: 'doctor5' },
  { value: '156doctor', label: 'Kinder-Nephrologie, Kinder-Orthopädie', link: 'doctor5' },
  { value: '157doctor', label: 'Kinder-Pneumologie', link: 'doctor5' },
  { value: '158doctor', label: 'Kinder-Rheumatologie', link: 'doctor5' },
  { value: '158doctor', label: 'Kinderchirurgie', link: 'doctor5' },
  { value: '160doctor', label: 'Kinderradiologie', link: 'doctor5' },
  { value: '161doctor', label: 'Klinische Pharmakologie', link: 'doctor5' },
  { value: '162doctor', label: 'Krankenhaushygiene', link: 'doctor5' },
  { value: '163doctor', label: 'Labordiagnostik-fachgebunden', link: 'doctor5' },
  { value: '164doctor', label: 'Labormedizin', link: 'doctor5' },
  { value: '165doctor', label: 'Magnetresonanztomographie-fachgebunden', link: 'doctor5' },
  { value: '166doctor', label: 'Manuelle Medizin / Chirotherapie', link: 'doctor5' },
  { value: '167doctor', label: 'Medikamentöse Tumortherapie', link: 'doctor5' },
  { value: '168doctor', label: 'Medizinische Informatik', link: 'doctor5' },
  { value: '169doctor', label: 'Mikrobiologie', link: 'doctor5' },
  { value: '170doctor', label: 'Mund-, Kiefer- und Gesichtschirurgie', link: 'doctor5' },
  { value: '171doctor', label: 'Naturheilverfahren', link: 'doctor5' },
  { value: '172doctor', label: 'Neonatologie', link: 'doctor5' },
  { value: '173doctor', label: 'Neurochirurgie', link: 'doctor5' },
  { value: '174doctor', label: 'Neurologie', link: 'doctor5' },
  { value: '175doctor', label: 'Neuropädiatrie', link: 'doctor5' },
  { value: '176doctor', label: 'Neuroradiologie', link: 'docto5' },
  { value: '177doctor', label: 'Notfallmedizin', link: 'doctor5' },
  { value: '178doctor', label: 'Nuklearmedizin', link: 'doctor5' },
  { value: '179doctor', label: 'Orthopädie und Unfallchirurgie', link: 'doctor5' },
  { value: '180doctor', label: 'Orthopädische Rheumatologie', link: 'doctor5' },
  { value: '181doctor', label: 'Palliativmedizin', link: 'doctor5' },
  { value: '182doctor', label: 'Pathologie', link: 'doctor5' },
  { value: '183doctor', label: 'Pharmakologie', link: 'doctor5' },
  { value: '184doctor', label: 'Pharmakologie und Texikologie', link: 'doctor5' },
  { value: '185doctor', label: 'Phlebologie', link: 'doctor5' },
  { value: '186doctor', label: 'Physikalische Therapie und Balneologie', link: 'doctor5' },
  { value: '187doctor', label: 'Physikalische und Rehabilitative Medizin', link: 'doctor5' },
  { value: '188doctor', label: 'Physiologie', link: 'doctor5' },
  { value: '189doctor', label: 'Plastische Operationen', link: 'doctor5' },
  { value: '190doctor', label: 'Plastische und Ästhetische Chirurgie', link: 'doctor5' },
  { value: '191doctor', label: 'Proktologie', link: 'doctor5' },
  { value: '192doctor', label: 'Psychiatrie und Psychotherapie', link: 'doctor5' },
  { value: '193doctor', label: 'Psychoanalyse', link: 'doctor5' },
  { value: '194doctor', label: 'Psychosomatische Medizin und Psychotherapie', link: 'doctor5' },
  { value: '195doctor', label: 'Psychotherapie-fachgebunden', link: 'doctor5' },
  { value: '196doctor', label: 'Radiologie', link: 'doctor5' },
  { value: '197doctor', label: 'Rechtsmedizin', link: 'doctor5' },
  { value: '198doctor', label: 'Rhytmologie', link: 'doctor5' },
  { value: '199doctor', label: 'Schlafmedizin', link: 'doctor5' },
  { value: '200doctor', label: 'Sozialmedizin', link: 'doctor5' },
  { value: '201doctor', label: 'Spezielle Geburtshilfe und Perinatalmedizin', link: 'doctor5' },
  { value: '202doctor', label: 'Spezielle Orthopädische Chirurgie', link: 'doctor5' },
  { value: '203doctor', label: 'Spezielle Schmerztherapie', link: 'doctor5' },
  { value: '204doctor', label: 'Spezielle Unfallchirurgie', link: 'doctor5' },
  { value: '205doctor', label: 'Sportmedizin', link: 'doctor5' },
  { value: '206doctor', label: 'Sprach-, Stimm- und kindliche Hörstörungen', link: 'doctor5' },
  { value: '207doctor', label: 'Strahlentherapie', link: 'doctor5' },
  { value: '208doctor', label: 'Suchtmedizinische Grundversorgung', link: 'doctor5' },
  { value: '209doctor', label: 'Thoraxchirurgie', link: 'doctor5' },
  { value: '210doctor', label: 'Transfusionsmedizin', link: 'doctor5' },
  { value: '211doctor', label: 'Tropenmedizin', link: 'doctor5' },
  { value: '212doctor', label: 'Urologie', link: 'doctor5' },
  { value: '213doctor', label: 'Viszeralchirurgie', link: 'doctor5' },
  { value: '214doctor', label: 'Zahnmedizin', link: 'doctor5' },
  { value: '215doctor', label: 'Ärztliches Qualitätsmanagement', link: 'doctor5' },
  { value: '216doctor', label: 'Öffentliche Gesundheitswesen', link: 'doctor5' },
]
const zm = [
  { value: 'Festanstellung', label: 'Festanstellung', id: "3" },
  { value: 'Befristet ', label: 'Befristet ', id: "4" },
];

const tv = [
  { value: 'Vollzeit', label: 'Vollzeit', id: "1" },
  { value: 'Teilzeit', label: 'Teilzeit', id: "2" },
];

class CreateJob extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      optSelect: {},
      opt2Select: {},
      opt3Select: {},
      zmSelect: null,
      tvSelect: null,
      file: null,
      company_name: '',
      comp_name: '',
      discription: '',
      address: '',
      zip_code: '',
      city: '',
      benefits: '',
      haus_no : '',
      PLZ:'',
      street:'',
      haus_no1:'',
      posst_code:'',
      city1:'',
      bill_comp_name:'',
      bill_email:'',
      bill_address:'',
      bill_haus_no:'',
      bill_PLZ:'',
      bills_VAT:'',
      about:'',
      statusSelect: null,
      imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage,
      visible: false,
      visible1: false,
      packageSelect:null,
      Rechnungsadresse:null,
      Unternehmensadresse:null,
      visible2: false,
      visible3: false,
      area:'',
      area1:'',
      visible4 : true,
      visible6 : false,
      visible7 : true
    };
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.changeHandler2 = this.changeHandler2.bind(this);
    this.changeHandler1 = this.changeHandler1.bind(this);
    this.changeHandler3 = this.changeHandler3.bind(this);
  }
  handleImageChange(e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      this.setState({
        file: file,
        imagePreviewUrl: reader.result,
      });
    };
    reader.readAsDataURL(file);
  }
  changeHandler = e => {
    this.setState({ [e.target.name]: e.target.value })
  }
  handleSubmit(e) {
    e.preventDefault();
    var bundesland1;
    var zmSelect1;
    var tvSelect1;
    if(this.state.file === null){
    if(this.state.optSelect.label === undefined){
      const bgrp_err = "Bitte wählen Sie Berufsgruppe";
      this.setState({bgrp_err})
    }
   if(this.state.area !== ''){
      var somestring = this.state.area;
    var index = somestring.indexOf(" ");
    var postcode = somestring.substr(0,index);
          console.log(postcode)
          // if (postcode.includes("1") || postcode.includes("2") || postcode.includes("3") || postcode.includes("4") || postcode.includes("5") || postcode.includes("6") || postcode.includes("7") || postcode.includes("8") || postcode.includes("9") || postcode.includes("0")) {
            if (postcode.includes(1) || postcode.includes(2) || postcode.includes(3) || postcode.includes(4) || postcode.includes(5)|| postcode.includes(6) || postcode.includes(7) || postcode.includes(8) || postcode.includes(9) || postcode.includes(0)) {
            console.log("number found", postcode)
           var city = somestring.substr(index+1);
          console.log("Second part city from number",city); 
          } else {
            console.log("string found", postcode)
            var city = this.state.area;
            console.log("city from string",city)
          }
       }
     if (this.state.opt2Select.label === "Bitte auswählen") {
     const func_err = "Bitte wählen Sie Funktion";
     this.setState({func_err})}
      if (this.state.statusSelect === null) {
      const unter_err = "Bitte wählen Sie Unternehmenstatigkeit";
      this.setState({unter_err})}
       if (this.state.zmSelect === null) {
        const Anstellungsart_err = "Bitte wählen Sie Anstellungsart";
        this.setState({Anstellungsart_err})}
         if (this.state.tvSelect === null) {
          const zeit_err = "Bitte wählen Sie Zeitmodell";
          this.setState({zeit_err})}
           if (this.state.discription === '' || this.state.discription === null) {
                  const disc_err = "Bitte geben Sie Aufgabenbeschreibung ein";
                  this.setState({disc_err})}
           if (this.state.benefits === '' || this.state.benefits === null) {
                    const benefits_err = "Bitte geben Sie Ihre Benefits ein";
                    this.setState({benefits_err})}
          
                    if(this.state.optSelect.label !== undefined && this.state.opt2Select.label !== "Bitte auswählen" && this.state.statusSelect !== null && this.state.zmSelect !== null && this.state.tvSelect !== null && this.state.discription !== '' && this.state.benefits !== '')
                    {
                      if(this.state.area === ''){
                        if(this.state.bundesland.label === undefined){
                          bundesland1 = this.state.bundesland;
                          console.log("from state",bundesland1)
                        }else{
                          bundesland1 = this.state.bundesland.label;
                          console.log("from label",bundesland1)
                        }
                        console.log("area is empty",this.state.city)
                             axios.post("/api2/addjobs1", {...this.state,bundesland1})
                      .then(response => { 
                       console.log(response)
                       const job_id = response.data[0].job_id;
                       localStorage.setItem('job_id',JSON.stringify(job_id));
                       console.log(job_id)
                  this.setState({redirect : true})
                    })
                    .catch(error => {
                     console.log(error);
                   })
                      }else{
                        console.log("area is not empty",city)
                        if(this.state.bundesland.label === undefined){
                          bundesland1 = this.state.bundesland;
                          console.log("from state",bundesland1)
                        }else{
                          bundesland1 = this.state.bundesland.label;
                          console.log("from label",bundesland1)
                        }
                             axios.post("/api2/addjobs1", {...this.state,city,bundesland1})
                      .then(response => { 
                       console.log(response)
                       // const msg1 = response.data.message;
                       // this.setState({msg1})
                       const job_id = response.data[0].job_id;
                       localStorage.setItem('job_id',JSON.stringify(job_id));
                       console.log(job_id)
                  this.setState({redirect : true})
                    })
                    .catch(error => {
                     console.log(error);
                   })
                     }
                    }
                  }
                
    ////////////////////////////////////////////////////////////////////////////////////
    else {
      if(this.state.optSelect.label === undefined){
        const bgrp_err = "Bitte wählen Sie Berufsgruppe";
        this.setState({bgrp_err})
      }
     if(this.state.area !== ''){
      var somestring = this.state.area;
    var index = somestring.indexOf(" ");
    var postcode = somestring.substr(0,index);
          console.log(postcode)
          // if (postcode.includes("1") || postcode.includes("2") || postcode.includes("3") || postcode.includes("4") || postcode.includes("5") || postcode.includes("6") || postcode.includes("7") || postcode.includes("8") || postcode.includes("9") || postcode.includes("0")) {
            if (postcode.includes(1) || postcode.includes(2) || postcode.includes(3) || postcode.includes(4) || postcode.includes(5)|| postcode.includes(6) || postcode.includes(7) || postcode.includes(8) || postcode.includes(9) || postcode.includes(0)) {
            console.log("number found", postcode)
           var city = somestring.substr(index+1);
          console.log("Second part city from number",city); 
          } else {
            console.log("string found", postcode)
            var city = this.state.area;
            console.log("city from string",city)
          }
       }
       if (this.state.opt2Select.label === "Bitte auswählen") {
       const func_err = "Bitte wählen Sie Funktion";
       this.setState({func_err})}
        if (this.state.statusSelect === null) {
        const unter_err = "Bitte wählen Sie Unternehmenstatigkeit";
        this.setState({unter_err})}
         if (this.state.zmSelect === null) {
          const Anstellungsart_err = "Bitte wählen Sie Anstellungsart";
          this.setState({Anstellungsart_err})}
           if (this.state.tvSelect === null) {
            const zeit_err = "Bitte wählen Sie Zeitmodell";
            this.setState({zeit_err})}
             if (this.state.discription === '' || this.state.discription === null) {
                    const disc_err = "Bitte geben Sie Aufgabenbeschreibung ein";
                    this.setState({disc_err})}
             if (this.state.benefits === '' || this.state.benefits === null) {
                      const benefits_err = "Bitte geben Sie Ihre Benefits ein";
                      this.setState({benefits_err})}
                      if(this.state.optSelect.label !== undefined && this.state.opt2Select.label !== "Bitte auswählen" && this.state.statusSelect !== null && this.state.zmSelect !== null && this.state.tvSelect !== null && this.state.discription !== '' && this.state.benefits !== '')
                    
                    {
                      if(this.state.zmSelect[1]=== undefined){
                        zmSelect1 = null;
                        console.log(zmSelect1)
                      }else{
                        zmSelect1 = this.state.zmSelect[1].label
                        console.log(zmSelect1)
                      }
                      if(this.state.tvSelect[1]=== undefined){
                        tvSelect1 = null;
                        console.log(tvSelect1)
                      }else{
                        tvSelect1 = this.state.tvSelect[1].label
                        console.log(tvSelect1)
                      }
                      if(this.state.area === ''){
                        console.log("area is empty",this.state.city)
                        if(this.state.bundesland.label === undefined){
                          bundesland1 = this.state.bundesland;
                          console.log("from state",bundesland1)
                        }else{
                          bundesland1 = this.state.bundesland.label;
                          console.log("from label",bundesland1)
                        }
                        console.log(this.state.zmSelect)
                        console.log(this.state.zmSelect)
                        const data = new FormData()
                      data.set('hosp_name',this.state.hosp_name)
                      data.set('adress',this.state.adress)
                      data.set('haus_number',this.state.haus_number)
                      data.set('zip_code',this.state.zip_code)
                      data.set('city',this.state.city)
                      data.set('bundesland1',bundesland1)
                      data.set('hosp_id', this.state.hosp_id)
                      data.set('job_id', this.state.job_id)
                      data.set('optSelect', this.state.optSelect.label)
                      data.set('opt2Select', this.state.opt2Select.label)
                      data.set('opt3Select', this.state.opt3Select.label)
                      data.set('discription', this.state.discription)
                      data.set('benefits', this.state.benefits)
                      data.set('about', this.state.about)
                      data.set('statusSelect', this.state.statusSelect.label)
                      data.set('zmSelect', this.state.zmSelect[0].label)
                      data.set('tvSelect',this.state.tvSelect[0].label)
                      data.set('zmSelect1',zmSelect1)
                      data.set('tvSelect1',tvSelect1)
                      data.append('file', this.state.file)
                      axios.post("/api2/addjobs", data)
                        .then(response => { 
                          console.log(response)
                          const job_id = response.data[0].job_id;
                            localStorage.setItem('job_id',JSON.stringify(job_id));
                            console.log(job_id)
                            this.setState({redirect : true})
                          
                      })
                      .catch(error => {
                        console.log(error);
                      })
                      }else{
                        console.log("area is not empty",city)
                        if(this.state.bundesland.label === undefined){
                          bundesland1 = this.state.bundesland;
                          console.log("from state",bundesland1)
                        }else{
                          bundesland1 = this.state.bundesland.label;
                          console.log("from label",bundesland1)
                        }
                        const data = new FormData()
                      data.set('hosp_name',this.state.hosp_name)
                      data.set('adress',this.state.adress)
                      data.set('haus_number',this.state.haus_number)
                      data.set('zip_code',this.state.zip_code)
                      data.set('city',city)
                      data.set('bundesland1',bundesland1)
                      data.set('hosp_id', this.state.hosp_id)
                      data.set('job_id', this.state.job_id)
                      data.set('optSelect', this.state.optSelect.label)
                      data.set('opt2Select', this.state.opt2Select.label)
                      data.set('opt3Select', this.state.opt3Select.label)
                      data.set('discription', this.state.discription)
                      data.set('benefits', this.state.benefits)
                      data.set('about', this.state.about)
                      data.set('statusSelect', this.state.statusSelect.label)
                      data.set('zmSelect', this.state.zmSelect[0].label)
                      data.set('tvSelect',this.state.tvSelect[0].label)
                      data.set('zmSelect1',zmSelect1)
                      data.set('tvSelect1',tvSelect1)
                      data.append('file', this.state.file)
                      axios.post("/api2/addjobs", data)
                        .then(response => { 
                          console.log(response)
                          const job_id = response.data[0].job_id;
                            localStorage.setItem('job_id',JSON.stringify(job_id));
                            console.log(job_id)
                            this.setState({redirect : true})
                          
                      })
                      .catch(error => {
                        console.log(error);
                      })
                      }
                    }
     }
  }
  // changeHandler5 = Unternehmensadresse => {
  //   this.setState(pv => {
  //     return { ...pv, Unternehmensadresse: Unternehmensadresse };
  //   }, () => {
  //     if (this.state.Unternehmensadresse.label === "Unternehmensadresse ändern"){
  //       this.setState(pv => ({ ...pv, visible3: true , visible4 : false }));
  //     } else {
  //       this.setState({ visible3: false, visible4 : true });
  //     }
  //   });

  // }

  changeHandler6 = UberUns => {
    this.setState(pv => {
      return { ...pv, UberUns: UberUns };
    }, () => {
      if (this.state.UberUns.label === "Über uns ändern"){
        this.setState(pv => ({ ...pv, visible6: true, visible7 : false }));
      } else {
        this.setState({ visible6: false , visible7 : true });
      }
    });

  }
  componentWillMount(){
    const {hosp_id} =  this.props.match.params;
    console.log(hosp_id)
    this.setState({hosp_id})
  }
  componentDidMount() {
   console.log(this.state)
  //  console.log(this.props.match.params);
  //  const { hosp_id } = this.props.match.params;
  //  console.log(hosp_id);
    axios.post("/api2/getcomadress", this.state)
         .then(response => { 
          console.log(response)
          const hosp_name = response.data[0].hosp_name;
          this.setState({hosp_name})
          const adress = response.data[0].adress;
          this.setState({adress})
          const haus_number = response.data[0].haus_number;
          this.setState({haus_number})
          const zip_code = response.data[0].zip_code;
          this.setState({zip_code})
          const city = response.data[0].city;
          this.setState({city})
          const bundesland = response.data[0].state;
          this.setState({bundesland})
          const about = response.data[0].hosp_disc;
          this.setState({about})
          const hosp_logo = response.data[0].hosp_logo;
          this.setState({hosp_logo})
          const imagePreviewUrl = `https://unternehmen.hello-doctor.de/uploads/${hosp_logo}`
          this.setState({imagePreviewUrl})
          
       })
       .catch(error => {
        console.log(error);
        
      })
      ///////////////////////////////////
      // axios.post("/api1/getbankadress", this.state)
      //    .then(response => { 
      //     console.log(response)
      //     const email = response.data[0].bill_email;
      //     this.setState({email})
      //     const company_name = response.data[0].company_name;
      //     this.setState({company_name})
      //     const street = response.data[0].street;
      //     this.setState({street})
      //     const haus_no1 = response.data[0].haus_no;
      //     this.setState({haus_no1})
      //     const post_code = response.data[0].post_code;
      //     this.setState({post_code})
      //     const city1 = response.data[0].city;
      //     this.setState({city1})
      //     const VAT = response.data[0].VAT;
      //     this.setState({VAT})
      //  })
      //  .catch(error => {
      //   console.log(error);
        
      // })
  }
  handleClick() {
    this.refs.fileInput.click();
  }
  handleRemove() {
    this.setState({
      file: null,
      imagePreviewUrl: this.props.avatar ? defaultAvatar : `https://unternehmen.hello-doctor.de/uploads/${this.state.hosp_logo}`
    });
    this.refs.fileInput.value = null;
  }
  changeHandler2 = optSelect => {
    this.setState(pv => {
      return { ...pv, optSelect: optSelect };
    }, () => {
      if (this.state.optSelect.label === "Arzt" ||
        this.state.optSelect.label === "Altenpflege" ||
        this.state.optSelect.label === "Krankenpflege" ||
        this.state.optSelect.label === "Therapeut" ||
        this.state.optSelect.label === "Medizintechnik" ||
        this.state.optSelect.label === "Rettungsdienst" ||
        this.state.optSelect.label === "Praxispersonal" ||
        this.state.optSelect.label === "Pharmazie") {
        let nextVal = opt2.filter((o) => o.link === this.state.optSelect.value)[0];
        this.changeHandler1(nextVal);
        this.setState(pv => ({ ...pv, visible: true }));
      } else {
        this.setState({ visible: false });
      }
    });

  }

  changeHandler1 = optSelect => {
    this.setState(() => ({ opt2Select: optSelect }), () => {
      if (this.state.opt2Select.label === "Assistenzarzt" ||
        this.state.opt2Select.label === "Facharzt" ||
        this.state.opt2Select.label === "Oberarzt" ||
        this.state.opt2Select.label === "Ltd, Oberarzt" ||
        this.state.opt2Select.label === "Chefarzt" ||
        this.state.opt2Select.label === "Anästhesieschwester" ||
        this.state.opt2Select.label === "Anästhesietechnische Assistentin (ATA)" ||
        this.state.opt2Select.label === "Fachkrankenschwester" ||
        this.state.opt2Select.label === "Gesundheitspflegehelfer/Krankenpflegehelfer" ||
        this.state.opt2Select.label === "Gesundheitspfleger/Krankenpfleger" ||
        this.state.opt2Select.label === "Hebamme" ||
        this.state.opt2Select.label === "Heilerziehungspfleger" ||
        this.state.opt2Select.label === "Kinderkrankenschwester" ||
        this.state.opt2Select.label === "OP Schwester" ||
        this.state.opt2Select.label === "Operationstechnische Assistentin (OTA)" ||
        this.state.opt2Select.label === "Pflegeassistent" ||
        this.state.opt2Select.label === "Pflegedienstleitung" ||
        this.state.opt2Select.label === "Stationsleitung" ||
        this.state.opt2Select.label === "Study Nurse") {
          let nextVal1 = opt3.filter((p) => p.link === this.state.opt2Select.value)[0];
          this.changeHandler3(nextVal1);
        this.setState({ visible1: true })
        console.log("if works", this.state);
      } else {
        this.setState({ visible1: false });
        console.log("else works", this.state);
      }

    })
  }

  changeHandler3 = opt2Select => {
    this.setState(pv => {
      return { ...pv, opt3Select: opt2Select };
    } );
  }

  // changeHandler4 = Rechnungsadresse => {
  //   this.setState(pv => {
  //     return { ...pv, Rechnungsadresse: Rechnungsadresse };
  //   }, () => {
  //     if (this.state.Rechnungsadresse.label === "Rechnungsadresse ändern"){
  //       this.setState(pv => ({ ...pv, visible2: true }));
  //     } else {
  //       this.setState({ visible2: false });
  //     }
  //   });

  // }

  changeHandler5 = Unternehmensadresse => {
    this.setState(pv => {
      return { ...pv, Unternehmensadresse: Unternehmensadresse };
    }, () => {
      if (this.state.Unternehmensadresse.label === "Unternehmensadresse ändern"){
        this.setState(pv => ({ ...pv, visible3: true , visible4 : false }));
      } else {
        this.setState({ visible3: false , visible4 : true });
      }
    });

  }

  render() {
    const { redirect } = this.state;
    if (redirect) {
      return <Redirect to='/admin/create-job-last'/>;
    }
    const filteredOpt = opt2.filter((o) => o.link === this.state.optSelect.value)
    const filteredOpt1 = opt3.filter((p) => p.link === this.state.opt2Select.value)
    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              {this.state.err1} {this.state.err2}
              <Form onSubmit={this.handleSubmit}>
                <Card>
                  <CardHeader>
                    <h4> <u> <b> Unternehmenslogo </b>  </u> </h4>
                    <Col md="4">
{/*               
                      <Badge color="warning">{this.state.file_err}</Badge>
               */}
                      <Badge color="success">{this.state.msg1}</Badge>
                      <hr></hr>
                      {/* <Row>
                        <Col md='2'>
                          <div className="fileinput text-center">
                            <input type="file" onChange={this.handleImageChange} ref="fileInput" name="file" />
                            <div style={{width: '150px'}} className={"thumbnail" + (this.props.avatar ? " img-circle" : "")}>
                              <img src={this.state.imagePreviewUrl} alt="..." />
                            </div>
                            
                            <div>
                              {this.state.file === null ? (
                             
                               <Button color="primary"
                               style={{width: '130px',
                               height: '58px',
                               fontSize: '11px',
                               padding: '13px'
                              }}
                               onClick={() => this.handleClick()}>
                                  {this.props.avatar ? "Ihr Photo" : "Logo hochladen (Optional)"}
                                </Button>
                               
                              
                              
                               
                              ) : (
                                  <span>
                                    <Button 
                                    color="primary"
                                    style={{width: '130px',
                                    height: '42px',
                                    fontSize: '11px',
                                    padding: '13px'
                                   }}
                                    onClick={() => this.handleClick()}>
                                      Ändern
              </Button>
                                    {this.props.avatar ? <br /> : null}
                                    <Button
                                     color="danger"
                                     style={{width: '130px',
                                     height: '42px',
                                     fontSize: '9px',
                                     borderRadius: '32px',
                                     boxShadow: '4.5px 7.794px 13px 0px #afc1ec',
                                     fontWeight: '400',
                                     border: 'none !important',
                                     padding: '13px 27px',
                                     background: '#dc3545'
                                   }}
                                      onClick={() => this.handleRemove()}
                                    >
                                      <i className="fa fa-times" />
                Löschen
              </Button>
                                  </span>
                                )}
                            </div>
                          </div>
                        </Col>

                      </Row> */}
                       <div className="fileinput text-center">
                    <h4 className = 'title'> <u>  Logo hochladen </u> </h4>
        <input type="file" onChange={this.handleImageChange} ref="fileInput"  name = "file" />
        {/* <div className={"thumbnail" + (this.props.avatar ? " img-circle" : "")} style={{width: '180px'}}> */}
        <div className={"thumbnail" + (this.props.avatar ? " img-circle" : "")} style={{width: '180px'}}>
          <img src={this.state.imagePreviewUrl} alt="..." />
          {/* <img src={`../uploads/${this.state.hosp_logo}`} alt="..." /> */}
        </div>
        
          <Row>
            <Col>
            <div>
          {this.state.file === null ? (
            <Button className="btn-round" onClick={() => this.handleClick()}>
              {this.props.avatar ? "Ihr Photo" : "Logo auswählen (Optional)"}
            </Button>
  
          ) : (
            <span>
              <Button className="btn-round" onClick={() => this.handleClick()}>
                Ändern
              </Button>
              {this.props.avatar ? <br /> : null}
              <Button
                color="danger"
                style={{width: '112px',
                height: '25px',
                fontSize: '9px',
                boxShadow: '4.5px 7.794px 13px 0px #afc1ec',
                fontWeight: '400',
                border: 'none !important',
                padding: '0',
                background: '#dc3545'
              }}
                onClick={() => this.handleRemove()}
              >
                <i className="fa fa-times" />
                Löschen
              </Button>
            </span>
          )}
        </div>
            </Col>
           
          </Row>
          
      </div>
                    </Col>
                    
                    <hr></hr>
                    <br></br>
                 
                    <Row>
                      <Col>
                      <br></br>
                        <h4 className='title'><u>Unternehmensadresse</u></h4>
                        <br></br>
                        
                    <FormGroup>
                    <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="statusSelect"
                            value={this.state.Unternehmensadresse}
                            onChange={this.changeHandler5
                            }
                            options={[
                            {
                                value: "1",
                                label: "Unternehmensadresse ändern"
                              }, 
                               {
                                value: "2",
                                label: "Vorherige Unternehmensadresse"
                              },
                              
                        ]}
                            placeholder="Unternehmensadresse ändern"
                          />
                    </FormGroup>
                   
                    <br></br> 
                        {this.state.visible4 ?  <span>    <Row>
                      <Col md ='12'>
                      <b>{this.state.hosp_name}</b>
                      <p>{this.state.adress} {this.state.haus_number}</p>
                     <p> {this.state.zip_code} {this.state.city},{this.state.bundesland}</p>
                        </Col></Row>
                      </span> : null}
                

                    <br></br>
                    <hr></hr>
                   
                    
                    {this.state.visible3 ?
                    <Row>
                      <Col><label>Name des Unternehmens / Firma</label> </Col>
                    </Row>
                    : null }
                    {this.state.visible3 ?
                    <Row>
                      <Col>
                        <Input
                          type="Text"
                          autoComplete="off"
                          value = {this.state.hosp_name}
                          name="hosp_name"
                          onChange={this.changeHandler}
                          required="Name des Unternehmens / Firma"
                        />
                         <Badge color="warning">{this.state.address_err}</Badge> 
                      </Col>
                    </Row>
                    : null}
                    {this.state.visible3 ?
                    <br></br>
                    : null}
                    {this.state.visible3 ?
                    <Row>
                      <Col><label>Straße</label> </Col>
                      <Col><label>Hausnummer</label> </Col>
                    </Row>
                    : null }
                    {this.state.visible3 ?
                    <Row>
                      <Col>
                        <Input
                          type="Text"
                          autoComplete="off"
                          value={this.state.adress}
                          name="adress"
                          onChange={this.changeHandler}
                          required
                          />
                         <Badge color="warning">{this.state.address_err}</Badge> 
                      </Col>
                      <Col>
                        <Input
                          type="Text"
                          autoComplete="off"
                          value={this.state.haus_number}
                          name="haus_number"
                          onChange={this.changeHandler}
                          required
                        />
                         <Badge color="warning">{this.state.haus_err}</Badge> 
                      </Col>
                    </Row>
                    : null}
                    {this.state.visible3 ?
                    <br></br>
                    : null}
                    {this.state.visible3 ?
                    <Row>
                      <Col><label>PLZ</label> </Col>
                      <Col><label>Ort</label> </Col>
                    </Row>
                    : null}
                    {this.state.visible3 ?
                    <Row>
                      <Col>
                        <Input
                          type="Text"
                          autoComplete="off"
                          value={this.state.zip_code}
                          name="zip_code"
                          onChange={this.changeHandler}
                          required
                        />
                         <Badge color="warning">{this.state.post_err}</Badge> 
                      </Col>
                      <Col>
                       <GooglePlacesAutocomplete
                      onChange={this.changeHandler}
                      onSelect={({description})=>(this.setState({area:description}))}
                      autocompletionRequest={{
                        componentRestrictions: {
                          country: 'de'
                        },
                        types: ["(regions)"],
                        terms:[
                              'cities'
                        ]
                      }}
                      placeholder={this.state.city}
                      inputClassName='form-control'
                      inputStyle={{height: '38px'}}
                      required
                      //initialValue="description"
                      required
                     
                    /> 
                         <Badge color="warning">{this.state.city_err}</Badge> 
                      </Col>
                    </Row>
                    :null}
                   {this.state.visible3 ?
                    <br></br>
                    : null}
                    {this.state.visible3 ?
                    <Row>
                      <Col><label>Bundesland</label></Col>
                      <Col>
                      <label>Land</label>
                      </Col>
                     
                      </Row>
                      :null}
                      {this.state.visible3 ?
                     <span> <Row>
                       <Col>
                      <Select
                   className="react-select primary"
                   classNamePrefix="react-select"
                   name="bundesland"
                   placeholder = {this.state.bundesland}
                   value={this.state.bundesland}
                   onChange={value =>
                     this.setState({ bundesland: value })
                   }
                   options={states} 
                  //  styles={customStyles}
                 />
                      </Col>
                        <Col>
                      <Input
                        type="Text"
                        name = "Input"  
                        value="Deutschland"
                        readOnly
                      />
                      </Col>
                      
                    </Row>
                    <br></br>
                    <hr></hr> </span>
                    :null}   

                    
                    <br></br>
                        <h4 className='title'> <u> Unternehmenstätigkeit </u> </h4>
                        <br></br>
                        <label style={{fontSize: '12px'}}>Wählen Sie bitte mindestens eine Tätigkeit aus, der Ihr Unternehmen zugeordnet werden kann.</label>
                        <Badge color="warning">{this.state.unter_err}</Badge>
             
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          name="statusSelect"
                          value={this.state.statusSelect}
                          onChange={value =>
                            this.setState({ statusSelect: value })
                          }
                          options={[{
                            value: "1",
                            label: "Altenheim / Pflegeheim "
                          },
                          {
                            value: "2",
                            label: "Krankenhaus"
                          },
                          {
                            value: "3",
                            label: "MVZ"
                          },
                          {
                            value: "4",
                            label: "Pflegedienst"
                          },
                          {
                            value: "5",
                            label: "Arztpraxis"
                          },
                          {
                            value: "5",
                            label: "Reha-Klinik"
                          },
                          ]}
                          placeholder="Bitte auswählen"
                          styles={customStyles}
                        />
                        <br></br>
                        <br></br>
                        <h4 className='title'> <u> Über uns </u> </h4>
                        <br></br>
                        <br></br>
                       
                    <FormGroup>
                    <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="statusSelect"
                            value={this.state.UberUns}
                            onChange={this.changeHandler6
                            }
                            options={[
                            {
                                value: "1",
                                label: "Über uns ändern"
                              }, 
                               {
                                value: "2",
                                label: "Vorherige Über uns"
                              },
                              
                        ]}
                            placeholder="Über uns ändern"
                          />
                    </FormGroup>
                    
                    {this.state.visible7 ? <span><br></br> 
                       <br></br>
                       {this.state.about}</span>: null}
                       {this.state.visible6 ? <span> <br></br>
                        <label style={{fontSize: '12px'}}>Beschreiben Sie Ihr Unternehmen.</label>
                        <br></br>
                        <Input 
                    style={{fontSize: '15px'}}
                    type="textarea"
                        rows = "5"
                        cols = "5"
                        value={this.state.about}
                        name='about'
                      //name='benefits'
                      onChange={this.changeHandler}
                    />
                       </span>: null}
                      </Col>
                    </Row>
                    <hr></hr>
                  </CardHeader>
                  <br></br>
                  <br></br>
                  <CardBody>
                    <h4 className='title'><u>Stellendetails</u></h4>
                    <br></br>
                    <Row>
                      <Col className="" md="4">
                        <FormGroup>
                          <label className="title"  style={{fontSize: '12px'}}>Berufsgruppe</label>
                          <Badge color="warning">{this.state.bgrp_err}</Badge>
                 
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="titleSelect"
                            value={this.state.optSelect}
                            onChange={this.changeHandler2}
                            options={opt}
                            styles={customStyles}
                          />
                          {/* <Badge color="warning">{this.state.grop_err}</Badge>
                 */}
                        </FormGroup>
                
                      </Col>
                      {this.state.visible ?
                        <Col className="" md="4">
                          <FormGroup>
                            <label className="title"  style={{fontSize: '12px'}}>Funktion, Position</label>
                            <Badge color="warning">{this.state.func_err}</Badge>
                   
                            <Select
                              className="react-select primary"
                              classNamePrefix="react-select"
                              name="titleSelect"
                              value={this.state.opt2Select}
                              onChange={this.changeHandler1}
                              options={filteredOpt}
                              styles={customStyles}
                            />
                            <Badge color="warning">{this.state.func_err}</Badge>
                          </FormGroup>
                        </Col>
                        : null}
                      {this.state.visible1 ?
                        <Col className="" md="4">
                          <FormGroup>
                            <label className="title"  style={{fontSize: '12px'}}>Fachgebiet, Fachabteilungen</label>
                            <Select
                            styles={customStyles}
                              //isMulti
                              //closeMenuOnSelect={false}
                              className="react-select primary"
                              classNamePrefix="react-select"
                              name="titleSelect"
                              value={this.state.opt3Select}
                              onChange={this.changeHandler3}
                              options={filteredOpt1}
                            />
                          </FormGroup>
                        </Col>
                        : null}
                    </Row>
                    <br></br>
                    <hr></hr>
                    <br></br>
                    <h5 className='title'><u>Aufgabenbeschreibung</u></h5>
                    <br></br>
                    <label  style={{fontSize: '12px'}}>Beschreiben Sie die Aufgabe hier</label>
                    <br></br>
                    <Badge color="warning">{this.state.disc_err}</Badge>
               
                    <Input 
                    style={{fontSize: '15px'}}
                    type="textarea"
                        rows = "5"
                        cols = "5"
                      name="discription"
                      onChange={this.changeHandler} />
                    <br></br>
                      <hr></hr>
                   <br></br>
                    <h4 className='title'><u>Rahmenbedingungen</u></h4>
                    <br></br>
                    <Row>
                      <Col md="6">
                        <label className='title'  style={{fontSize: '12px'}}>Anstellungsart</label>
                        <br></br>
                        <label  style={{fontSize: '12px'}}>Bitte geben Sie die möglichen Arten der Anstellung an.</label>
                        <br></br>
                        <Badge color="warning">{this.state.Anstellungsart_err}</Badge>
                 
                        <Select
                        styles={customStyles}
                           isMulti
                        //  closeMenuOnSelect={false}
                          className="react-select primary"
                          classNamePrefix="react-select"
                          name="titleSelect"
                          placeholder = 'Bitte auswählen'
                          value={this.state.zmSelect}
                          onChange={value =>
                            this.setState({ zmSelect: value })
                          }
                          options={zm}
                        />
                        {/* <Badge color="warning">{this.state.zm_err}</Badge>
                      */}
                      </Col>

                      <Col md="6">
                        <label className='title'  style={{fontSize: '12px'}}>Zeitmodell</label>
                        <br></br>
                        <label  style={{fontSize: '12px'}}>Handelt es sich um eine Vollzeit- oder Teilzeitstelle?</label>
                        <br></br>
                        <Badge color="warning">{this.state.zeit_err}</Badge>
                     
                        <Select
                          styles={customStyles}
                          isMulti
                         // closeMenuOnSelect={false}
                          className="react-select primary"
                          classNamePrefix="react-select"
                          name="titleSelect"
                          placeholder = 'Bitte auswählen'
                          value={this.state.tvSelect}
                          onChange={value =>
                            this.setState({ tvSelect: value })
                          }
                          options={tv}
                        />
                        {/* <Badge color="warning">{this.state.tv_err}</Badge>
                      */}
                      </Col>
                    </Row>
                    <br></br>
                    <hr></hr>
                    <br></br>
                    <Row>
                      <Col>
                      <h4 className='title'><u>Benefits</u></h4>
                    <br></br>
                    <label  style={{fontSize: '12px'}}>Welche Benefits bietet Ihr Unternehmen an?</label>
                    <br></br>
                    <Badge color="warning">{this.state.benefits_err}</Badge>
                    <Input 
                    style={{fontSize: '15px'}}
                    type="textarea"
                        rows = "10"
                        cols = "15"

                      name='benefits'
                      onChange={this.changeHandler}
                    />
                      </Col>
                    
                    </Row>
                    <hr></hr>
                  </CardBody>
                  <CardFooter>
                    <Row>
                      <Col md='10'>
                      {/* <label className = "title" style = {{fontSize: '15px'}}> <b>Ihre Angaben können von Ihnen NICHT mehr bearbeitet werden, nachdem die Stellenanzeige veröffentlicht wurde. Bitte überprüfen Sie Ihre Eingaben daher sorgfältig. Sollte Sie im Nachgang einen Änderungsbedarf an Ihrer Stellenanzeige feststellen, können Sie uns gern <Link to = {"/admin/contact"} target="_blank">kontaktieren </Link>.</b></label> */}
                      </Col>
                      <Col>
                      {/* <Link to = {'/admin/job-print'}> */}
                        <Button color="primary"
                         onClick={this.handleSubmit} 
                        >
                           WEITER</Button>
                        {/* </Link> */}
                      </Col>
                    </Row>
                    <br></br>
                  </CardFooter>
                </Card>
              </Form>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
export default CreateJob;

