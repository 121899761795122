import React from "react";
import axios from 'axios';
// react plugin used to create charts
// import { Line, Bar, Doughnut } from "react-chartjs-2";
// // react plugin for creating vector maps
// import { VectorMap } from "react-jvectormap";
 import { Link } from "react-router-dom"
// reactstrap components
import {
  //Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  Table,
  //UncontrolledTooltip
} from "reactstrap";

// import {
//   chartExample1,
//   chartExample2,
//   chartExample3,
//   chartExample4,
//   chartExample5,
//   chartExample6,
//   chartExample7,
//   chartExample8
// } from "variables/charts.jsx";
// import {chartProvision, chartJobs, chartInstitutions} from "variables/data_charts_hm.jsx"
//import { monthNames} from "variables/general.jsx"

// import TableTop from "building_block/table_top"


// import FixedMessage from "components/FixedMessage";
// import { isTemplateMiddle } from "typescript";

// var mapData = {
//   AU: 760,
//   BR: 550,
//   CA: 120,
//   DE: 1300,
//   FR: 540,
//   GB: 690,
//   GE: 200,
//   IN: 200,
//   RO: 600,
//   RU: 300,
//   US: 2920
// };



class CandidateByStellen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      countJobs: 0,
      data : [],
      data1 : [],
      cand : [],
      msg : ''
    };
  }
//   eHandle (userid, e){
//   e.preventDefault();
//   //const { company_name, person_name} = this.state
//     console.log(userid)
//     axios
//     .post('/api2/rejecthospital', {...this.state,userid})
//     .then(response => {
//       console.log(response);
//       const msg = "Sie lehnen diese Anfrage ab";
//         this.setState({msg})
//       window.location.reload();
//     })
//     .catch(error => {
//       console.log(error.response);
//     })
// }

 
  componentDidMount(){
    console.log(this.state);
    axios.get('/api2/candidatebystellen')
    .then(response => {
      console.log(response)
      const data = response.data;
      this.setState({ data });
      localStorage.setItem('hosp', JSON.stringify(this.state.hosp));
      console.log(data);
    
    })
    .catch(error => {
      console.log(error.response);
    })
       /////////////////////////////////////////////
       axios.get('/api2/candidateapply')
       .then(response => {
         console.log(response)
          const data1 = response.data;
          this.setState({ data1 });
          localStorage.setItem('hosp', JSON.stringify(this.state.hosp));
          console.log(data1);
       
       })
       .catch(error => {
         console.log(error.response);
       })
  }
  render() {
    // var d = new Date()
    // var actualMonth = monthNames[d.getMonth()]
    return (
      <>
        <div className="content">
        <Card>
                <CardHeader>
                  <h5 className="cstm-heading-backend"><b>Nicht Beworben </b></h5>
                  <hr></hr>
                </CardHeader>
                <CardBody>
                  <Table responsive>
                    <thead className="text-primary">
                      <tr>
                        <th className="text-left">Kandidaten</th>
                        <th className="text-left">Aktion</th>
                      </tr>
                    </thead>
                    <tbody>
                    {this.state.data.map((item) => {
                          return (
                            <>   
                    <tr>
                   
                    {/* {this.state.data1.map((item1) => {
                          return (
                            <> */}
                            {/* {item.cnt >= item1.cnt ?
                             
                          null
                            :   */}
                            <td className="text-left">  
                          
                                  <label className="text-ubold">
                                      <Link to = {`/admin/detail-kandidate/${item.app_id}`} target="_blank"> 
                                      {item.f_name} {item.l_name} <br></br>
                                      {item.haus_no}<br />
                                       {item.post_code}
                                      {item.city} <br></br>
                                      {item.telephone}</Link>     
                                    </label>   </td>
                            {/* } */}
                                   {/* </>
                          )
                        })}  */}
                     
                            
                      
                       
                         {/* {this.state.data1.map((item1) => {
                          return (
                            <> */}
                            {/* {item.cnt >= item1.cnt ?
                             
                          null
                            :   */}
                            <td>
                    <Link to = {`/admin/jobsbystellen/${item.app_id}`} target="_blank">
                    <Button color="primary" 
                      >
                        Jobs
                      </Button></Link></td> 
                      {/* } */}
                                   {/* </>
                          )
                        })}   */}
                    
                    
                        
                      
                        </tr>
                        
                         </>
                          )
                        })} 
                    </tbody>
                    <br></br>
                    <br></br>
               
                  </Table>
                </CardBody>
                </Card>
           
                {/* <Card>
                <CardHeader>
                  <h5 className="cstm-heading-backend"><b>Nicht Beworben </b></h5>
                  <hr></hr>
                </CardHeader>
                <CardBody>
                  <Table responsive>
                    <thead className="text-primary">
                      <tr>
                     
                        <th className="text-left">Kandidaten</th>
                        <th className="text-left">Aktion</th>
                      
                      </tr>
                    </thead>
                    <tbody>
                    {this.state.data1.map((item1) => {
                          return (
                            <>
                           
                    <tr>
                   
                    {this.state.data.map((item) => {
                          return (
                            <>
                            {item.cnt <= item1.cnt ?
                             
                          null
                            :  <td className="text-left">  
                          
                                  <label className="text-ubold">
                                      <Link to = {`/admin/detail-kandidate/${item.app_id}`} target="_blank"> 
                                      {item.f_name} {item.l_name} <br></br>
                                      {item.haus_no}<br />
                                       {item.post_code}
                                      {item.city} <br></br>
                                      {item.telephone}</Link>     
                                    </label>   </td>
                            }
                                   </>
                          )
                        })} 
                     
                            
                      
                       
                         {this.state.data.map((item) => {
                          return (
                            <>
                            {item.cnt <= item1.cnt ?
                             
                          null
                            :  <td>
                    <Link to = {`/admin/jobsbystellen/${item.app_id}`} target="_blank">
                    <Button color="primary" 
                      >
                        Jobs
                      </Button></Link></td> }
                                   </>
                          )
                        })}  
                        </tr>
                        
                         </>
                          )
                        })} 
                    </tbody>
                    <br></br>
                    <br></br>
               
                  </Table>
                </CardBody>
                </Card> */}
        </div>
      </>
    );
  }
}

export default CandidateByStellen;