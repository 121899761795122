import React from "react";
import axios from 'axios';
import { Link } from "react-router-dom"
import Select from "react-select";
import ReactPaginate from 'react-paginate';
import "../../style1.css";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardFooter,
  Collapse,
  Form,
  FormGroup,
  Input,
  Row,
  Col,
  //Table,
  Button,
  UncontrolledTooltip,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
const admin = [
  {
    id: "1",
    salutation: "Frau",
    title: "Prof",
    lastName: "Ketzer",
    firstName: "Dorothe",
    email: "d.ketzer@nn.de",
    telefon: "09887878",
    position: "CEO",
    status: "aktiv",
    pos: "Select.."
  }]
const opt = [
  {
    id: "1",
    label: "Arzt",
    value: "A"
  }
]
const opt2 = [
  { value: 'doctor0', label: 'Select', link: 'A' },
  { value: 'doctor1', label: 'Assistenzarzt', link: 'A' },
  { value: 'doctor2', label: 'Facharzt', link: 'A' },
  { value: 'doctor3', label: 'Oberarzt', link: 'A' },
  { value: 'doctor4', label: 'Ltd, Oberarzt', link: 'A' },
  { value: 'doctor5', label: 'Chefarzt', link: 'A' }
];
const opt3 = [
  { value: 'doctor7', label: 'Akupunktur', link: 'doctor1' },
  { value: 'doctor8', label: 'Allergologie', link: 'doctor1' },
  { value: 'doctor9', label: 'Allgemeinchirurgie', link: 'doctor1' },
  { value: 'doctor10', label: 'Allgemeinmedizin', link: 'doctor1' },
  { value: 'doctor11', label: 'Anatomie', link: 'doctor1' },
  { value: 'doctor12', label: 'Andrologie', link: 'doctor1' },
  { value: 'doctor13', label: 'Anästhesiologie', link: 'doctor1' },
  { value: 'doctor14', label: 'Arbeitsmedizin', link: 'doctor1' },
  { value: 'doctor15', label: 'Augenheilkunde', link: 'doctor1' },
  { value: 'doctor16', label: 'Biochemie', link: 'doctor1' },
  { value: 'doctor17', label: 'Chirurgie', link: 'doctor1' },
  { value: 'doctor18', label: 'Dermatohistologie', link: 'doctor1' },
  { value: 'doctor19', label: 'Diabetologie', link: 'doctor1' },
  { value: 'doctor20', label: 'Flugmedizin', link: 'doctor1' },
  { value: 'doctor21', label: 'Forensische Psychiatrie', link: 'doctor1' },
  { value: 'doctor22', label: 'Gefäßchirugie', link: 'doctor1' },
  { value: 'doctor23', label: 'Geriatrie', link: 'doctor1' },
  { value: 'doctor24', label: 'Gynäkologie und Geburtshilfe', link: 'doctor1' },
  { value: 'doctor25', label: 'Gynäkologische Endokrinologie und Reproduktionsmed', link: 'doctor1' },
  { value: 'doctor26', label: 'Gynäkologische Exfoliativ-Zytologie', link: 'doctor1' },
  { value: 'doctor27', label: 'Gynäkologische Onkologie', link: 'doctor1' },
  { value: 'doctor28', label: 'Hals-Nasen-Ohrenheilkunde (HNO)', link: 'doctor1' },
  { value: 'doctor29', label: 'Handchirurgie', link: 'doctor1' },
  { value: 'doctor30', label: 'Haut- und Geschlechtskrankheiten', link: 'doctor1' },
  { value: 'doctor31', label: 'Herzchirurgie', link: 'doctor1' },
  { value: 'doctor32', label: 'Homöopathie', link: 'doctor1' },
  { value: 'doctor33', label: 'Humangenetik', link: 'doctor1' },
  { value: 'doctor34', label: 'Hygiene und Umweltmedizin', link: 'doctor1' },
  { value: 'doctor35', label: 'Hämostaseologie', link: 'doctor1' },
  { value: 'doctor36', label: 'Infektiologie', link: 'doctor1' },
  { value: 'doctor37', label: 'Innere Medizin', link: 'doctor1' },
  { value: 'doctor38', label: 'Innere Medizin und Angiologie', link: 'doctor1' },
  { value: 'doctor39', label: 'Innere Medizin und Endokrinologie und Diabetologie', link: 'doctor1' },
  { value: 'doctor40', label: 'Innere Medizin und Gastroenterologie', link: 'doctor1' },
  { value: 'doctor41', label: 'Innere Medizin und Hämatologie und Onkologie', link: 'doctor1' },
  { value: 'doctor42', label: 'Innere Medizin und Kardiologie', link: 'doctor1' },
  { value: 'doctor43', label: 'Innere Medizin und Nephrologie', link: 'doctor1' },
  { value: 'doctor44', label: 'Innere Medizin und Pneumologie', link: 'doctor1' },
  { value: 'doctor45', label: 'Innere Medizin und Rheumatologie', link: 'doctor1' },
  { value: 'doctor46', label: 'Intensivmedizin', link: 'doctor1' },
  { value: 'doctor47', label: 'Interventionelle Kardiologie', link: 'doctor1' },
  { value: 'doctor48', label: 'Kinder- und Jugendmedizin', link: 'doctor1' },
  { value: 'doctor49', label: 'Kinder- und Jugendpsychiatrie und -psychotherapie', link: 'doctor1' },
  { value: 'doctor50', label: 'Kinder-Endokrinologie und -Diabetologie', link: 'doctor1' },
  { value: 'doctor51', label: 'Kinder-Gastroenterologie', link: 'doctor1' },
  { value: 'doctor52', label: 'Kinder-Hämatologie und -Onkologie', link: 'doctor1' },
  { value: 'doctor53', label: 'Kinder-Kardiologie', link: 'doctor1' },
  { value: 'doctor54', label: 'Kinder-Nephrologie, Kinder-Orthopädie', link: 'doctor1' },
  { value: 'doctor55', label: 'Kinder-Pneumologie', link: 'doctor1' },
  { value: 'doctor56', label: 'Kinder-Rheumatologie', link: 'doctor1' },
  { value: 'doctor57', label: 'Kinderchirurgie', link: 'doctor1' },
  { value: 'doctor58', label: 'Kinderradiologie', link: 'doctor1' },
  { value: 'doctor59', label: 'Klinische Pharmakologie', link: 'doctor1' },
  { value: 'doctor60', label: 'Krankenhaushygiene', link: 'doctor1' },
  { value: 'doctor61', label: 'Labordiagnostik-fachgebunden', link: 'doctor1' },
  { value: 'doctor62', label: 'Labormedizin', link: 'doctor1' },
  { value: 'doctor63', label: 'Magnetresonanztomographie-fachgebunden', link: 'doctor1' },
  { value: 'doctor64', label: 'Manuelle Medizin / Chirotherapie', link: 'doctor1' },
  { value: 'doctor65', label: 'Medikamentöse Tumortherapie', link: 'doctor1' },
  { value: 'doctor66', label: 'Medizinische Informatik', link: 'doctor1' },
  { value: 'doctor67', label: 'Mikrobiologie', link: 'doctor1' },
  { value: 'doctor68', label: 'Mund-, Kiefer- und Gesichtschirurgie', link: 'doctor1' },
  { value: 'doctor69', label: 'Naturheilverfahren', link: 'doctor1' },
  { value: 'doctor70', label: 'Neonatologie', link: 'doctor1' },
  { value: 'doctor71', label: 'Neurochirurgie', link: 'doctor1' },
  { value: 'doctor72', label: 'Neurologie', link: 'doctor1' },
  { value: 'doctor73', label: 'Neuropädiatrie', link: 'doctor1' },
  { value: 'doctor74', label: 'Neuroradiologie', link: 'doctor1' },
  { value: 'doctor75', label: 'Notfallmedizin', link: 'doctor1' },
  { value: 'doctor76', label: 'Nuklearmedizin', link: 'doctor1' },
  { value: 'doctor77', label: 'Orthopädie und Unfallchirurgie', link: 'doctor1' },
  { value: 'doctor78', label: 'Orthopädische Rheumatologie', link: 'doctor1' },
  { value: 'doctor79', label: 'Palliativmedizin', link: 'doctor1' },
  { value: 'doctor80', label: 'Pathologie', link: 'doctor1' },
  { value: 'doctor81', label: 'Pharmakologie', link: 'doctor1' },
  { value: 'doctor82', label: 'Pharmakologie und Texikologie', link: 'doctor1' },
  { value: 'doctor83', label: 'Phlebologie', link: 'doctor1' },
  { value: 'doctor84', label: 'Physikalische Therapie und Balneologie', link: 'doctor1' },
  { value: 'doctor85', label: 'Physikalische und Rehabilitative Medizin', link: 'doctor1' },
  { value: 'doctor86', label: 'Physiologie', link: 'doctor1' },
  { value: 'doctor87', label: 'Plastische Operationen', link: 'doctor1' },
  { value: 'doctor88', label: 'Plastische und Ästhetische Chirurgie', link: 'doctor1' },
  { value: 'doctor89', label: 'Proktologie', link: 'doctor1' },
  { value: 'doctor90', label: 'Psychiatrie und Psychotherapie', link: 'doctor1' },
  { value: 'doctor91', label: 'Psychoanalyse', link: 'doctor1' },
  { value: 'doctor92', label: 'Psychosomatische Medizin und Psychotherapie', link: 'doctor1' },
  { value: 'doctor93', label: 'Psychotherapie-fachgebunden', link: 'doctor1' },
  { value: 'doctor94', label: 'Radiologie', link: 'doctor1' },
  { value: 'doctor95', label: 'Rechtsmedizin', link: 'doctor1' },
  { value: 'doctor96', label: 'Rhytmologie', link: 'doctor1' },
  { value: 'doctor97', label: 'Schlafmedizin', link: 'doctor1' },
  { value: 'doctor98', label: 'Sozialmedizin', link: 'doctor1' },
  { value: 'doctor99', label: 'Spezielle Geburtshilfe und Perinatalmedizin', link: 'doctor1' },
  { value: 'doctor100', label: 'Spezielle Orthopädische Chirurgie', link: 'doctor1' },
  { value: 'doctor101', label: 'Spezielle Schmerztherapie', link: 'doctor1' },
  { value: 'doctor102', label: 'Spezielle Unfallchirurgie', link: 'doctor1' },
  { value: 'doctor103', label: 'Sportmedizin', link: 'doctor1' },
  { value: 'doctor104', label: 'Sprach-, Stimm- und kindliche Hörstörungen', link: 'doctor1' },
  { value: 'doctor105', label: 'Strahlentherapie', link: 'doctor1' },
  { value: 'doctor106', label: 'Suchtmedizinische Grundversorgung', link: 'doctor1' },
  { value: 'doctor107', label: 'Thoraxchirurgie', link: 'doctor1' },
  { value: 'doctor108', label: 'Transfusionsmedizin', link: 'doctor1' },
  { value: 'doctor109', label: 'Tropenmedizin', link: 'doctor1' },
  { value: 'doctor110', label: 'Urologie', link: 'doctor1' },
  { value: 'doctor111', label: 'Viszeralchirurgie', link: 'doctor1' },
  { value: 'doctor112', label: 'Zahnmedizin', link: 'doctor1' },
  { value: 'doctor113', label: 'Ärztliches Qualitätsmanagement', link: 'doctor1' },
  { value: 'doctor114', label: 'Öffentliche Gesundheitswesen', link: 'doctor1' },
  { value: 'doctor115', label: 'Akupunktur', link: 'doctor2' },
  { value: 'doctor116', label: 'Allergologie', link: 'doctor2' },
  { value: 'doctor117', label: 'Allgemeinchirurgie', link: 'doctor2' },
  { value: 'doctor118', label: 'Allgemeinmedizin', link: 'doctor2' },
  { value: 'doctor119', label: 'Anatomie', link: 'doctor2' },
  { value: 'doctor120', label: 'Andrologie', link: 'doctor2' },
  { value: 'doctor121', label: 'Anästhesiologie', link: 'doctor2' },
  { value: 'doctor122', label: 'Arbeitsmedizin', link: 'doctor2' },
  { value: 'doctor123', label: 'Augenheilkunde', link: 'doctor2' },
  { value: 'doctor124', label: 'Biochemie', link: 'doctor2' },
  { value: 'doctor125', label: 'Chirurgie', link: 'doctor2' },
  { value: 'doctor126', label: 'Dermatohistologie', link: 'doctor2' },
  { value: 'doctor127', label: 'Diabetologie', link: 'doctor2' },
  { value: 'doctor128', label: 'Flugmedizin', link: 'doctor2' },
  { value: 'doctor129', label: 'Forensische Psychiatrie', link: 'doctor2' },
  { value: 'doctor130', label: 'Gefäßchirugie', link: 'doctor2' },
  { value: 'doctor131', label: 'Geriatrie', link: 'doctor2' },
  { value: 'doctor132', label: 'Gynäkologie und Geburtshilfe', link: 'doctor2' },
  { value: 'doctor133', label: 'Gynäkologische Endokrinologie und Reproduktionsmed', link: 'doctor2' },
  { value: 'doctor134', label: 'Gynäkologische Exfoliativ-Zytologie', link: 'doctor2' },
  { value: 'doctor135', label: 'Gynäkologische Onkologie', link: 'doctor2' },
  { value: 'doctor136', label: 'Hals-Nasen-Ohrenheilkunde (HNO)', link: 'doctor2' },
  { value: 'doctor137', label: 'Handchirurgie', link: 'doctor2' },
  { value: 'doctor138', label: 'Haut- und Geschlechtskrankheiten', link: 'doctor2' },
  { value: 'doctor139', label: 'Herzchirurgie', link: 'doctor2' },
  { value: 'doctor140', label: 'Homöopathie', link: 'doctor2' },
  { value: 'doctor141', label: 'Humangenetik', link: 'doctor2' },
  { value: 'doctor141', label: 'Hygiene und Umweltmedizin', link: 'doctor2' },
  { value: 'doctor142', label: 'Hämostaseologie', link: 'doctor2' },
  { value: 'doctor143', label: 'Infektiologie', link: 'doctor2' },
  { value: 'doctor144', label: 'Innere Medizin', link: 'doctor2' },
  { value: 'doctor145', label: 'Innere Medizin und Angiologie', link: 'doctor2' },
  { value: 'doctor146', label: 'Innere Medizin und Endokrinologie und Diabetologie', link: 'doctor2' },
  { value: 'doctor147', label: 'Innere Medizin und Gastroenterologie', link: 'doctor2' },
  { value: 'doctor148', label: 'Innere Medizin und Hämatologie und Onkologie', link: 'doctor2' },
  { value: 'doctor149', label: 'Innere Medizin und Kardiologie', link: 'doctor2' },
  { value: 'doctor150', label: 'Innere Medizin und Nephrologie', link: 'doctor2' },
  { value: 'doctor151', label: 'Innere Medizin und Pneumologie', link: 'doctor2' },
  { value: 'doctor152', label: 'Innere Medizin und Rheumatologie', link: 'doctor2' },
  { value: 'doctor153', label: 'Intensivmedizin', link: 'doctor2' },
  { value: 'doctor154', label: 'Interventionelle Kardiologie', link: 'doctor2' },
  { value: 'doctor155', label: 'Kinder- und Jugendmedizin', link: 'doctor2' },
  { value: 'doctor156', label: 'Kinder- und Jugendpsychiatrie und -psychotherapie', link: 'doctor2' },
  { value: 'doctor157', label: 'Kinder-Endokrinologie und -Diabetologie', link: 'doctor2' },
  { value: 'doctor158', label: 'Kinder-Gastroenterologie', link: 'doctor2' },
  { value: 'doctor159', label: 'Kinder-Hämatologie und -Onkologie', link: 'doctor2' },
  { value: 'doctor160', label: 'Kinder-Kardiologie', link: 'doctor2' },
  { value: 'doctor161', label: 'Kinder-Nephrologie, Kinder-Orthopädie', link: 'doctor2' },
  { value: 'doctor162', label: 'Kinder-Pneumologie', link: 'doctor2' },
  { value: 'doctor163', label: 'Kinder-Rheumatologie', link: 'doctor2' },
  { value: 'doctor164', label: 'Kinderchirurgie', link: 'doctor2' },
  { value: 'doctor165', label: 'Kinderradiologie', link: 'doctor2' },
  { value: 'doctor166', label: 'Klinische Pharmakologie', link: 'doctor2' },
  { value: 'doctor167', label: 'Krankenhaushygiene', link: 'doctor2' },
  { value: 'doctor168', label: 'Labordiagnostik-fachgebunden', link: 'doctor2' },
  { value: 'doctor169', label: 'Labormedizin', link: 'doctor2' },
  { value: 'doctor170', label: 'Magnetresonanztomographie-fachgebunden', link: 'doctor2' },
  { value: 'doctor171', label: 'Manuelle Medizin / Chirotherapie', link: 'doctor2' },
  { value: 'doctor172', label: 'Medikamentöse Tumortherapie', link: 'doctor2' },
  { value: 'doctor173', label: 'Medizinische Informatik', link: 'doctor2' },
  { value: 'doctor174', label: 'Mikrobiologie', link: 'doctor2' },
  { value: 'doctor175', label: 'Mund-, Kiefer- und Gesichtschirurgie', link: 'doctor2' },
  { value: 'doctor176', label: 'Naturheilverfahren', link: 'doctor2' },
  { value: 'doctor177', label: 'Neonatologie', link: 'doctor2' },
  { value: 'doctor178', label: 'Neurochirurgie', link: 'doctor2' },
  { value: 'doctor179', label: 'Neurologie', link: 'doctor2' },
  { value: 'doctor180', label: 'Neuropädiatrie', link: 'doctor2' },
  { value: 'doctor181', label: 'Neuroradiologie', link: 'doctor2' },
  { value: 'doctor182', label: 'Notfallmedizin', link: 'doctor2' },
  { value: 'doctor183', label: 'Nuklearmedizin', link: 'doctor2' },
  { value: 'doctor184', label: 'Orthopädie und Unfallchirurgie', link: 'doctor2' },
  { value: 'doctor185', label: 'Orthopädische Rheumatologie', link: 'doctor2' },
  { value: 'doctor186', label: 'Palliativmedizin', link: 'doctor2' },
  { value: 'doctor187', label: 'Pathologie', link: 'doctor2' },
  { value: 'doctor188', label: 'Pharmakologie', link: 'doctor2' },
  { value: 'doctor189', label: 'Pharmakologie und Texikologie', link: 'doctor2' },
  { value: 'doctor190', label: 'Phlebologie', link: 'doctor2' },
  { value: 'doctor191', label: 'Physikalische Therapie und Balneologie', link: 'doctor2' },
  { value: 'doctor192', label: 'Physikalische und Rehabilitative Medizin', link: 'doctor2' },
  { value: 'doctor193', label: 'Physiologie', link: 'doctor2' },
  { value: 'doctor194', label: 'Plastische Operationen', link: 'doctor2' },
  { value: 'doctor195', label: 'Plastische und Ästhetische Chirurgie', link: 'doctor2' },
  { value: 'doctor196', label: 'Proktologie', link: 'doctor2' },
  { value: 'doctor197', label: 'Psychiatrie und Psychotherapie', link: 'doctor2' },
  { value: 'doctor198', label: 'Psychoanalyse', link: 'doctor2' },
  { value: 'doctor199', label: 'Psychosomatische Medizin und Psychotherapie', link: 'doctor2' },
  { value: 'doctor200', label: 'Psychotherapie-fachgebunden', link: 'doctor2' },
  { value: 'doctor201', label: 'Radiologie', link: 'doctor2' },
  { value: 'doctor202', label: 'Rechtsmedizin', link: 'doctor2' },
  { value: 'doctor203', label: 'Rhytmologie', link: 'doctor2' },
  { value: 'doctor204', label: 'Schlafmedizin', link: 'doctor2' },
  { value: 'doctor205', label: 'Sozialmedizin', link: 'doctor2' },
  { value: 'doctor206', label: 'Spezielle Geburtshilfe und Perinatalmedizin', link: 'doctor2' },
  { value: 'doctor207', label: 'Spezielle Orthopädische Chirurgie', link: 'doctor2' },
  { value: 'doctor208', label: 'Spezielle Schmerztherapie', link: 'doctor2' },
  { value: 'doctor209', label: 'Spezielle Unfallchirurgie', link: 'doctor2' },
  { value: 'doctor210', label: 'Sportmedizin', link: 'doctor2' },
  { value: 'doctor211', label: 'Sprach-, Stimm- und kindliche Hörstörungen', link: 'doctor2' },
  { value: 'doctor212', label: 'Strahlentherapie', link: 'doctor2' },
  { value: 'doctor213', label: 'Suchtmedizinische Grundversorgung', link: 'doctor2' },
  { value: 'doctor214', label: 'Thoraxchirurgie', link: 'doctor2' },
  { value: 'doctor215', label: 'Transfusionsmedizin', link: 'doctor2' },
  { value: 'doctor216', label: 'Tropenmedizin', link: 'doctor2' },
  { value: 'doctor217', label: 'Urologie', link: 'doctor2' },
  { value: 'doctor218', label: 'Viszeralchirurgie', link: 'doctor2' },
  { value: 'doctor219', label: 'Zahnmedizin', link: 'doctor2' },
  { value: 'doctor220', label: 'Ärztliches Qualitätsmanagement', link: 'doctor2' },
  { value: 'doctor221', label: 'Öffentliche Gesundheitswesen', link: 'doctor2' },
  { value: 'docto1', label: 'Akupunktur', link: 'doctor3' },
  { value: 'docto2', label: 'Allergologie', link: 'doctor3' },
  { value: 'docto3', label: 'Allgemeinchirurgie', link: 'doctor3' },
  { value: 'docto4', label: 'Allgemeinmedizin', link: 'doctor3' },
  { value: 'docto5', label: 'Anatomie', link: 'doctor3' },
  { value: 'docto6', label: 'Andrologie', link: 'doctor3' },
  { value: 'docto7', label: 'Anästhesiologie', link: 'doctor3' },
  { value: 'docto8', label: 'Arbeitsmedizin', link: 'doctor3' },
  { value: 'docto9', label: 'Augenheilkunde', link: 'doctor3' },
  { value: 'docto10', label: 'Biochemie', link: 'doctor3' },
  { value: 'docto11', label: 'Chirurgie', link: 'doctor3' },
  { value: 'docto12', label: 'Dermatohistologie', link: 'doctor3' },
  { value: 'docto13', label: 'Diabetologie', link: 'doctor3' },
  { value: 'docto14', label: 'Flugmedizin', link: 'doctor3' },
  { value: 'docto15', label: 'Forensische Psychiatrie', link: 'doctor3' },
  { value: 'docto16', label: 'Gefäßchirugie', link: 'doctor3' },
  { value: 'docto17', label: 'Geriatrie', link: 'doctor3' },
  { value: 'docto18', label: 'Gynäkologie und Geburtshilfe', link: 'doctor3' },
  { value: 'docto19', label: 'Gynäkologische Endokrinologie und Reproduktionsmed', link: 'doctor3' },
  { value: 'docto20', label: 'Gynäkologische Exfoliativ-Zytologie', link: 'doctor3' },
  { value: 'docto21', label: 'Gynäkologische Onkologie', link: 'doctor3' },
  { value: 'docto22', label: 'Hals-Nasen-Ohrenheilkunde (HNO)', link: 'doctor3' },
  { value: 'docto23', label: 'Handchirurgie', link: 'doctor3' },
  { value: 'docto24', label: 'Haut- und Geschlechtskrankheiten', link: 'doctor3' },
  { value: 'docto25', label: 'Herzchirurgie', link: 'doctor3' },
  { value: 'docto26', label: 'Homöopathie', link: 'doctor3' },
  { value: 'docto27', label: 'Humangenetik', link: 'doctor3' },
  { value: 'docto28', label: 'Hygiene und Umweltmedizin', link: 'doctor3' },
  { value: 'docto29', label: 'Hämostaseologie', link: 'doctor3' },
  { value: 'docto30', label: 'Infektiologie', link: 'doctor3' },
  { value: 'docto31', label: 'Innere Medizin', link: 'doctor3' },
  { value: 'docto32', label: 'Innere Medizin und Angiologie', link: 'doctor3' },
  { value: 'docto33', label: 'Innere Medizin und Endokrinologie und Diabetologie', link: 'doctor3' },
  { value: 'docto34', label: 'Innere Medizin und Gastroenterologie', link: 'doctor3' },
  { value: 'docto35', label: 'Innere Medizin und Hämatologie und Onkologie', link: 'doctor3' },
  { value: 'docto36', label: 'Innere Medizin und Kardiologie', link: 'doctor3' },
  { value: 'docto37', label: 'Innere Medizin und Nephrologie', link: 'doctor3' },
  { value: 'docto38', label: 'Innere Medizin und Pneumologie', link: 'doctor3' },
  { value: 'docto39', label: 'Innere Medizin und Rheumatologie', link: 'doctor3' },
  { value: 'docto40', label: 'Intensivmedizin', link: 'doctor3' },
  { value: 'docto41', label: 'Interventionelle Kardiologie', link: 'doctor3' },
  { value: 'docto42', label: 'Kinder- und Jugendmedizin', link: 'doctor3' },
  { value: 'docto43', label: 'Kinder- und Jugendpsychiatrie und -psychotherapie', link: 'doctor3' },
  { value: 'docto44', label: 'Kinder-Endokrinologie und -Diabetologie', link: 'doctor3' },
  { value: 'docto45', label: 'Kinder-Gastroenterologie', link: 'doctor3' },
  { value: 'docto46', label: 'Kinder-Hämatologie und -Onkologie', link: 'doctor3' },
  { value: 'docto47', label: 'Kinder-Kardiologie', link: 'doctor3' },
  { value: 'docto48', label: 'Kinder-Nephrologie, Kinder-Orthopädie', link: 'doctor3' },
  { value: 'docto49', label: 'Kinder-Pneumologie', link: 'doctor3' },
  { value: 'docto50', label: 'Kinder-Rheumatologie', link: 'doctor3' },
  { value: 'docto51', label: 'Kinderchirurgie', link: 'doctor3' },
  { value: 'docto52', label: 'Kinderradiologie', link: 'doctor3' },
  { value: 'docto53', label: 'Klinische Pharmakologie', link: 'doctor3' },
  { value: 'docto54', label: 'Krankenhaushygiene', link: 'doctor3' },
  { value: 'docto55', label: 'Labordiagnostik-fachgebunden', link: 'doctor3' },
  { value: 'docto56', label: 'Labormedizin', link: 'doctor3' },
  { value: 'docto57', label: 'Magnetresonanztomographie-fachgebunden', link: 'doctor3' },
  { value: 'docto58', label: 'Manuelle Medizin / Chirotherapie', link: 'doctor3' },
  { value: 'docto59', label: 'Medikamentöse Tumortherapie', link: 'doctor3' },
  { value: 'docto60', label: 'Medizinische Informatik', link: 'doctor3' },
  { value: 'docto61', label: 'Mikrobiologie', link: 'doctor3' },
  { value: 'docto62', label: 'Mund-, Kiefer- und Gesichtschirurgie', link: 'doctor3' },
  { value: 'docto63', label: 'Naturheilverfahren', link: 'doctor3' },
  { value: 'docto64', label: 'Neonatologie', link: 'doctor3' },
  { value: 'docto65', label: 'Neurochirurgie', link: 'doctor3' },
  { value: 'docto66', label: 'Neurologie', link: 'doctor3' },
  { value: 'docto67', label: 'Neuropädiatrie', link: 'doctor3' },
  { value: 'docto68', label: 'Neuroradiologie', link: 'doctor3' },
  { value: 'docto69', label: 'Notfallmedizin', link: 'doctor3' },
  { value: 'docto70', label: 'Nuklearmedizin', link: 'doctor3' },
  { value: 'docto71', label: 'Orthopädie und Unfallchirurgie', link: 'doctor3' },
  { value: 'docto72', label: 'Orthopädische Rheumatologie', link: 'doctor3' },
  { value: 'docto73', label: 'Palliativmedizin', link: 'doctor3' },
  { value: 'docto74', label: 'Pathologie', link: 'doctor3' },
  { value: 'docto75', label: 'Pharmakologie', link: 'doctor3' },
  { value: 'docto76', label: 'Pharmakologie und Texikologie', link: 'doctor3' },
  { value: 'docto77', label: 'Phlebologie', link: 'doctor3' },
  { value: 'docto78', label: 'Physikalische Therapie und Balneologie', link: 'doctor3' },
  { value: 'docto79', label: 'Physikalische und Rehabilitative Medizin', link: 'doctor3' },
  { value: 'docto80', label: 'Physiologie', link: 'doctor3' },
  { value: 'docto81', label: 'Plastische Operationen', link: 'doctor3' },
  { value: 'docto82', label: 'Plastische und Ästhetische Chirurgie', link: 'doctor' },
  { value: 'docto83', label: 'Proktologie', link: 'doctor3' },
  { value: 'docto84', label: 'Psychiatrie und Psychotherapie', link: 'doctor3' },
  { value: 'docto85', label: 'Psychoanalyse', link: 'doctor3' },
  { value: 'docto86', label: 'Psychosomatische Medizin und Psychotherapie', link: 'doctor3' },
  { value: 'docto87', label: 'Psychotherapie-fachgebunden', link: 'doctor3' },
  { value: 'docto88', label: 'Radiologie', link: 'doctor3' },
  { value: 'docto89', label: 'Rechtsmedizin', link: 'doctor3' },
  { value: 'docto90', label: 'Rhytmologie', link: 'doctor3' },
  { value: 'docto91', label: 'Schlafmedizin', link: 'doctor3' },
  { value: 'docto92', label: 'Sozialmedizin', link: 'doctor3' },
  { value: 'docto93', label: 'Spezielle Geburtshilfe und Perinatalmedizin', link: 'doctor3' },
  { value: 'docto94', label: 'Spezielle Orthopädische Chirurgie', link: 'doctor3' },
  { value: 'docto95', label: 'Spezielle Schmerztherapie', link: 'doctor3' },
  { value: 'docto96', label: 'Spezielle Unfallchirurgie', link: 'doctor3' },
  { value: 'docto97', label: 'Sportmedizin', link: 'doctor3' },
  { value: 'docto98', label: 'Sprach-, Stimm- und kindliche Hörstörungen', link: 'doctor3' },
  { value: 'docto99', label: 'Strahlentherapie', link: 'doctor3' },
  { value: 'docto100', label: 'Suchtmedizinische Grundversorgung', link: 'doctor3' },
  { value: 'docto101', label: 'Thoraxchirurgie', link: 'doctor3' },
  { value: 'docto102', label: 'Transfusionsmedizin', link: 'doctor3' },
  { value: 'docto103', label: 'Tropenmedizin', link: 'doctor3' },
  { value: 'docto104', label: 'Urologie', link: 'doctor3' },
  { value: 'docto105', label: 'Viszeralchirurgie', link: 'doctor3' },
  { value: 'docto106', label: 'Zahnmedizin', link: 'doctor3' },
  { value: 'docto107', label: 'Ärztliches Qualitätsmanagement', link: 'doctor3' },
  { value: 'docto108', label: 'Öffentliche Gesundheitswesen', link: 'doctor3' },
  { value: '1doctor', label: 'Akupunktur', link: 'doctor4' },
  { value: '2doctor', label: 'Allergologie', link: 'doctor4' },
  { value: '3doctor', label: 'Allgemeinchirurgie', link: 'doctor4' },
  { value: '4doctor', label: 'Allgemeinmedizin', link: 'doctor4' },
  { value: '5doctor', label: 'Anatomie', link: 'doctor4' },
  { value: '6doctor', label: 'Andrologie', link: 'doctor4' },
  { value: '7doctor', label: 'Anästhesiologie', link: 'doctor4' },
  { value: '8doctor', label: 'Arbeitsmedizin', link: 'doctor4' },
  { value: '9doctor', label: 'Augenheilkunde', link: 'doctor4' },
  { value: '10doctor', label: 'Biochemie', link: 'doctor4' },
  { value: '11doctor', label: 'Chirurgie', link: 'doctor4' },
  { value: '12doctor', label: 'Dermatohistologie', link: 'doctor4' },
  { value: '13doctor', label: 'Diabetologie', link: 'doctor4' },
  { value: '14doctor', label: 'Flugmedizin', link: 'doctor4' },
  { value: '15doctor', label: 'Forensische Psychiatrie', link: 'doctor4' },
  { value: '16doctor', label: 'Gefäßchirugie', link: 'doctor4' },
  { value: '17doctor', label: 'Geriatrie', link: 'doctor4' },
  { value: '18doctor', label: 'Gynäkologie und Geburtshilfe', link: 'doctor4' },
  { value: '19doctor', label: 'Gynäkologische Endokrinologie und Reproduktionsmed', link: 'doctor4' },
  { value: '20doctor', label: 'Gynäkologische Exfoliativ-Zytologie', link: 'doctor4' },
  { value: '21doctor', label: 'Gynäkologische Onkologie', link: 'doctor4' },
  { value: '22doctor', label: 'Hals-Nasen-Ohrenheilkunde (HNO)', link: 'doctor4' },
  { value: '23doctor', label: 'Handchirurgie', link: 'doctor4' },
  { value: '24doctor', label: 'Haut- und Geschlechtskrankheiten', link: 'doctor4' },
  { value: '25doctor', label: 'Herzchirurgie', link: 'doctor4' },
  { value: '26doctor', label: 'Homöopathie', link: 'doctor4' },
  { value: '27doctor', label: 'Humangenetik', link: 'doctor4' },
  { value: '28doctor', label: 'Hygiene und Umweltmedizin', link: 'doctor4' },
  { value: '29doctor', label: 'Hämostaseologie', link: 'doctor4' },
  { value: '30doctor', label: 'Infektiologie', link: 'doctor4' },
  { value: '31doctor', label: 'Innere Medizin', link: 'doctor4' },
  { value: '32doctor', label: 'Innere Medizin und Angiologie', link: 'doctor4' },
  { value: '33doctor', label: 'Innere Medizin und Endokrinologie und Diabetologie', link: 'doctor4' },
  { value: '34doctor', label: 'Innere Medizin und Gastroenterologie', link: 'doctor4' },
  { value: '35doctor', label: 'Innere Medizin und Hämatologie und Onkologie', link: 'doctor4' },
  { value: '36doctor', label: 'Innere Medizin und Kardiologie', link: 'doctor4' },
  { value: '37doctor', label: 'Innere Medizin und Nephrologie', link: 'doctor4' },
  { value: '38doctor', label: 'Innere Medizin und Pneumologie', link: 'doctor4' },
  { value: '39doctor', label: 'Innere Medizin und Rheumatologie', link: 'doctor4' },
  { value: '40doctor', label: 'Intensivmedizin', link: 'doctor4' },
  { value: '41doctor', label: 'Interventionelle Kardiologie', link: 'doctor4' },
  { value: '42doctor', label: 'Kinder- und Jugendmedizin', link: 'doctor4' },
  { value: '43doctor', label: 'Kinder- und Jugendpsychiatrie und -psychotherapie', link: 'doctor4' },
  { value: '44doctor', label: 'Kinder-Endokrinologie und -Diabetologie', link: 'doctor4' },
  { value: '45doctor', label: 'Kinder-Gastroenterologie', link: 'doctor4' },
  { value: '46doctor', label: 'Kinder-Hämatologie und -Onkologie', link: 'doctor4' },
  { value: '47doctor', label: 'Kinder-Kardiologie', link: 'doctor4' },
  { value: '48doctor', label: 'Kinder-Nephrologie, Kinder-Orthopädie', link: 'doctor4' },
  { value: '49doctor', label: 'Kinder-Pneumologie', link: 'doctor4' },
  { value: '50doctor', label: 'Kinder-Rheumatologie', link: 'doctor4' },
  { value: '51doctor', label: 'Kinderchirurgie', link: 'doctor4' },
  { value: '52doctor', label: 'Kinderradiologie', link: 'doctor4' },
  { value: '53doctor', label: 'Klinische Pharmakologie', link: 'doctor4' },
  { value: '54doctor', label: 'Krankenhaushygiene', link: 'doctor4' },
  { value: '55doctor', label: 'Labordiagnostik-fachgebunden', link: 'doctor4' },
  { value: '56doctor', label: 'Labormedizin', link: 'doctor4' },
  { value: '57doctor', label: 'Magnetresonanztomographie-fachgebunden', link: 'doctor4' },
  { value: '58doctor', label: 'Manuelle Medizin / Chirotherapie', link: 'doctor4' },
  { value: '59doctor', label: 'Medikamentöse Tumortherapie', link: 'doctor4' },
  { value: '60doctor', label: 'Medizinische Informatik', link: 'doctor4' },
  { value: '61doctor', label: 'Mikrobiologie', link: 'doctor4' },
  { value: '62doctor', label: 'Mund-, Kiefer- und Gesichtschirurgie', link: 'doctor4' },
  { value: '63doctor', label: 'Naturheilverfahren', link: 'doctor4' },
  { value: '64doctor', label: 'Neonatologie', link: 'doctor4' },
  { value: '65doctor', label: 'Neurochirurgie', link: 'doctor4' },
  { value: '66doctor', label: 'Neurologie', link: 'doctor4' },
  { value: '67doctor', label: 'Neuropädiatrie', link: 'doctor4' },
  { value: '68doctor', label: 'Neuroradiologie', link: 'doctor4' },
  { value: '69doctor', label: 'Notfallmedizin', link: 'doctor4' },
  { value: '70doctor', label: 'Nuklearmedizin', link: 'doctor4' },
  { value: '71doctor', label: 'Orthopädie und Unfallchirurgie', link: 'doctor4' },
  { value: '72doctor', label: 'Orthopädische Rheumatologie', link: 'doctor4' },
  { value: '73doctor', label: 'Palliativmedizin', link: 'doctor4' },
  { value: '74doctor', label: 'Pathologie', link: 'doctor4' },
  { value: '75doctor', label: 'Pharmakologie', link: 'doctor4' },
  { value: '76doctor', label: 'Pharmakologie und Texikologie', link: 'doctor4' },
  { value: '77doctor', label: 'Phlebologie', link: 'doctor4' },
  { value: '78doctor', label: 'Physikalische Therapie und Balneologie', link: 'doctor4' },
  { value: '79doctor', label: 'Physikalische und Rehabilitative Medizin', link: 'doctor4' },
  { value: '80doctor', label: 'Physiologie', link: 'doctor4' },
  { value: '81doctor', label: 'Plastische Operationen', link: 'doctor4' },
  { value: '82doctor', label: 'Plastische und Ästhetische Chirurgie', link: 'doctor4' },
  { value: '83doctor', label: 'Proktologie', link: 'doctor4' },
  { value: '84doctor', label: 'Psychiatrie und Psychotherapie', link: 'doctor4' },
  { value: '85doctor', label: 'Psychoanalyse', link: 'doctor4' },
  { value: '86doctor', label: 'Psychosomatische Medizin und Psychotherapie', link: 'doctor4' },
  { value: '87doctor', label: 'Psychotherapie-fachgebunden', link: 'doctor4' },
  { value: '88doctor', label: 'Radiologie', link: 'doctor4' },
  { value: '89doctor', label: 'Rechtsmedizin', link: 'doctor4' },
  { value: '90doctor', label: 'Rhytmologie', link: 'doctor4' },
  { value: '91doctor', label: 'Schlafmedizin', link: 'doctor4' },
  { value: '92doctor', label: 'Sozialmedizin', link: 'doctor4' },
  { value: '93doctor', label: 'Spezielle Geburtshilfe und Perinatalmedizin', link: 'doctor4' },
  { value: '94doctor', label: 'Spezielle Orthopädische Chirurgie', link: 'doctor4' },
  { value: '95doctor', label: 'Spezielle Schmerztherapie', link: 'doctor4' },
  { value: '96doctor', label: 'Spezielle Unfallchirurgie', link: 'doctor4' },
  { value: '97doctor', label: 'Sportmedizin', link: 'doctor4' },
  { value: '98doctor', label: 'Sprach-, Stimm- und kindliche Hörstörungen', link: 'doctor4' },
  { value: '99doctor', label: 'Strahlentherapie', link: 'doctor4' },
  { value: '100doctor', label: 'Suchtmedizinische Grundversorgung', link: 'doctor4' },
  { value: '101doctor', label: 'Thoraxchirurgie', link: 'doctor4' },
  { value: '102doctor', label: 'Transfusionsmedizin', link: 'doctor4' },
  { value: '103doctor', label: 'Tropenmedizin', link: 'doctor4' },
  { value: '104doctor', label: 'Urologie', link: 'doctor4' },
  { value: '105doctor', label: 'Viszeralchirurgie', link: 'doctor4' },
  { value: '106doctor', label: 'Zahnmedizin', link: 'doctor4' },
  { value: '107octor', label: 'Ärztliches Qualitätsmanagement', link: 'doctor4' },
  { value: '108doctor', label: 'Öffentliche Gesundheitswesen', link: 'doctor4' },
  { value: '109doctor', label: 'Akupunktur', link: 'doctor5' },
  { value: '110doctor', label: 'Allergologie', link: 'doctor5' },
  { value: '111doctor', label: 'Allgemeinchirurgie', link: 'doctor5' },
  { value: '112doctor', label: 'Allgemeinmedizin', link: 'doctor5' },
  { value: '113doctor', label: 'Anatomie', link: 'doctor5' },
  { value: '114doctor', label: 'Andrologie', link: 'doctor5' },
  { value: '115doctor', label: 'Anästhesiologie', link: 'doctor5' },
  { value: '116doctor', label: 'Arbeitsmedizin', link: 'doctor5' },
  { value: '117doctor', label: 'Augenheilkunde', link: 'doctor5' },
  { value: '118doctor', label: 'Biochemie', link: 'doctor5' },
  { value: '119doctor', label: 'Chirurgie', link: 'doctor5' },
  { value: '120doctor', label: 'Dermatohistologie', link: 'doctor5' },
  { value: '121doctor', label: 'Diabetologie', link: 'doctor5' },
  { value: '122doctor', label: 'Flugmedizin', link: 'doctor5' },
  { value: '123doctor', label: 'Forensische Psychiatrie', link: 'doctor5' },
  { value: '124doctor', label: 'Gefäßchirugie', link: 'doctor5' },
  { value: '125doctor', label: 'Geriatrie', link: 'doctor5' },
  { value: '126doctor', label: 'Gynäkologie und Geburtshilfe', link: 'doctor5' },
  { value: '127doctor', label: 'Gynäkologische Endokrinologie und Reproduktionsmed', link: 'doctor5' },
  { value: '128doctor', label: 'Gynäkologische Exfoliativ-Zytologie', link: 'doctor5' },
  { value: '129doctor', label: 'Gynäkologische Onkologie', link: 'doctor5' },
  { value: '130doctor', label: 'Hals-Nasen-Ohrenheilkunde (HNO)', link: 'doctor5' },
  { value: '131doctor', label: 'Handchirurgie', link: 'doctor5' },
  { value: '132doctor', label: 'Haut- und Geschlechtskrankheiten', link: 'doctor5' },
  { value: '133doctor', label: 'Herzchirurgie', link: 'doctor5' },
  { value: '134doctor', label: 'Homöopathie', link: 'doctor5' },
  { value: '135doctor', label: 'Humangenetik', link: 'doctor5' },
  { value: '136doctor', label: 'Hygiene und Umweltmedizin', link: 'doctor5' },
  { value: '137doctor', label: 'Hämostaseologie', link: 'doctor5' },
  { value: '138doctor', label: 'Infektiologie', link: 'doctor5' },
  { value: '139doctor', label: 'Innere Medizin', link: 'doctor5' },
  { value: '140doctor', label: 'Innere Medizin und Angiologie', link: 'doctor5' },
  { value: '141doctor', label: 'Innere Medizin und Endokrinologie und Diabetologie', link: 'doctor5' },
  { value: '142doctor', label: 'Innere Medizin und Gastroenterologie', link: 'doctor5' },
  { value: '143doctor', label: 'Innere Medizin und Hämatologie und Onkologie', link: 'doctor5' },
  { value: '144doctor', label: 'Innere Medizin und Kardiologie', link: 'doctor5' },
  { value: '145doctor', label: 'Innere Medizin und Nephrologie', link: 'doctor5' },
  { value: '146doctor', label: 'Innere Medizin und Pneumologie', link: 'doctor5' },
  { value: '147doctor', label: 'Innere Medizin und Rheumatologie', link: 'doctor5' },
  { value: '148doctor', label: 'Intensivmedizin', link: 'doctor5' },
  { value: '149doctor', label: 'Interventionelle Kardiologie', link: 'doctor5' },
  { value: '150doctor', label: 'Kinder- und Jugendmedizin', link: 'doctor5' },
  { value: '151doctor', label: 'Kinder- und Jugendpsychiatrie und -psychotherapie', link: 'doctor5' },
  { value: '152doctor', label: 'Kinder-Endokrinologie und -Diabetologie', link: 'doctor5' },
  { value: '153doctor', label: 'Kinder-Gastroenterologie', link: 'doctor5' },
  { value: '154doctor', label: 'Kinder-Hämatologie und -Onkologie', link: 'doctor5' },
  { value: '155doctor', label: 'Kinder-Kardiologie', link: 'doctor5' },
  { value: '156doctor', label: 'Kinder-Nephrologie, Kinder-Orthopädie', link: 'doctor5' },
  { value: '157doctor', label: 'Kinder-Pneumologie', link: 'doctor5' },
  { value: '158doctor', label: 'Kinder-Rheumatologie', link: 'doctor5' },
  { value: '158doctor', label: 'Kinderchirurgie', link: 'doctor5' },
  { value: '160doctor', label: 'Kinderradiologie', link: 'doctor5' },
  { value: '161doctor', label: 'Klinische Pharmakologie', link: 'doctor5' },
  { value: '162doctor', label: 'Krankenhaushygiene', link: 'doctor5' },
  { value: '163doctor', label: 'Labordiagnostik-fachgebunden', link: 'doctor5' },
  { value: '164doctor', label: 'Labormedizin', link: 'doctor5' },
  { value: '165doctor', label: 'Magnetresonanztomographie-fachgebunden', link: 'doctor5' },
  { value: '166doctor', label: 'Manuelle Medizin / Chirotherapie', link: 'doctor5' },
  { value: '167doctor', label: 'Medikamentöse Tumortherapie', link: 'doctor5' },
  { value: '168doctor', label: 'Medizinische Informatik', link: 'doctor5' },
  { value: '169doctor', label: 'Mikrobiologie', link: 'doctor5' },
  { value: '170doctor', label: 'Mund-, Kiefer- und Gesichtschirurgie', link: 'doctor5' },
  { value: '171doctor', label: 'Naturheilverfahren', link: 'doctor5' },
  { value: '172doctor', label: 'Neonatologie', link: 'doctor5' },
  { value: '173doctor', label: 'Neurochirurgie', link: 'doctor5' },
  { value: '174doctor', label: 'Neurologie', link: 'doctor5' },
  { value: '175doctor', label: 'Neuropädiatrie', link: 'doctor5' },
  { value: '176doctor', label: 'Neuroradiologie', link: 'docto5' },
  { value: '177doctor', label: 'Notfallmedizin', link: 'doctor5' },
  { value: '178doctor', label: 'Nuklearmedizin', link: 'doctor5' },
  { value: '179doctor', label: 'Orthopädie und Unfallchirurgie', link: 'doctor5' },
  { value: '180doctor', label: 'Orthopädische Rheumatologie', link: 'doctor5' },
  { value: '181doctor', label: 'Palliativmedizin', link: 'doctor5' },
  { value: '182doctor', label: 'Pathologie', link: 'doctor5' },
  { value: '183doctor', label: 'Pharmakologie', link: 'doctor5' },
  { value: '184doctor', label: 'Pharmakologie und Texikologie', link: 'doctor5' },
  { value: '185doctor', label: 'Phlebologie', link: 'doctor5' },
  { value: '186doctor', label: 'Physikalische Therapie und Balneologie', link: 'doctor5' },
  { value: '187doctor', label: 'Physikalische und Rehabilitative Medizin', link: 'doctor5' },
  { value: '188doctor', label: 'Physiologie', link: 'doctor5' },
  { value: '189doctor', label: 'Plastische Operationen', link: 'doctor5' },
  { value: '190doctor', label: 'Plastische und Ästhetische Chirurgie', link: 'doctor5' },
  { value: '191doctor', label: 'Proktologie', link: 'doctor5' },
  { value: '192doctor', label: 'Psychiatrie und Psychotherapie', link: 'doctor5' },
  { value: '193doctor', label: 'Psychoanalyse', link: 'doctor5' },
  { value: '194doctor', label: 'Psychosomatische Medizin und Psychotherapie', link: 'doctor5' },
  { value: '195doctor', label: 'Psychotherapie-fachgebunden', link: 'doctor5' },
  { value: '196doctor', label: 'Radiologie', link: 'doctor5' },
  { value: '197doctor', label: 'Rechtsmedizin', link: 'doctor5' },
  { value: '198doctor', label: 'Rhytmologie', link: 'doctor5' },
  { value: '199doctor', label: 'Schlafmedizin', link: 'doctor5' },
  { value: '200doctor', label: 'Sozialmedizin', link: 'doctor5' },
  { value: '201doctor', label: 'Spezielle Geburtshilfe und Perinatalmedizin', link: 'doctor5' },
  { value: '202doctor', label: 'Spezielle Orthopädische Chirurgie', link: 'doctor5' },
  { value: '203doctor', label: 'Spezielle Schmerztherapie', link: 'doctor5' },
  { value: '204doctor', label: 'Spezielle Unfallchirurgie', link: 'doctor5' },
  { value: '205doctor', label: 'Sportmedizin', link: 'doctor5' },
  { value: '206doctor', label: 'Sprach-, Stimm- und kindliche Hörstörungen', link: 'doctor5' },
  { value: '207doctor', label: 'Strahlentherapie', link: 'doctor5' },
  { value: '208doctor', label: 'Suchtmedizinische Grundversorgung', link: 'doctor5' },
  { value: '209doctor', label: 'Thoraxchirurgie', link: 'doctor5' },
  { value: '210doctor', label: 'Transfusionsmedizin', link: 'doctor5' },
  { value: '211doctor', label: 'Tropenmedizin', link: 'doctor5' },
  { value: '212doctor', label: 'Urologie', link: 'doctor5' },
  { value: '213doctor', label: 'Viszeralchirurgie', link: 'doctor5' },
  { value: '214doctor', label: 'Zahnmedizin', link: 'doctor5' },
  { value: '215doctor', label: 'Ärztliches Qualitätsmanagement', link: 'doctor5' },
  { value: '216doctor', label: 'Öffentliche Gesundheitswesen', link: 'doctor5' }
]
const option = [{
  value: "1",
  label: "Registrierungsdatum",
  fontSize: '166px'
},
{
  value: "2",
  label: "Profile Aktualisierungsdatum",
  fontSize: '166px'
},

]
class FollowingCandidate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      horizontalTabs: "HelloMatch",
      jobTypeSingleSelect: null,
      qualificationSingleSelect: null,
      specializationMultipleSelect: null,
      workingTimeMultipleSelect: null,
      workingTernureMultipleSelect: null,
      qualificationMultiSelect: null,
      specializationMultiSelect: null,
      countrySingleSelect: null,
      citySingleSelect: null,
      radiusSingleSelect: null,
      decisionSingleSelect: null,
      optSelect: {},
      opt2Select: {},
      opt3Select: null,
      optSelect4: {},
      opt5Select: {},
      opt6Select: null,
      doctorSelect: null,
      functionsSelect: null,
      arbPSelect: null,
      funcSelect: {},
      teraSelect: null,
      eiSelect: null,
      statesSelect: null,
      statesSelect1: null,
      distanceSelect: null,
      openedCollapses: ["collapseOne", "collapse1"],
      visible: false,
      visible1: false,
      cand: [],
      filter: [],
      notescount: [],
      l_name: '',
      offset: 0,
      perPage: 20,
      currentPage: 0,
      offset1: 0,
      perPage1: 20,
      currentPage1: 0,
      state1: true,
      state2: true,
      show3: false,
      //modal: false,
      job_value: null
    };
    this.toggle = this.toggle.bind(this);
  }
  toggle() {
    this.setState({
      modal: !this.state.modal
    });

  }
  changeHandler10 = (job_value) => {
    this.setState(pv => {
      return { ...pv, job_value: job_value };
    }
    );
    console.log(job_value)
    axios.post("/api2/candidbydate", { ...this.state, job_value })
      .then(response => {
        console.log(response)
        const filter = response.data;
        this.setState({ filter });
        this.setState({ state1: false })
        localStorage.setItem('filter', JSON.stringify(this.state.filter));
        console.log(filter);
        this.makePostData1(this.state.filter);
      })
      .catch(error => {
        console.log(error.response.data);
        const searcherr = error.response.data.msg;
        this.setState({ searcherr })
        this.setState({ state2: false })
      })
    /////////////////////////////////////////////
    axios.get('/api2/getnotes1')
      .then(response => {
        console.log(response)
        const data1 = response.data;
        this.setState({ data1 });
        console.log(data1);
      })
      .catch(error => {
        console.log(error.response);
      })
  }
  // with this function we create an array with the opened collapses
  // it is like a toggle function for all collapses from this page
  collapsesToggle = collapse => {
    let openedCollapses = this.state.openedCollapses;
    if (openedCollapses.includes(collapse)) {
      this.setState({
        openedCollapses: openedCollapses.filter(item => item !== collapse)
      });
    } else {
      openedCollapses.push(collapse);
      this.setState({
        openedCollapses: openedCollapses
      });
    }
  };
  changeHandler2 = optSelect => {
    this.setState(pv => {
      return { ...pv, optSelect: optSelect };
    }, () => {
      if (this.state.optSelect.label === "Arzt" ||
        this.state.optSelect.label === "Altenpflege" ||
        this.state.optSelect.label === "Krankenpflege" ||
        this.state.optSelect.label === "Therapeut" ||
        this.state.optSelect.label === "Medizintechnik" ||
        this.state.optSelect.label === "Rettungsdienst" ||
        this.state.optSelect.label === "Praxispersonal" ||
        this.state.optSelect.label === "Pharmazie") {
        let nextVal = opt2.filter((o) => o.link === this.state.optSelect.value)[0];
        this.changeHandler1(nextVal);
        this.setState(pv => ({ ...pv, visible: true }));
      } else {
        this.setState({ visible: false });
      }
    });

  }

  changeHandler1 = optSelect => {
    this.setState(() => ({ opt2Select: optSelect }), () => {
      if (this.state.opt2Select.label === "Assistenzarzt" ||
        this.state.opt2Select.label === "Facharzt" ||
        this.state.opt2Select.label === "Oberarzt" ||
        this.state.opt2Select.label === "Ltd, Oberarzt" ||
        this.state.opt2Select.label === "Chefarzt" ||
        this.state.opt2Select.label === "Anästhesieschwester" ||
        this.state.opt2Select.label === "Anästhesietechnische Assistentin (ATA)" ||
        this.state.opt2Select.label === "Fachkrankenschwester" ||
        this.state.opt2Select.label === "Gesundheitspflegehelfer/Krankenpflegehelfer" ||
        this.state.opt2Select.label === "Gesundheitspfleger/Krankenpfleger" ||
        this.state.opt2Select.label === "Hebamme" ||
        this.state.opt2Select.label === "Heilerziehungspfleger" ||
        this.state.opt2Select.label === "Kinderkrankenschwester" ||
        this.state.opt2Select.label === "OP Schwester" ||
        this.state.opt2Select.label === "Operationstechnische Assistentin (OTA)" ||
        this.state.opt2Select.label === "Pflegeassistent" ||
        this.state.opt2Select.label === "Pflegedienstleitung" ||
        this.state.opt2Select.label === "Stationsleitung" ||
        this.state.opt2Select.label === "Study Nurse") {
        let nextVal1 = opt3.filter((p) => p.link === this.state.opt2Select.value)[0];
        this.changeHandler3(nextVal1);
        this.setState({ visible1: true })
        console.log("if works", this.state);
      } else {
        this.setState({ visible1: false });
        console.log("else works", this.state);
      }

    })
  }

  changeHandler3 = opt2Select => {
    this.setState(pv => {
      return { ...pv, opt3Select: opt2Select };
    });
  }

  changeHandler4 = optSelect4 => {
    this.setState(pvr => {
      return { ...pvr, optSelect4: optSelect4 };
    }, () => {
      if (this.state.optSelect4.label === "Arzt" ||
        this.state.optSelect4.label === "Altenpflege" ||
        this.state.optSelect4.label === "Krankenpflege" ||
        this.state.optSelect4.label === "Therapeut" ||
        this.state.optSelect4.label === "Medizintechnik" ||
        this.state.optSelect4.label === "Rettungsdienst" ||
        this.state.optSelect4.label === "Praxispersonal" ||
        this.state.optSelect4.label === "Pharmazie") {
        let nextVal = opt2.filter((o) => o.link === this.state.optSelect4.value)[0];
        this.changeHandler5(nextVal);
        this.setState(pvr => ({ ...pvr, visible2: true }));
      } else {
        this.setState({ visible2: false });
      }
    });

  }

  changeHandler5 = optSelect4 => {
    this.setState(() => ({ opt5Select: optSelect4 }), () => {
      if (this.state.opt5Select.label === "Assistenzarzt" ||
        this.state.opt5Select.label === "Facharzt" ||
        this.state.opt5Select.label === "Oberarzt" ||
        this.state.opt5Select.label === "Ltd, Oberarzt" ||
        this.state.opt5Select.label === "Chefarzt" ||
        this.state.opt5Select.label === "Anästhesieschwester" ||
        this.state.opt5Select.label === "Anästhesietechnische Assistentin (ATA)" ||
        this.state.opt5Select.label === "Fachkrankenschwester" ||
        this.state.opt5Select.label === "Gesundheitspflegehelfer/Krankenpflegehelfer" ||
        this.state.opt5Select.label === "Gesundheitspfleger/Krankenpfleger" ||
        this.state.opt5Select.label === "Hebamme" ||
        this.state.opt5Select.label === "Heilerziehungspfleger" ||
        this.state.opt5Select.label === "Kinderkrankenschwester" ||
        this.state.opt5Select.label === "OP Schwester" ||
        this.state.opt5Select.label === "Operationstechnische Assistentin (OTA)" ||
        this.state.opt5Select.label === "Pflegeassistent" ||
        this.state.opt5Select.label === "Pflegedienstleitung" ||
        this.state.opt5Select.label === "Stationsleitung" ||
        this.state.opt5Select.label === "Study Nurse") {
        let nextVal1 = opt3.filter((p) => p.link === this.state.opt5Select.value)[0];
        this.changeHandler6(nextVal1);
        this.setState({ visible3: true })
        console.log("if works", this.state);
      } else {
        this.setState({ visible3: false });
        console.log("else works", this.state);
      }

    })
  }

  changeHandler6 = opt5Select => {
    this.setState(pv => {
      return { ...pv, opt6Select: opt5Select };
    });
  }
  componentDidMount() {
    const roles = localStorage.getItem('roles')
    this.setState({ roles })
    console.log(roles);
    axios
      .get('/api2/getnotes',)
      .then(response => {
        console.log(response);
        const notescount = response.data;
        this.setState({ notescount })
        console.log(notescount);
      })
      .catch(error => {
        console.log(error.response);
      })
    axios
      .post('/api2/allcandidate', this.state)
      .then(response => {
        console.log(response)
        const candidateList = response.data.responsePayload.results;
       
        const jobsData = candidateList.reduce((acc, current) => {
          const x = acc.find(item => item.applicant_id === current.applicant_id);
          if (!x) {
            return acc.concat([current]);
          } else {
            return acc;
          }
        }, []);
        this.setState({ jobsData })
       
        const currentPage = response.data.responsePayload.pagination.current;
        this.setState({ currentPage })
        const pageCount = response.data.responsePayload.pagination.TotalPages;
        this.setState({ pageCount })
        // const slice = cand.slice(this.state.offset, this.state.offset + this.state.perPage)
        const postData = jobsData.map(item1 => <React.Fragment>

          <Card style={{ backgroundColor: '#f5f5f5' }} >
            <CardBody><Row>
              <Col md='2'>
                {item1.picture === null || item1.picture === " " ? null : <img style={{ width: '100px', height: '100px' }}
                  alt="..."
                  className="avatar border-gray"
                  src={`https://hello-doctor.de/uploads/${item1.picture}`}


                />}
              </Col>

              <Col md='5'><h7 className="text-left"   >
                <Link to={`/admin/detail-kandidate/${item1.applicant_id}`}>
                 {item1.title === 'Kein' ? null : item1.title} {item1.f_name} {item1.l_name}
                </Link>
                <br></br>
                {item1.telephone === null ? null : <><h7 className="text-left" style={{ fontSize: '13px' }}><i class="fab fa-whatsapp"></i> &nbsp;<a href={`https://wa.me/${item1.telephone}`} target="_blank" rel="noopener noreferrer">{item1.telephone}</a></h7><br></br></>}
                <h7 className="text-left" style={{ fontSize: '13px' }}><i class="fas fa-envelope-open-text"></i>&nbsp;{item1.username}</h7><br></br>
                <i class="fas fa-map-marker-alt"></i>&nbsp;
                <h7 className="text-left" style={{ fontSize: '13px' }}>{item1.haus_no}, {item1.post_code} {item1.applicant_city}, &nbsp;{item1.country}</h7>
                <br></br>
                {this.state.notescount.map(item => {
                  return (
                    <>
                      {item1.applicant_id === item.app_id ?
                        <span>
                          <Badge className="mr-1" color="success" pill>
                            Notizen: {item.cnt}
                          </Badge>
                        </span>
                        :
                        null
                      }
                      {/* <Badge className="mr-1" color="success" pill>
                        Notizen: {item.cnt}
                          </Badge> */}
                    </>
                  )
                })}
              </h7>
              </Col>
              <Col md='3'>
                <h7 className="text-left" style={{ fontSize: '11px' }}><b>Registrierung:</b> {item1.created_date}</h7><br></br>
                <h7 className="text-left" style={{ fontSize: '11px' }}><b>Profile aktualisiert:</b> {item1.last_update}</h7><br></br>
                <h7 className="text-left" style={{ fontSize: '11px' }}><b>Eingeloggt zuletzt:</b> {item1.last_login_date}</h7><br></br>
                {item1.status === 'aktiv' ? <h7 className="text-left" style={{ fontSize: '11px', color: '#28a745' }}><b>Konto Status:</b> {item1.status} </h7> :
                  item1.status === "Kandidaten gelöscht" ? <><h7 className="text-left" style={{ fontSize: '11px', color: '#ff0800' }}><p>Konto wurde am {item1.account_del} vom{item1.status}</p>  </h7></> :
                    <h7 className="text-left" style={{ fontSize: '11px', color: '#ff0800' }}><b>Konto Status:</b> {item1.status} </h7>
                }
              </Col>
              <Col md='2' className="text-right" style={{ marginTop: '-30px', marginBottom: '-20px' }}>
                <br></br>
                <Button
                  className="btn-icon"
                  color="primary"
                  id={"tooltip366246639" + item1.applicant_id}
                  size="sm"
                  type="button"
                  onClick={this.acceptHandle.bind(this, item1.user_id)}
                >
                  <i class="fa fa-unlock" aria-hidden="true"></i>
                </Button>{" "}
                <UncontrolledTooltip
                  delay={0}
                  target={"tooltip366246639" + item1.applicant_id}
                >
                  Aktivieren
                </UncontrolledTooltip>

                <Button
                  className="btn-icon"
                  color="danger"
                  id={"tooltip366246640" + item1.applicant_id}
                  size="sm"
                  type="button"
                  onClick={this.rejectHandle.bind(this, item1.user_id)}
                >
                  <i className="fa fa-toggle-off" />
                </Button>{" "}
                <UncontrolledTooltip
                  delay={0}
                  target={"tooltip366246640" + item1.applicant_id}
                >
                  Deaktivieren
                </UncontrolledTooltip>
                <br></br>

                <Button
                  style={{ marginTop: '-5px' }}
                  className="btn-icon"
                  color="primary"
                  id={"tooltip3662466421112" + item1.applicant_id}
                  size="sm"
                  type="button"
                  onClick={this.mailHandle.bind(this, item1.user_id)}

                >
                  <i className="fa fa-envelope" />
                </Button>{" "}
                <UncontrolledTooltip
                  delay={0}
                  target={"tooltip3662466421112" + item1.applicant_id}
                >
                  Profile Update Email
                </UncontrolledTooltip>
                <Button
                  style={{ marginTop: '-5px' }}
                  className="btn-icon"
                  color="primary"
                  id={"tooltip3662466421113" + item1.applicant_id}
                  size="sm"
                  type="button"
                  onClick={this.mailHandleFeed.bind(this, item1.user_id)}

                >
                  <i className="fa fa-paper-plane" />
                </Button>{" "}
                <UncontrolledTooltip
                  delay={0}
                  target={"tooltip3662466421113" + item1.applicant_id}
                >
                  Feedback Email
                </UncontrolledTooltip>
                {this.state.roles !== 'admin' ?
                  null
                  :
                  <>    <Button
                    style={{ marginTop: '-5px' }}
                    className="btn-icon"
                    color="danger"
                    id={"tooltip366246642" + item1.applicant_id}
                    size="sm"
                    type="button"
                    //onClick={this.toggle}
                    //onClick={this.deleteHandle.bind(this, item1.user_id)}
                    onClick={() => this.setState({ user_id: item1.user_id }, this.toggle)}
                  >

                    <i className="fa fa-trash" />
                  </Button>{" "}
                    <UncontrolledTooltip
                      delay={0}
                      target={"tooltip366246642" + item1.applicant_id}
                    >
                      Löschen
                    </UncontrolledTooltip>
                    <Link to={`/admin/messages-list/${item1.user_id}`}>
                      <Button
                        style={{ marginTop: '-5px' }}
                        className="btn-icon"
                        color="primary"
                        id={"tooltip366246644" + item1.applicant_id}
                        size="sm"
                        type="button"
                      //onClick={this.toggle}
                      //onClick={this.deleteHandle.bind(this, item1.user_id)}
                      //onClick={() => this.setState({ user_id: item1.user_id }, this.toggle)}
                      >

                        <i className="fa fa-comments" />
                      </Button>{" "}
                      <UncontrolledTooltip
                        delay={0}
                        target={"tooltip366246644" + item1.applicant_id}
                      >
                        Chat
                      </UncontrolledTooltip>

                    </Link>
                  </>
                }

              </Col>


            </Row>

            </CardBody>

          </Card>

        </React.Fragment>)
        localStorage.setItem('cand', JSON.stringify(this.state.jobsData));
        //console.log(cand);
        this.setState({
          //pageCount: Math.ceil(cand.length / this.state.perPage),

          postData
        })
        // console.log(cand);   
      })
      .catch(error => {
        console.log(error);
      })
    //////////////////////////////////////////////////////

  }

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState({
      currentPage: selectedPage,
      offset: offset
    }, () => {
      this.componentDidMount()
    });

  };

  changeHandler = e => {
    this.setState({ [e.target.name]: e.target.value })
  }
  submitHandler = e => {
    e.preventDefault();
    var mainerr;
    var opt31err;
    var opt32err;
    var oneerr;
    //const { selectedFile, doc_title} = this.state
    if (this.state.optSelect.label === undefined && this.state.opt2Select.label === undefined && this.state.opt3Select === null && this.state.l_name === '') {
      console.log("empty")
      mainerr = 'Alle Felder sind leer'
      this.setState({ mainerr })
    } else {
      this.setState({ mainerr: undefined })
      console.log("not empty")
    }
    if (this.state.optSelect.label !== undefined && this.state.opt2Select.label !== undefined) {
      // if(this.state.optSelect.label === "Arzt" || this.state.optSelect.label === "Krankenpflege"){
      if (this.state.opt2Select.label !== "Select" && this.state.opt3Select === null) {
        console.log("opt3 is empty")
        opt31err = 'Bitte auswählen'
        this.setState({ opt31err })
      }
      else {
        this.setState({ opt31err: undefined })
      }
      // }else{
      //   this.setState({opt32err:undefined})
      //   this.setState({opt31err:undefined})
      // }
      if (this.state.opt3Select !== null) {
        if (this.state.opt3Select.length > 3) {
          opt32err = 'max 3 erlaubt'
          this.setState({ opt32err })
        } else {
          this.setState({ opt32err: undefined })
        }
      } else {
        this.setState({ opt32err: undefined })
      }
    } else {
      this.setState({ opt32err: undefined })
      this.setState({ opt31err: undefined })
    }
    if (this.state.l_name !== '' && this.state.optSelect.label !== undefined) {
      oneerr = 'Bitte verwenden Sie eine Suchoption';
      this.setState({ oneerr })
    } else {
      this.setState({ oneerr: undefined })
    }
    console.log(this.state)
    if (mainerr === undefined && opt31err === undefined && opt32err === undefined && oneerr === undefined) {
      console.log("everything ok")
      axios.post("/api2/candidatesearch1", this.state)
        .then(response => {
          console.log(response)
          const filter = response.data;
          this.setState({ filter });
          this.setState({ state1: false })
          localStorage.setItem('filter', JSON.stringify(this.state.filter));
          console.log(filter);
          this.makePostData1(this.state.filter);
        })
        .catch(error => {
          console.log(error.response.data);
          const searcherr = error.response.data.msg;
          this.setState({ searcherr })
          this.setState({ state2: false, state1: false })
        })
      //////////////////////////////////
      axios
        .get('/api2/getnotes',)
        .then(response => {
          console.log(response);
          const notescount = response.data;
          this.setState({ notescount })
          console.log(notescount);
          // const msg = "Sie lehnen diese Anfrage ab";
          //   this.setState({msg})
          // window.location.reload();
        })
        .catch(error => {
          console.log(error.response);
        })
    }
  }

  acceptHandle(userid, e) {
    e.preventDefault();
    console.log(this.state.job_value)
    if (this.state.job_value === null) {
      axios
        .post('/api2/acceptcandidate', { ...this.state, userid })
        .then(response => {
          console.log(response)
          const msg = "Sie akzeptieren die Anfrage";
          this.setState({ msg })
          this.intervalID = setTimeout(this.componentDidMount.bind(this), 100);
          //window.location.reload(true);
        })
        .catch(error => {
          console.log(error.response);
        })
    } else {
      axios
        .post('/api2/acceptcandidate1', { ...this.state, userid })
        .then(response => {
          console.log(response)
          const filter = response.data;
          this.setState({ filter });
          this.setState({ state1: false })
          localStorage.setItem('filter', JSON.stringify(this.state.filter));
          console.log(filter);
          this.makePostData1(this.state.filter);
        })
        .catch(error => {
          console.log(error.response.data);
          const searcherr = error.response.data.msg;
          this.setState({ searcherr })
          this.setState({ state2: false })
        })
    }

  }
  componentWillUnmount() {
    clearTimeout(this.intervalID);
  }
  rejectHandle(userid, e) {
    e.preventDefault();
    //const { company_name, person_name} = this.state
    console.log(userid)
    if (this.state.job_value === null) {
      axios
        .post('/api2/rejectcandidate', { ...this.state, userid })
        .then(response => {
          console.log(response);
          const msg = "Sie lehnen diese Anfrage ab";
          this.setState({ msg })
          this.intervalID = setTimeout(this.componentDidMount.bind(this), 100);
          //window.location.reload(true);
        })
        .catch(error => {
          console.log(error.response);
        })
    } else {
      axios
        .post('/api2/rejectcandidate1', { ...this.state, userid })
        .then(response => {
          console.log(response);
          const filter = response.data;
          this.setState({ filter });
          this.setState({ state1: false })
          localStorage.setItem('filter', JSON.stringify(this.state.filter));
          console.log(filter);
          this.makePostData1(this.state.filter);
        })
        .catch(error => {
          console.log(error.response.data);
          const searcherr = error.response.data.msg;
          this.setState({ searcherr })
          this.setState({ state2: false })
        })
    }

  }
  deleteHandle = e => {
    //e.preventDefault();

    console.log(this.state.user_id)
    axios
      .post('/api2/deletecandidate', this.state)
      .then(response => {
        console.log(response);
        const msg = "Sie lehnen diese Anfrage ab";
        this.setState({ msg })
        window.location.reload();
      })
      .catch(error => {
        console.log(error.response);
      })
  }
  mailHandle(userid, e) {
    e.preventDefault();
    //const { company_name, person_name} = this.state
    console.log(userid)
    axios
      .post('/api2/candidmail', { ...this.state, userid })
      .then(response => {
        console.log(response);
        const msg = "Sie lehnen diese Anfrage ab";
        this.setState({ msg })
        window.location.reload();
      })
      .catch(error => {
        console.log(error.response);
      })
  }
  mailHandleFeed(userid, e) {
    e.preventDefault();
    //const { company_name, person_name} = this.state
    console.log(userid)
    axios
      .post('/api2/candidmailFeedback', { ...this.state, userid })
      .then(response => {
        console.log(response);
        const msg = "Sie lehnen diese Anfrage ab";
        this.setState({ msg })
        window.location.reload();
      })
      .catch(error => {
        console.log(error.response);
      })
  }

  makePostData1(filter) {

    const SearchData = filter.reduce((acc, current) => {
      const x = acc.find(item => item.applicant_id === current.applicant_id);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);

    const slice1 = SearchData.slice(this.state.offset1, this.state.offset1 + this.state.perPage1)
    const postData1 = slice1.map(item1 => <React.Fragment>


      <Card style={{ backgroundColor: '#f5f5f5' }} >
        <CardBody><Row>
          <Col md='2'>
            {item1.picture === null || item1.picture === " " ? null : <img style={{ width: '100px', height: '100px' }}
              alt="..."
              className="avatar border-gray"
              src={`https://hello-doctor.de/uploads/${item1.picture}`}


            />}
          </Col>

          <Col md='5'><h7 className="text-left"   >
            <Link to={`/admin/detail-kandidate/${item1.applicant_id}`}>
            {item1.title === 'Kein' ? null : item1.title} {item1.f_name} {item1.l_name}
            </Link>
            <br></br>
            {item1.telephone === null ? null : <><h7 className="text-left" style={{ fontSize: '13px' }}><i class="fab fa-whatsapp"></i> &nbsp;<a href={`https://wa.me/${item1.telephone}`} target="_blank" rel="noopener noreferrer">{item1.telephone}</a></h7><br></br></>}
            <h7 className="text-left" style={{ fontSize: '13px' }}><i class="fas fa-envelope-open-text"></i>&nbsp;{item1.username}</h7><br></br>
            <i class="fas fa-map-marker-alt"></i>&nbsp;
            <h7 className="text-left" style={{ fontSize: '13px' }}>{item1.haus_no}, {item1.post_code} {item1.applicant_city}, &nbsp;{item1.country}</h7>
            <br></br>
            {this.state.notescount.map(item => {
              return (
                <>
                  {item1.applicant_id === item.app_id ?
                    <span>
                      <Badge className="mr-1" color="success" pill>
                        Notizen: {item.cnt}
                      </Badge>
                    </span>
                    :
                    null
                  }
                  {/* <Badge className="mr-1" color="success" pill>
                        Notizen: {item.cnt}
                          </Badge> */}
                </>
              )
            })}
          </h7>
          </Col>
          <Col md='3'>
            <h7 className="text-left" style={{ fontSize: '11px' }}><b>Registrierung:</b> {item1.created_date}</h7><br></br>
            <h7 className="text-left" style={{ fontSize: '11px' }}><b>Profile aktualisiert:</b> {item1.last_update}</h7><br></br>
            <h7 className="text-left" style={{ fontSize: '11px' }}><b>Eingeloggt zuletzt:</b> {item1.last_login_date}</h7><br></br>
            {/* {item1.status === "aktiv" ? <span>{this}</span>:null} */}
            {item1.status === 'aktiv' ? <h7 className="text-left" style={{ fontSize: '11px', color: '#28a745' }}><b>Konto Status:</b> {item1.status} </h7> :
              item1.status === "Kandidaten gelöscht" ? <><h7 className="text-left" style={{ fontSize: '11px', color: '#ff0800' }}><p>Konto wurde am {item1.account_del} vom{item1.status}</p>  </h7></> :
                <h7 className="text-left" style={{ fontSize: '11px', color: '#ff0800' }}><b>Konto Status:</b> {item1.status} </h7>
            }

          </Col>
          <Col md='2' className="text-right" style={{ marginTop: '-30px', marginBottom: '-20px' }}>
            <br></br>
            <Button
              className="btn-icon"
              color="primary"
              id={"tooltip366246639" + item1.applicant_id}
              size="sm"
              type="button"
              onClick={this.acceptHandle.bind(this, item1.user_id)}
            >
              <i class="fa fa-unlock" aria-hidden="true"></i>
            </Button>{" "}
            <UncontrolledTooltip
              delay={0}
              target={"tooltip366246639" + item1.applicant_id}
            >
              Aktivieren
            </UncontrolledTooltip>

            <Button
              className="btn-icon"
              color="danger"
              id={"tooltip366246640" + item1.applicant_id}
              size="sm"
              type="button"
              onClick={this.rejectHandle.bind(this, item1.user_id)}
            >
              <i className="fa fa-toggle-off" />
            </Button>{" "}
            <UncontrolledTooltip
              delay={0}
              target={"tooltip366246640" + item1.applicant_id}
            >
              Deaktivieren
            </UncontrolledTooltip>
            <br></br>

            <Button
              style={{ marginTop: '-5px' }}
              className="btn-icon"
              color="primary"
              id={"tooltip3662466421112" + item1.applicant_id}
              size="sm"
              type="button"
              onClick={this.mailHandle.bind(this, item1.user_id)}

            >
              <i className="fa fa-envelope" />
            </Button>{" "}
            <UncontrolledTooltip
              delay={0}
              target={"tooltip3662466421112" + item1.applicant_id}
            >
              Profile Update Email
            </UncontrolledTooltip>
            <Button
              style={{ marginTop: '-5px' }}
              className="btn-icon"
              color="primary"
              id={"tooltip3662466421113" + item1.applicant_id}
              size="sm"
              type="button"
              onClick={this.mailHandleFeed.bind(this, item1.user_id)}

            >
              <i className="fa fa-paper-plane" />
            </Button>{" "}
            <UncontrolledTooltip
              delay={0}
              target={"tooltip3662466421113" + item1.applicant_id}
            >
              Feedback Email
            </UncontrolledTooltip>
            {this.state.roles !== 'admin' ?
              null
              :
              <>
                <Button
                  style={{ marginTop: '-5px' }}
                  className="btn-icon"
                  color="danger"
                  id={"tooltip366246642" + item1.applicant_id}
                  size="sm"
                  type="button"
                  onClick={() => this.setState({ user_id: item1.user_id }, this.toggle)}
                >
                  <i className="fa fa-trash" />
                </Button>{" "}
                <UncontrolledTooltip
                  delay={0}
                  target={"tooltip366246642" + item1.applicant_id}
                >
                  Löschen
                </UncontrolledTooltip>
                <Link to={`/admin/messages-list/${item1.user_id}`}>
                      <Button
                        style={{ marginTop: '-5px' }}
                        className="btn-icon"
                        color="primary"
                        id={"tooltip366246644" + item1.applicant_id}
                        size="sm"
                        type="button"
                      //onClick={this.toggle}
                      //onClick={this.deleteHandle.bind(this, item1.user_id)}
                      //onClick={() => this.setState({ user_id: item1.user_id }, this.toggle)}
                      >

                        <i className="fa fa-comments" />
                      </Button>{" "}
                      <UncontrolledTooltip
                        delay={0}
                        target={"tooltip366246644" + item1.applicant_id}
                      >
                        Chat
                      </UncontrolledTooltip>

                    </Link>
              </>}
          </Col>


        </Row>

        </CardBody>

      </Card>
    </React.Fragment>)
    this.setState({ state2: true })
    this.setState({
      pageCount1: Math.ceil(filter.length / this.state.perPage1),
      postData1
    });
  }
  handlePageClick1 = (e) => {
    const selectedPage1 = e.selected;
    const offset1 = selectedPage1 * this.state.perPage1;

    this.setState({
      currentPage1: selectedPage1,
      offset1: offset1
    }, () => {
      this.makePostData1(this.state.filter);
    });

  };

  ////////////////////////////////////////////////////////
  render() {




    const filteredOpt = opt2.filter((o) => o.link === this.state.optSelect.value)
    const filteredOpt1 = opt3.filter((p) => p.link === this.state.opt2Select.value)

    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <Row>
                    <Col md="6">
                      <h2>
                        Kandidaten
                      </h2>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="12">
                      <Row className="justify-content-center ml-auto mr-auto">
                        <Col md="">
                          <Card>
                            <CardBody>
                              <div
                                aria-multiselectable={true}
                                className="card-collapse"
                                id="accordion"
                                role="tablist"
                              >
                                <CardTitle tag="h4" className="">Filter</CardTitle>
                                <Card className="card-plain">
                                  <Badge color="danger">{this.state.mainerr}</Badge>
                                  <Badge color="danger">{this.state.oneerr}</Badge>
                                  <CardHeader role="tab">
                                    <a
                                      aria-expanded={this.state.openedCollapses.includes(
                                        "collapseOne"
                                      )}
                                      href="#pablo"
                                      data-parent="#accordion"
                                      data-toggle="collapse"
                                      onClick={() => this.collapsesToggle("collapseOne")}
                                    >
                                      Kandidaten suchen nach Persönliche Angaben{" "}
                                      <i className="nc-icon nc-minimal-down" />
                                    </a>
                                  </CardHeader>
                                  <Collapse
                                    role="tabpanel"
                                    isOpen={this.state.openedCollapses.includes(
                                      "collapseOne"
                                    )}
                                  >
                                    <CardBody>

                                      <Row>
                                        <Col md="6">
                                          <label>Vollständiger Name</label>
                                          <FormGroup>
                                            <Input placeholder="Vorname/Nachname" type="text" name="l_name" onChange={this.changeHandler} />
                                          </FormGroup>
                                        </Col>
                                      </Row>

                                    </CardBody>
                                  </Collapse>
                                </Card>
                                <Card className="card-plain">
                                  <CardHeader role="tab">
                                    <a
                                      aria-expanded={this.state.openedCollapses.includes(
                                        "collapseTwo"
                                      )}
                                      href="#pablo"
                                      data-parent="#accordion"
                                      data-toggle="collapse"
                                      onClick={() => this.collapsesToggle("collapseTwo")}
                                    >
                                      Kandidaten suchen nach Stellenpräferenzen{" "}
                                      <i className="nc-icon nc-minimal-down" />
                                    </a>
                                  </CardHeader>
                                  <Collapse
                                    role="tabpanel"
                                    isOpen={this.state.openedCollapses.includes(
                                      "collapseTwo"
                                    )}
                                  >
                                    <CardBody>
                                      <div className="content">
                                        <Row>
                                          <Col>

                                            <Row>
                                              <Col md="12">
                                                {admin.map(() => {
                                                  return (
                                                    <>
                                                      <Row>
                                                        <Col className="" md="4">
                                                          <FormGroup>
                                                            <label >Berufsgruppe</label>
                                                            <Select
                                                              className="react-select primary"
                                                              classNamePrefix="react-select"
                                                              name="titleSelect"
                                                              value={this.state.optSelect}
                                                              onChange={this.changeHandler2}
                                                              options={opt}
                                                              placeholder="Select..."
                                                            />
                                                          </FormGroup>
                                                        </Col>
                                                        {this.state.visible ?
                                                          <Col className="" md="4">
                                                            <FormGroup>
                                                              <label>Funktion, Position</label>
                                                              <Select
                                                                className="react-select primary"
                                                                classNamePrefix="react-select"
                                                                name="titleSelect"
                                                                value={this.state.opt2Select}
                                                                onChange={this.changeHandler1}
                                                                options={filteredOpt}
                                                              />
                                                            </FormGroup>
                                                          </Col>
                                                          : null}
                                                        {this.state.visible1 ?
                                                          <Col className="" md="4">
                                                            <FormGroup>
                                                              <label>Fachgebiet, Fachabteilungen</label><Badge color="danger">{this.state.opt32err}</Badge><Badge color="danger">{this.state.opt31err}</Badge>
                                                              <Select
                                                                isMulti
                                                                closeMenuOnSelect={false}
                                                                className="react-select primary"
                                                                classNamePrefix="react-select"
                                                                name="titleSelect"
                                                                value={this.state.opt3Select}
                                                                onChange={this.changeHandler3}
                                                                options={filteredOpt1}
                                                              />
                                                            </FormGroup>
                                                          </Col>
                                                          : null}
                                                      </Row>
                                                    </>
                                                  )
                                                })}
                                              </Col>
                                            </Row>
                                          </Col>
                                        </Row>
                                      </div>
                                    </CardBody>
                                  </Collapse>
                                </Card>

                              </div>
                            </CardBody>
                            <CardFooter>
                              <Row>
                                <Col className="text-center">
                                  <Button
                                    className="btn-round center"
                                    outline
                                    size="md"
                                    color="primary"
                                    onClick={this.submitHandler}
                                  >
                                    Suchen
                                  </Button>
                                </Col>
                              </Row>

                            </CardFooter>
                          </Card>
                        </Col>
                      </Row>
                      <hr />
                      <FormGroup>
                        <Badge color="danger">{this.state.errvalue}</Badge>
                        <Select
                          className="react-select primary"
                          name="job_value"
                          onChange={this.changeHandler10.bind(this)}
                          value={this.state.job_value}
                          options={option}
                          placeholder="Sortieren nach"
                          type="text"
                        />
                      </FormGroup>
                      <Card>
                        <CardHeader>
                        </CardHeader>
                        <CardBody>

                          {this.state.state1 ? <>
                            {this.state.postData}
                          </>
                            :
                            <>{this.state.state2 ?
                              <> {this.state.postData1} </>
                              : null}</>
                          }
                          <br></br>
                          <br></br>
                          {this.state.state1 ?
                            <ReactPaginate
                              previousLabel={"<"}
                              nextLabel={">"}
                              breakLabel={"..."}
                              breakClassName={"break-me"}
                              pageCount={this.state.pageCount}
                              marginPagesDisplayed={2}
                              pageRangeDisplayed={5}
                              onPageChange={this.handlePageClick}
                              containerClassName={"pagination"}
                              subContainerClassName={"pages pagination"}
                              activeClassName={"active"} />
                            :
                            <span> {this.state.state2 ? <ReactPaginate
                              previousLabel={"<"}
                              nextLabel={">"}
                              breakLabel={"..."}
                              breakClassName={"break-me"}
                              pageCount={this.state.pageCount1}
                              marginPagesDisplayed={2}
                              pageRangeDisplayed={5}
                              onPageChange={this.handlePageClick1}
                              containerClassName={"pagination"}
                              subContainerClassName={"pages pagination"}
                              activeClassName={"active"} /> : <Badge color="danger">No Kandidates For this Result</Badge>}</span>
                          }
                          <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}
                          >
                            <ModalHeader toggle={this.toggle}></ModalHeader>
                            <ModalBody>
                              <h4 ><b>Kandidaten dauerhaft löschen</b></h4>
                              <br></br><Badge color="warning">{this.state.reply}</Badge><br />
                              Sind Sie sicher, dass Sie diesen Kandidaten löschen möchten?<br></br>
                              <br></br>
                              <center><Button
                                onClick={this.toggle}
                                style={{
                                  width: 'auto',
                                  height: '32px',
                                  fontSize: '9px',
                                  borderRadius: '32px',
                                  boxShadow: '4.5px 7.794px 13px 0px #afc1ec',
                                  fontWeight: '400',
                                  border: 'none !important',
                                  padding: '9px 27px',
                                  // background: '#dc3545',
                                  marginBottom: '1rem'
                                }}>Abbrechen</Button>
                                <Button
                                  color="primary"
                                  style={{
                                    width: 'auto',
                                    height: '32px',
                                    fontSize: '9px',
                                    borderRadius: '32px',
                                    boxShadow: '4.5px 7.794px 13px 0px #afc1ec',
                                    fontWeight: '400',
                                    border: 'none !important',
                                    padding: '10px 27px',
                                    background: '#dc3545'
                                  }}
                                  onClick={this.deleteHandle}
                                >

                                  Bestätigen </Button></center>

                            </ModalBody>
                          </Modal>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default FollowingCandidate;
