import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import axios from "axios";
import Button from 'reactstrap/lib/Button';
import {
    Row,
    Col,
    TabContent, TabPane, Nav, NavItem, NavLink,
    Card,
    CardBody,
    CardHeader
} from 'reactstrap'
import classnames from 'classnames';
import Button_loading from 'components/Button_loading';
import SunEditor from 'suneditor-react';
import Moment from 'moment';

import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File

export default class ContractEditor extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: '',
            value1: '',
            activeTab: "1",
            loading:false,
            disabled:false,
            candidate_list:[],
            company_list:[]
        };
        this.toggle = this.toggle.bind(this);
    }
    modules = {
        toolbar: [
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image', 'color'],
            ['clean']
        ],
    }

    formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image'
    ]
    submitHandler = e => {
        this.setState({ loading: true })
        this.setState({disabled:true})
        axios
            .post('/api2/EditorContractCompany', this.state)
            .then(response => {
                //   console.log(response)
                //   this.setState({adduser_err:undefined})
                //   var adduser_succ = response.data.msg;
                //   this.setState({ adduser_succ })
                this.intervalID = setTimeout(this.componentDidMount.bind(this), 100);
                setTimeout(() => {
                    this.setState({ loading: false })
                    this.setState({disabled:false})
                }, 1500);
            })
            .catch(error => {
                console.log(error.response);
                setTimeout(() => {
                    this.setState({ loading: false })
                    this.setState({disabled:false})
                }, 1500);
                //   this.setState({adduser_succ:undefined})
                //   var adduser_err = error.response.data.msg
                //   this.setState({ adduser_err })
            })
        //}

    }



    submitHandler1 = e => {
        this.setState({ loading: true })
        this.setState({disabled:true})
        axios
            .post('/api2/EditorContractCandidate', this.state)
            .then(response => {
                //   console.log(response)
                //   this.setState({adduser_err:undefined})
                //   var adduser_succ = response.data.msg;
                //   this.setState({ adduser_succ })
                this.intervalID = setTimeout(this.componentDidMount.bind(this), 100);
                setTimeout(() => {
                    this.setState({ loading: false })
                    this.setState({disabled:false})
                }, 1500);
            })
            .catch(error => {
                console.log(error.response);
                setTimeout(() => {
                    this.setState({ loading: false })
                    this.setState({disabled:false})
                }, 1500);
                //   this.setState({adduser_succ:undefined})
                //   var adduser_err = error.response.data.msg
                //   this.setState({ adduser_err })
            })
        //}

    }
    componentDidMount() {
        this.setState({ loading: true })
        this.setState({disabled:true})
        axios
            .post('/api2/GetEditorContractCompany', this.state)
            .then(response => {
                //console.log(response)
                const value = response.data.result[0].EditorDesc;
                const Contract_Company_id = response.data.result[0].Contract_Company_id
                //console.log(AGB_Company_id)
                this.setState({ Contract_Company_id })
                this.setState({ value })
                setTimeout(() => {
                    this.setState({ loading: false })
                    this.setState({disabled:false})
                }, 1500);
                //this.setState({adduser_err:undefined})
                //   var adduser_succ = response.data.msg;
                //   this.setState({ adduser_succ })
            })
            .catch(error => {
                console.log(error.response);
                setTimeout(() => {
                    this.setState({ loading: false })
                    this.setState({disabled:false})
                }, 1500);
                //   this.setState({adduser_succ:undefined})
                //   var adduser_err = error.response.data.msg
                //   this.setState({ adduser_err })
            })

        axios
            .post('/api2/GetEditorContractCandidate', this.state)
            .then(response => {
                //console.log(response)
                const value1 = response.data.result[0].EditorDesc;
                const Contract_Candidate_id = response.data.result[0].Contract_Candidate_id
                //  console.log(value1)
                this.setState({ Contract_Candidate_id })
                this.setState({ value1 })
                setTimeout(() => {
                    this.setState({ loading: false })
                    this.setState({disabled:false})
                }, 1500);
                //this.setState({adduser_err:undefined})
                //   var adduser_succ = response.data.msg;
                //   this.setState({ adduser_succ })
            })
            .catch(error => {
                console.log(error.response);
                setTimeout(() => {
                    this.setState({ loading: false })
                    this.setState({disabled:false})
                }, 1500);
                //   this.setState({adduser_succ:undefined})
                //   var adduser_err = error.response.data.msg
                //   this.setState({ adduser_err })
            })

            axios
            .post('/api2/GetEditorContractCandidateAll', this.state)
            .then(response => {
                console.log(response)
                const candidate_list = response.data.result;
                //  console.log(value1)
                this.setState({ candidate_list })
                // setTimeout(() => {
                //     this.setState({ loading: false })
                //     this.setState({ disabled: false })
                // }, 1500);
                //this.setState({adduser_err:undefined})
                //   var adduser_succ = response.data.msg;
                //   this.setState({ adduser_succ })
            })
            .catch(error => {
                console.log(error.response);
                // setTimeout(() => {
                //     this.setState({ loading: false })
                //     this.setState({ disabled: false })
                // }, 1500);
                //   this.setState({adduser_succ:undefined})
                //   var adduser_err = error.response.data.msg
                //   this.setState({ adduser_err })
            })
            axios
            .post('/api2/GetEditorContractCompanyAll', this.state)
            .then(response => {
                console.log(response)
                const company_list = response.data.result;
                //  console.log(value1)
                this.setState({ company_list })
                // setTimeout(() => {
                //     this.setState({ loading: false })
                //     this.setState({ disabled: false })
                // }, 1500);
                //this.setState({adduser_err:undefined})
                //   var adduser_succ = response.data.msg;
                //   this.setState({ adduser_succ })
            })
            .catch(error => {
                console.log(error.response);
                // setTimeout(() => {
                //     this.setState({ loading: false })
                //     this.setState({ disabled: false })
                // }, 1500);
                //   this.setState({adduser_succ:undefined})
                //   var adduser_err = error.response.data.msg
                //   this.setState({ adduser_err })
            })
    }

    componentWillMount() {
        const user_id = localStorage.getItem("user_id")
        //  console.log(user_id)
        this.setState({ user_id })
    }

    onChange = (value) => {
        //   console.log(value)
        this.setState({ value: value });
    }

    onChange1 = (value) => {
        console.log(value)
        this.setState({ value1: value });
    }
    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    render() {
        return (
            <div className="content">
                <Nav pills style={{ justifyContent: "center" }}>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === '1' })}
                            onClick={() => { this.toggle('1'); }}
                        >
                            Vertrag Kandidaten
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === '2' })}
                            onClick={() => { this.toggle('2'); }}
                        >
                            Vertrag Unternehmen
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeTab === '3' })}
                            onClick={() => { this.toggle('3'); }}
                        >
                            Vertrag Archived
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                        <div style={{ background: 'white' }}>
                            <Row>
                                <Col md="8">
                                    <h4 style={{ textAlign: "center" }}>Vertrag Kandidaten</h4>

                                </Col>
                                <Col md="4" >
                                    <Link to="/admin/ContractEditor_New_Version_Kandidaten">
                                        <Button
                                            style={{ marginTop: '25px' }}
                                            color="primary"
                                        >

                                            Add New (Kandidaten) </Button>
                                    </Link>

                                </Col>
                            </Row>
                            <div>
                                <SunEditor

                                    setContents={this.state.value1}
                                    onChange={this.onChange1}
                                    // onClick={this.submitHandler1}
                                    lang="en"
                                    width="100%"
                                    height="100%"

                                    setOptions={{
                                        buttonList: [
                                            ['undo', 'redo'],
                                            ['font', 'fontSize', 'formatBlock'],
                                            ['paragraphStyle', 'blockquote'],
                                            ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                                            ['fontColor', 'hiliteColor', 'textStyle'],
                                            ['removeFormat'],
                                            '/', // Line break
                                            ['outdent', 'indent'],
                                            ['align', 'horizontalRule', 'list', 'lineHeight'],
                                            ['table', 'link', /** 'image', 'video', 'audio' ,'math' */], // You must add the 'katex' library at options to use the 'math' plugin.
                                            /** ['imageGallery'] */ // You must add the "imageGalleryUrl".
                                            ['fullScreen', 'showBlocks', 'codeView'],
                                            ['preview', 'print'],
                                            ['save', 'template'],
                                            /** ['dir', 'dir_ltr', 'dir_rtl'] */ // "dir": Toggle text direction, "dir_ltr": Right to Left, "dir_rtl": Left to Right
                                        ]
                                    }}
                                />
                                <Button_loading
                                    style={{ float: 'right' }}
                                    color="primary"
                                    onClick={this.submitHandler1}
                                    loading={this.state.loading}
                                    disabled={this.state.disabled}
                                >

                                    Save </Button_loading>
                            </div>
                        </div>
                    </TabPane>
                    <TabPane tabId="2">
                        <div style={{ background: 'white' }}>

                            <Row>
                                <Col md="8">
                                    <h4 style={{ textAlign: "center" }}>Vertrag Unternehmen</h4>

                                </Col>
                                <Col md="4" >
                                    <Link to="/admin/ContractEditor_New_Version_Unternehmen">
                                        <Button
                                            style={{ marginTop: '25px' }}
                                            color="primary"
                                        >

                                            Add New (Unternehmen) </Button>
                                    </Link>

                                </Col>
                            </Row>
                            <div>

                                <SunEditor

                                    setContents={this.state.value}
                                    onChange={this.onChange}
                                    // onClick={this.submitHandler1}
                                    lang="en"
                                    width="100%"
                                    height="100%"

                                    setOptions={{
                                        buttonList: [
                                            ['undo', 'redo'],
                                            ['font', 'fontSize', 'formatBlock'],
                                            ['paragraphStyle', 'blockquote'],
                                            ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                                            ['fontColor', 'hiliteColor', 'textStyle'],
                                            ['removeFormat'],
                                            '/', // Line break
                                            ['outdent', 'indent'],
                                            ['align', 'horizontalRule', 'list', 'lineHeight'],
                                            ['table', 'link', /** 'image', 'video', 'audio' ,'math' */], // You must add the 'katex' library at options to use the 'math' plugin.
                                            /** ['imageGallery'] */ // You must add the "imageGalleryUrl".
                                            ['fullScreen', 'showBlocks', 'codeView'],
                                            ['preview', 'print'],
                                            ['save', 'template'],
                                            /** ['dir', 'dir_ltr', 'dir_rtl'] */ // "dir": Toggle text direction, "dir_ltr": Right to Left, "dir_rtl": Left to Right
                                        ]
                                    }}
                                />
                                <Button_loading
                                    style={{ float: 'right' }}
                                    color="primary"
                                    onClick={this.submitHandler}
                                    loading={this.state.loading}
                                    disabled={this.state.disabled}
                                >

                                    Save </Button_loading>
                            </div>
                        </div>
                    </TabPane>

                    <TabPane tabId="3">
                        <div style={{ background: 'white' }}>
                            <Card>
                                <CardHeader>
                                <h4>Kandidaten Vertrag List</h4>
                                </CardHeader>
                                <CardBody>
                                {this.state.candidate_list.map((user) => (
                                <div key={user.Contract_Candidate_id}><Link to={"candidate_vertrag_edit/"+user.Contract_Candidate_id}><li>{Moment(user.Date).format("DD.MM.YYYY")}</li></Link></div>
                            ))}
                                </CardBody>
                            
                          
                            </Card>

                            <Card>
                                <CardHeader>
                                <h4>Unternehmen Vertrag List</h4>
                                </CardHeader>
                                <CardBody>
                                {this.state.company_list.map((user) => (
                                <div key={user.Contract_Company_id}><Link to={"company_vertrag_edit/"+user.Contract_Company_id}><li>{Moment(user.Date).format("DD.MM.YYYY")}</li></Link></div>
                            ))}
                                </CardBody>
                            
                          
                            </Card>
                          
                        </div>
                    </TabPane>
                </TabContent>

                {/* <div style={{ background: 'white' }}>
                    <Row>
                        <Col md="8">
                            <h4>Vertrag Editor Unternehmen</h4>
                        </Col>
                        <Col md="4" >
                            <Link to="/admin/AGBEditor_New_Version_Unternehmen">
                            <Button
                            style={{marginTop:'25px'}}
                                color="primary"
                            >

                                Add New (Unternehmen) </Button>
                            </Link>
                           
                        </Col>
                    </Row>

                    <ReactQuill
                        className="editor"
                        theme="snow"
                        value={this.state.value}
                        onChange={this.onChange}
                        modules={this.modules}
                        formats={this.formats}
                    />
                    <div style={{ marginBottom: '70px' }}>
                        <Button
                            style={{ float: 'right' }}
                            color="primary"
                            onClick={this.submitHandler}
                        >

                            Save </Button>
                    </div>

                </div>

                <div style={{ background: 'white' }}>
   
                    <Row>
                        <Col md="8">
                        <h4 style={{ textAlign: "center" }}>Vertrag Editor Kandidaten</h4>
                  
                        </Col>
                        <Col md="4" >
                            <Link to="/admin/AGBEditor_New_Version_Kandidaten">
                            <Button
                            style={{marginTop:'25px'}}
                                color="primary"
                            >

                                Add New (Kandidaten) </Button>
                            </Link>
                           
                        </Col>
                    </Row>

                    <ReactQuill
                        className="editor"
                        theme="snow"
                        value={this.state.value1}
                        onChange={this.onChange1}
                        modules={this.modules}
                        formats={this.formats}
                    />
                    <Button
                        style={{ float: 'right' }}
                        color="primary"
                        onClick={this.submitHandler1}
                    >

                        Save </Button>
                        
                </div>
               */}
                <br></br>  <br></br>


                <br></br>  <br></br>


            </div>
        )
    }
}


