import React, { Component } from 'react'
import axios from "axios";
import Button_loading from 'components/Button_loading';
import SunEditor from 'suneditor-react';

export default class ImpressumEditor extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: '',
            loading:false,
            disabled:false
        };
    }
    modules = {
        toolbar: [
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image', 'color'],
            ['clean']
        ],
    }

    formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image'
    ]
    submitHandler = e => {
        this.setState({ loading: true })

        // e.preventDefault();
        // var email_err;
        // var pass_err;
        // var role_err;
        // if (this.state.email === '') {
        //   email_err = "please enter email"
        //   this.setState({email_err})
        // } else {
        //   this.setState({email_err:undefined})
        // }
        //  if (email_err === undefined && pass_err === undefined && role_err === undefined) {
        axios
            .post('/api2/EditorImpressum', this.state)
            .then(response => {
                //   console.log(response)
                //   this.setState({adduser_err:undefined})
                //   var adduser_succ = response.data.msg;
                //   this.setState({ adduser_succ })
                this.intervalID = setTimeout(this.componentDidMount.bind(this), 100);
                setTimeout(() => {
                    this.setState({ loading: false })
                }, 1500);
            })
            .catch(error => {
                console.log(error.response);
                //   this.setState({adduser_succ:undefined})
                //   var adduser_err = error.response.data.msg
                //   this.setState({ adduser_err })
                setTimeout(() => {
                    this.setState({ loading: false })
                }, 1500);
            })
        //}

    }
    componentDidMount() {
        this.setState({ loading: true })
        console.log("test")
        axios
            .post('/api2/GetEditorImpressum', this.state)
            .then(response => {
                //console.log(response)
                const value = response.data.result[0].EditorDesc;
                console.log(value)
                this.setState({ value })
                setTimeout(() => {
                    this.setState({ loading: false })
                }, 1500);
                //this.setState({adduser_err:undefined})
                //   var adduser_succ = response.data.msg;
                //   this.setState({ adduser_succ })
            })
            .catch(error => {
                console.log(error.response);
                setTimeout(() => {
                    this.setState({ loading: false })
                }, 1500);
                //   this.setState({adduser_succ:undefined})
                //   var adduser_err = error.response.data.msg
                //   this.setState({ adduser_err })
            })
    }

    componentWillMount() {
        const user_id = localStorage.getItem("user_id")
        //  console.log(user_id)
        this.setState({ user_id })
    }

    onChange = (value) => {
        console.log(value)
        this.setState({ value: value });
    }

    getText = (html) => {
        const doc = new DOMParser().parseFromString(html, "text/html")
        return doc.body.textContent
      }
    render() {
        return (
            <div className="content">
                <div style={{ background: 'white' }}>
                    <h4 style={{textAlign:"center"}}>Impressum</h4>
                 
                     <SunEditor
                        setContents={this.state.value}
                        onChange={this.onChange}
                        // onClick={this.submitHandler1}
                        lang="en"
                        width="100%"
                        height="100%"

                        setOptions={{
                            buttonList: [
                                ['undo', 'redo'],
                                ['font', 'fontSize', 'formatBlock'],
                                ['paragraphStyle', 'blockquote'],
                                ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                                ['fontColor', 'hiliteColor', 'textStyle'],
                                ['removeFormat'],
                                '/', // Line break
                                ['outdent', 'indent'],
                                ['align', 'horizontalRule', 'list', 'lineHeight'],
                                ['table', 'link', /** 'image', 'video', 'audio' ,'math' */], // You must add the 'katex' library at options to use the 'math' plugin.
                                /** ['imageGallery'] */ // You must add the "imageGalleryUrl".
                                ['fullScreen', 'showBlocks', 'codeView'],
                                ['preview', 'print'],
                                ['save', 'template'],
                                /** ['dir', 'dir_ltr', 'dir_rtl'] */ // "dir": Toggle text direction, "dir_ltr": Right to Left, "dir_rtl": Left to Right
                            ]
                        }}
                    />
                    <Button_loading
                        style={{ float: 'right' }}
                        color="primary"
                        onClick={this.submitHandler}
                        loading={this.state.loading}
                        disabled={this.state.disabled}
                    >

                        Save </Button_loading>
                </div>

                
            </div>
        )
    }
}


