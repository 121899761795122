import React from "react";
import axios from 'axios';
import { Redirect} from "react-router-dom";

// reactstrap components

import {Button,Card,CardHeader,Row,Col, Modal, ModalHeader,ModalBody
} from "reactstrap";

//import { FaBeer } from 'react-icons/fa';
class Referal2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      salutationSelect: null,
      titleSelect: null,
      modal: false,
    };
    this.toggle = this.toggle.bind(this);
  }
  toggle() {
    this.setState({
      modal: !this.state.modal
    });
   
  }
    componentWillMount(){
    const user_id = JSON.parse(localStorage.getItem('user_id'))
    this.setState({user_id})
    const email = JSON.parse(localStorage.getItem('email'))
    this.setState({email})
  }
  submitHandler = e => {
      axios
    .post('/api2/referalcode',this.state)
    .then(response =>{
      const msg = response.data.msg;
      this.setState({msg})
      this.setState({redirect : true})
    })
    .catch(error =>{
      console.log(error.response)
    })
  }
  render() {
    const { redirect } = this.state;
    if (redirect) {
      return <Redirect to='/admin/referallast'/>;
    }
    return (
      <>
        <div className="content">
              <Card className="card-stats">
                <Row>
                  <Col md="12">
                 <div> 
                <img src="../../../img2/referral.png" />
                 </div>
                   
                  </Col>
                
                </Row>

                <Row>
                  <Col>
                  <div style={{marginLeft:'5%'}}>
                  <h1>
                  So einfach geht's!
                  </h1>

                  <ul>
                    <li>
                   <b>1. Prüfe Deine E-Mail-Adresse</b> <br></br>
                    Deine aktuell hinterlegte E-Mail-Adresse lautet: {this.state.email}
                    </li>
                    <li>
                      
                    <b>2. E-Mail mit Empfehlungscode an Deinen Freund weiterleiten </b><br></br>
                      Du erhältst eine E-Mail mit dem persönlichen Empfehlungscode. Diese leitest Du an Deinen Freund weiter.

                    </li>
                  </ul>
                 
                <p>
                <b>
                  Damit auch alles klappt, sind diese Schritte sehr wichtig:
                  </b>
                <ul>
                  <li>Nur über den Link „Jetzt Job finden“ registriert Dein Freund sich richtig.
                </li>
                  <li>Dieser gibt Dein Empfehlungscode an. Jetzt ist Deine Freundschaftswerbung richtig zugeordnet.</li>
                  <li> Jeder Empfehlungscode ist nur einmal gültig.</li>
                </ul>
                
               
                </p>
              
                 </div>
                  </Col>
                </Row>
         
                <CardHeader>
                    {this.state.msg}<br></br>
              <Button color = "primary" style={{marginLeft: '4%'}} type="submit" onClick = {this.submitHandler}>
              Jetzt Freunde werben
              </Button>
             <br></br>
              <Button  style={{marginLeft: '4%'}} color="link" size="sm" 
               onClick={this.toggle}>
              Konditionen
              </Button>
                  </CardHeader>
                <p style={{fontSize:"10px", padding:'4%', color:'#989898'}}>
                <b>Hinweis:</b> Falls Dein Freund sich bei uns nicht registriert oder die Konditionen nicht erfüllt, erhältst Du keine weitere Benachrichtigung. Wir behalten uns vor, einzelne Werber von der Aktion auszuschließen sowie bei Nichterfüllung der Bedingungen oder Missbrauch in jeglicher Form die Prämie zurückzufordern. Die Gül GmbH behält sich vor, diese Aktion frühzeitig zu beenden.
                </p>
                </Card>  

    <Modal isOpen={this.state.modal} toggle={this.toggle}  className={this.props.className}
        >
          <ModalHeader toggle={this.toggle}></ModalHeader> 
          <ModalBody style={{padding:'2rem'}}>
            <h4 style={{marginTop:'15px'}}>Konditionen für Dich und Deinen Freund</h4>
        <Row>
          <Col md="6" style={{backgroundColor:'rgb(247 247 246)'}}> 
          <p>Barprämie</p>
          </Col>
          <Col md="6" style={{backgroundColor:'#f4f3ef'}}>
          <p>Die Barprämie wird auf Dein Konto überwiesen.</p>
          </Col>
        </Row>
        <h4  style={{marginTop:'15px'}}>Wann ist Ihre Freunde-Empfehlung erfolgreich?</h4>
        <Row>
          <Col md="6" style={{backgroundColor:'rgb(247 247 246)'}}> 
          <p>Eröffnung</p>
          </Col>
          <Col md="6" style={{backgroundColor:'#f4f3ef'}}>
          <ul>
          Ein geworbener Neukunde muss:
            <li>
            Den in der Empfehlungs-E-Mail enthaltenen Link öffnen, um sich zu registrieren.
            </li>

            <li>
            Den Empfehlungscode eingeben. Nur dann können wir den Neukunden dem „Werber” zuordnen.
            </li>
          </ul>
          </Col>
        </Row>
        <Row>
          <Col md="6" style={{backgroundColor:'rgb(247 247 246)'}}> 
          <p>Prämienanspruch</p>
          </Col>
          <Col md="6" style={{backgroundColor:'#f4f3ef'}}>
          <p><ul>
          Ein Werber erhält die Prämie sofern der geworbene Neukunde nach erfolgreicher Registrierung folgende Bedingunge erfüllt hat:
            <li>
            Wenn jemand, den Du uns empfiehlst, für eine Teilzeit, Vollzeitstelle in Festanstellung oder Befristet über uns eingestellt wird, überweisen wir Dir 200€.
            </li>
            </ul></p>
          </Col>
        </Row>
        <Row>
          <Col md="6" style={{backgroundColor:'rgb(247 247 246)'}}> 
          <p>Prämiengutschrift</p>
          </Col>
          <Col md="6" style={{backgroundColor:'#f4f3ef'}}>
          <p>Zwischen Einstellung Ihres geworbenen Neukunden und dem Erhalt der Prämie können bis zu 4 Wochen vergehen.</p>
          </Col>
        </Row>
        <h4  style={{marginTop:'15px'}}>Wer kann mitmachen?</h4>
        <Row>
          <Col md="6" style={{backgroundColor:'rgb(247 247 246)'}}> 
          <p>Werber</p>
          </Col>
          <Col md="6" style={{backgroundColor:'#f4f3ef'}}>
        
          Kandidaten Hello-Sister mit einem bestehenden Konto – diese muss mindestens bis zum Erhalt der Prämie bestehen.

          </Col>
        </Row>

        <Row>
          <Col md="6" style={{backgroundColor:'rgb(247 247 246)'}}> 
          <p>Geworbene</p>
          </Col>
          <Col md="6" style={{backgroundColor:'#f4f3ef'}}>
          <ul>
            <br></br>
            <li>
            Bewerber, die kein Kunde von hello-doctor.de sind.
            </li>

            <li>
            Dieses Angebot richtet sich ausschließlich an Personal im Gesundheitswesen in den Kategorien Krankenpflege, Altenpflege, Medizintechnik, Praxispersonal, Rettungsdienst, Therapeut, Pharmazie, u.ä. medizinische Stellungen und Tätigkeiten.
            </li>
          </ul>
          </Col>
        </Row>
       
          
        
       <center>
       <Button color = "primary" style={{marginLeft: '4%'}} type="submit" onClick = {this.submitHandler}>
              Jetzt Freunde werben
              </Button>
         <Button  
         style={{boxShadow: '4.5px 7.794px 13px 0px #afc1ec',
          borderRadius: '30px'}}
          color = "danger"
                  onClick={this.toggle}  
                 >Abbrechen</Button>
          </center>
          <p style={{fontSize:"10px", padding:'4%', color:'#989898'}}>
                <b>Hinweis:</b> Falls Dein Freund sich bei uns nicht registriert oder die Konditionen nicht erfüllt, erhältst Du keine weitere Benachrichtigung. Wir behalten uns vor, einzelne Werber von der Aktion auszuschließen sowie bei Nichterfüllung der Bedingungen oder Missbrauch in jeglicher Form die Prämie zurückzufordern. Die Gül GmbH behält sich vor, diese Aktion frühzeitig zu beenden.
                </p>
         </ModalBody>
        </Modal>            
        </div>
      </>
    );
  }
}
export default Referal2;
