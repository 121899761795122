import React from "react";
import axios from 'axios';
import {Redirect } from "react-router-dom";
// react plugin used to create DropdownMenu for selecting items
//import Select from "react-select";
import { Link } from "react-router-dom";
//import {jsPDF} from "jspdf";
import {
  Card,
  CardHeader,
  CardBody,
  //CardTitle,
  //CardFooter,
  //FormGroup,
  Row,
  Col,
  Table,
  Button,
  //UncontrolledTooltip,
  //Badge
} from "reactstrap";
class Imprint extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      salutationSelect: null,
      titleSelect: null,
      horizontalTabs: "HelloMatch",
      visible: false,
      visible1: false,
      visible2: false,
      visible3: false,
      visible4: false,
      visible5: false,
      visible6: true,
      visible7: true,
      visible8: true,
      visible9: true,
      visible10: true,
      visible11: true,
      visible12: true,
      visible13: true,
      visible14: true,
      visible15: true,
      visible16: true,
      visible17: true,
      visible18: true,
      visible19: true,
      visible20: true,
      visible23: true,
      visible41: true,
      visible42: true,
      visible43: true,
      visible44: true,
      visible45: true,
      visible46: true,
      candidatelist : [],
      hospital : [],
      app_sign : null,
      app_sign_city : null,
      app_sign_date : null
    };
  }
  // componentWillMount(){
  //   const user_id = JSON.parse(localStorage.getItem('user_id'))
  // this.setState({user_id})
  // }

  componentDidMount(){
    console.log(this.state);
    //console.log("abc")
    const {hosp_id} =  this.props.match.params;
    console.log(hosp_id)
    this.setState({hosp_id})
    axios.post("/api2/allhospitaljobs", {...this.state,hosp_id})
   .then(response => { // then print response status
    const msg = response.data.Message;
    this.setState({msg});
    console.log(response)
    const candidatelist = response.data;
    localStorage.setItem('candidatelist', JSON.stringify(this.state.candidatelist));
     this.setState({candidatelist});
 })
 .catch(error => {
  console.log(error);
})
////////////////////////////////////////////////////////////////
axios.post("/api2/hospitaldetails", {...this.state,hosp_id})
   .then(response => { // then print response status
    const msg = response.data.Message;
    this.setState({msg});
    console.log(response)
    const hospital = response.data;
    localStorage.setItem('hospital', JSON.stringify(this.state.hospital));
    this.setState({hospital});
    console.log(hospital)
 })
 .catch(error => {
  console.log(error);
})
  }
  render() {
    const { redirect } = this.state;
  
       if (redirect) {
         return <Redirect to='/admin/Kontaktierte-Kandidaten-Search'/>;
       }
    return (
      <>
        <div className="content">
             <Card>
               <CardHeader>
                <h5><b>Unternehmen</b></h5> 
               </CardHeader>
               <CardBody>
               {this.state.hospital.map((item) => {
                          return (
                            <>
                               <Card key = {item.job_id}>
                          <CardBody>
                           <Row>
                            <Col md = "1.5">
                            <img src ={`../uploads/${item.hosp_logo}`} alt='' height = '120' width = '150' />
                            </Col>
                            <Col>
                           
                            <h5><b>
                        
                     {item.hosp_name}</b></h5>
                     <h7>
                          {item.adress} {item.haus_number}, <br></br>
                          {item.zip_code} {item.city} <br></br> {item.country} </h7>
                  
                </Col>
                <Col>
                <h5><b> Kontakt Person</b></h5>
                  <h7>
                          {item.f_name} {item.l_name} <br></br>
                          {item.username} <br></br> {item.telephone} </h7>
                     
                </Col>
                  </Row>
              
                  {/* <Row>
                  <Col md="3">
                    Status 
                    <br />
                    <h5 style = {{fontSize: '16px'}}>
                    <Badge className="mr-1" color="success" pill>
                          {item.job_status}
                          </Badge> 
                          </h5>
                    

                    </Col>
                    <Col md="2">
                
                    <br />
                    <h5 style = {{fontSize: '16px'}}>
                    {item.job_ternure}
                    </h5>
                    
                    </Col>
                    
                    <Col md="2">
                
                    <br />
                    <h5 style = {{fontSize: '16px'}}>
                    {item.job_worktime} 
                    </h5>
                    

                    </Col>

                    <Col md="2">
                    
                    <br />
                    <h5 style = {{fontSize: '16px'}}>
                    {item.city}
                    </h5>
                    

                    </Col>
                    
                    
                    <Col md="3">
                    
                    <br />
                    <h5 style = {{fontSize: '16px'}}>
                    Laufzeit bis: {item.valid_till} 
                    </h5>
                    
                    </Col>

                    
                </Row>
               */}
                  </CardBody>
             </Card>                        
                            </>
                                               
                                               )
                                             })} 
               </CardBody>
             </Card>
              <Card>
                <CardHeader>
             <h3><b>Jobs</b></h3></CardHeader>
             <CardBody>
             <Table responsive>
                    <thead className="text-primary">
                      <tr>
                        <th className="text-left">Online Seit</th>
                        <th className="text-left">Online Bis</th>
                        <th className="text-left">Job Title</th>
                        <th className="text-left">Unternehmen</th>
                        {/* <th className="text-left">Bewerbungen</th> */}
                        {/* <th className="text-left">Status</th> */}
                        <th className="text-right">Aktionen</th> 
                      </tr>
                    </thead>
                   <tbody>
                   {this.state.candidatelist.map((item) => {
                          return (
                            <> 
      
<tr>
                               <td className="text-left">

<medium className="">
{item.created_date}
</medium> 
<br/>

</td>
<td className="text-left">
{item.valid_till}
</td> 
<br/>
<Link to= {`/admin/job-profile/${item.job_id}/${item.app_id}`} target="_blank">
{item.job_function} (m/w/d) {item.profession}</Link>
<td className="text-left">

  <medium>
  <b>{item.company_name}</b> <br></br>
   {item.address}  {item.haus_no}, {item.zip_code} {item.city}
  </medium>
  

<br/>

</td>
<td className="text-right">


                          <Link to={`/admin/candidatelist1/${item.job_id}/${item.hosp_id}`}> 
                          <Button
                            
                            color="info"
                            id="tooltip476609784"
                            style={{fontSize: '12px'}}
                            type="button"
                          >
                           Bewerbungen
                          </Button>{" "}
                           </Link> 
</td>
                              </tr>
                          </>
                      
                      )
                    })} 
                    </tbody>
                    
                 
                    
        
                  </Table>
             </CardBody>
            
                </Card>
           
        </div>
      </>
    );
  }
}

export default Imprint;
