import React from "react";
import axios from 'axios';
import { Link } from "react-router-dom"
import { withRouter } from 'react-router-dom';
// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
// import 'react-google-places-autocomplete/dist/index.min.css';
import "../../style1.css";
import ReactPaginate from 'react-paginate';
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  //CardFooter,
  FormGroup,
  Input,
  Row,
  Col,
  Table,
  Button,
  Badge,
  UncontrolledTooltip,
  Modal,
  ModalTitle,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledCollapse
} from "reactstrap";


const distance = [
  { value: '5', label: '5 KM', id: "1" },
  { value: '10', label: '10 KM', id: "2" },
  { value: '25', label: '25 KM', id: "3" },
  { value: '50', label: '50 KM', id: "4" },
  { value: '75', label: '75 KM', id: "5" },
]
class InstitutionList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      company_name: '',
      person_name: '',
      allHospitals: [],
      filter: [],
      distanceSelect: null,
      area: '',
      offset: 0,
      perPage: 20,
      currentPage: 0,
      offset1: 0,
      perPage1: 20,
      currentPage1: 0,
      state1: true,
      state2: true,
      state3: false,
      isJobRunning:false,
    };
    this.toggle = this.toggle.bind(this);
  }
  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  changeHandler = e => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }
  componentWillMount(){
    localStorage.setItem('isJobRunning', JSON.stringify(this.state.isJobRunning));
  }
  componentWillUnmount() {
    clearTimeout(this.intervalID);
  }
  submitHandler = e => {
    e.preventDefault();
    //const { company_name, person_name} = this.state
    var err;
    var err1;
    var city;
    var errmsg;
    console.log(this.state)
    if (this.state.area === '' && this.state.distanceSelect !== null) {
      console.log("please select city")
      err = "Bitte wählen Sie die Stadt";
      this.setState({ err })
    } else {
      this.setState({ err: undefined })
    } if (this.state.distanceSelect === null && this.state.area === '' && this.state.person_name === '' && this.state.company_name === '') {
      console.log("All fields are Empty")
      err1 = "Alle Felder sind leer";
      this.setState({ err1 })
    } else {
      this.setState({ err1: undefined })
    }
    if (err !== "Bitte wählen Sie die Stadt" && err1 !== "Alle Felder sind leer") {
      console.log("everything is ok")
      if (this.state.area !== '') {
        var somestring = this.state.area;
        var index = somestring.indexOf(" ");
        var postcode = somestring.substr(0, index);
        console.log("first part", postcode)
        //this.setState({postcode});
        city = somestring.substr(index + 1);
        console.log("Second part city", city);
      } else {
        city = ''
      }
      console.log("everything is ok")
      axios
        .post('/api2/searchhospital', { ...this.state, city })
        .then(response => {
          console.log(response)
          const filter = response.data;
          this.setState({ filter });
          this.setState({ state1: false })
          localStorage.setItem('filter', JSON.stringify(this.state.filter));
          console.log(filter);
          this.makePostData1(this.state.filter);
          this.setState({ errmsg: undefined })
        })
        .catch(error => {
          console.log(error.response);

          errmsg = error.response.data.Message;
          console.log(errmsg)
          this.setState({ errmsg })
          this.setState({ state2: false })
          this.setState({ state1: false })
        })
    }
  }
  acceptHandle(hospid, e) {
    e.preventDefault();
    //const { company_name, person_name} = this.state
    console.log(hospid)
    axios
      .post('/api2/accepthospital', { ...this.state, hospid })
      .then(response => {
        console.log(response)
        const msg1 = "Sie akzeptieren die Anfrage";
        this.setState({ msg1 })
        this.intervalID = setTimeout(this.componentDidMount.bind(this), 100);
        //window.location.reload();
      })
      .catch(error => {
        console.log(error.response);
      })
  }
  rejectHandle(hospid, e) {
    e.preventDefault();
    //const { company_name, person_name} = this.state
    console.log(hospid)
    axios
      .post('/api2/rejecthospital1', { ...this.state, hospid })
      .then(response => {
        console.log(response);
        const msg1 = "Sie lehnen diese Anfrage ab";
        this.setState({ msg1 })
        this.intervalID = setTimeout(this.componentDidMount.bind(this), 100);
        //window.location.reload();
      })
      .catch(error => {
        console.log(error.response);
      })
  }
  deleteHandle = e => {
    e.preventDefault();
    //const { company_name, person_name} = this.state
    console.log(this.state.hospid)
    axios
      .post('/api2/deletehospital', this.state)
      .then(response => {
        console.log(response);
        const msg = "Sie lehnen diese Anfrage ab";
        this.setState({ msg })
        window.location.reload();
      })
      .catch(error => {
        console.log(error.response);
      })
  }

   handleStartClick = (hosp_id) => {
    axios.post('/api2/StartInvoiceSend',{hosp_id })
      .then(response => {
        console.log(response.data);
       // setIsJobRunning(true);
       this.intervalID = setTimeout(this.componentDidMount.bind(this), 1000);

      })
      .catch(error => console.error(error));
  };

   handleStopClick = (hosp_id) => {
    axios.post('/api2/StopInvoiceSend',{hosp_id})
      .then(response => {
        console.log(response.data);
        //setIsJobRunning(false);
        this.intervalID = setTimeout(this.componentDidMount.bind(this), 1000);
      })
      .catch(error => console.error(error));
  };

  componentDidMount() {
    console.log(this.state);
    axios.post('/api2/allhospitals', this.state)
      .then(response => {
        // console.log(response)
        const allHospitals = response.data.hospital;
        const allHospitalSub = response.data.hospitalSub;
        console.log(response.data.hospital)
        console.log(response.data.hospitalSub)
        const slice = allHospitals.slice(this.state.offset, this.state.offset + this.state.perPage)
        const postData = slice.map(item1 => <React.Fragment>

          <Card style={{ backgroundColor: '#f5f5f5' }} key={item1.hosp_id}>
            <CardBody>
              <Row style={{ height: '130px' }}>
                <Col md='2'>
                  {item1.hosp_logo === null || item1.hosp_logo === " " ? null : <img style={{ width: '100px', height: '100px' }}
                    alt="..."
                    className="avatar border-gray"
                    src={`https://unternehmen.hello-doctor.de/uploads/${item1.hosp_logo}`}
                  />}
                </Col>

                <Col md='5'><h7 className="text-left"   >
                  <Link to={`/admin/institution-details/${item1.hosp_id}`}>
                    {item1.hosp_name}
                  </Link>
                  <br></br>

                  <i class="fas fa-map-marker-alt"></i>&nbsp;
                  <h7 className="text-left" style={{ fontSize: '13px' }}>{item1.adress} {item1.haus_number}, {item1.zip_code}
                    &nbsp;{item1.city}<br></br>&nbsp;&nbsp;&nbsp;&nbsp;{item1.state}, {item1.country}</h7>
                  <br></br>

                </h7>
                </Col>
                <Col md='3'>
                  {/* <h7 className="text-left" style={{fontSize:'11px'}}><b>Registrierung:</b> {item1.created_date}</h7><br></br>
                <h7 className="text-left" style={{fontSize:'11px'}}><b>Profile aktualisiert:</b> {item1.last_update}</h7><br></br> 
                 <h7 className="text-left" style={{fontSize:'11px'}}><b>Eingeloggt zuletzt:</b> {item1.last_login_date}</h7><br></br> 
                {item1.status === 'aktiv' ? <h7 className="text-left" style={{ fontSize: '11px', color: '#28a745' }}><b>Konto Status:</b> {item1.status} </h7> :
              item1.status === "Unternehmen gelöscht" ? <><h7 className="text-left" style={{ fontSize: '11px', color: '#ff0800' }}><p>Konto Status: Konto wurde am {item1.account_del} vom {item1.status}</p>  </h7></> :
               <h7 className="text-left" style={{ fontSize: '11px', color: '#ff0800' }}><b>Konto Status:</b> {item1.status} </h7>   
            } */}
                </Col>
                <Col md='2' className="text-right" style={{ marginTop: '-30px', marginBottom: '-20px' }}>
                  <br></br>
                  <Button style={{ marginRight: '-3px' }}
                    className="btn-icon"
                    color="primary"
                    id={"tooltip366246639" + item1.hosp_id}
                    size="sm"
                    type="button"
                    onClick={this.acceptHandle.bind(this, item1.hosp_id)}

                  >
                    <i class="fa fa-unlock" aria-hidden="true"></i>
                  </Button>{" "}
                  <UncontrolledTooltip
                    delay={0}
                    target={"tooltip366246639" + item1.hosp_id}

                  >
                    Aktivieren
                  </UncontrolledTooltip>

                  <Button
                    className="btn-icon"
                    color="danger"
                    id={"tooltip366246640" + item1.hosp_id}
                    size="sm"
                    type="button"
                    onClick={this.rejectHandle.bind(this, item1.hosp_id)}
                  >
                    <i className="fa fa-toggle-off" />
                  </Button>{" "}
                  <UncontrolledTooltip
                    delay={0}
                    target={"tooltip366246640" + item1.hosp_id}
                  >
                    Deaktivieren
                  </UncontrolledTooltip>
                  <br></br>


                  {/* <Link to={`/admin/CreateJob/${item1.hosp_id}`}>
                    <Button
                      style={{ marginTop: '-5px' }}
                      className="btn-icon"
                      color="success"
                      id={"tooltip476609784" + item1.hosp_id}
                      size="sm"
                      type="button"
                    >
                      <i className="fa fa-plus-square" />
                    </Button>{" "}
                  </Link>
                  <UncontrolledTooltip
                    delay={0}
                    target={"tooltip476609784" + item1.hosp_id}
                  >
                    Job Anlegen
                  </UncontrolledTooltip> */}

                  <Button
                    style={{ marginTop: '-5px' }}
                    className="btn-icon"
                    color="danger"
                    id={"tooltip366246642" + item1.hosp_id}
                    size="sm"
                    type="button"
                    onClick={() => this.setState({ hospid: item1.hosp_id }, this.toggle)}
                  //onClick={this.deleteHandle.bind(this, item1.user_id)}
                  >
                    <i className="fa fa-trash" />
                  </Button>{" "}
                  <UncontrolledTooltip
                    delay={0}
                    target={"tooltip366246642" + item1.hosp_id}
                  >
                    Löschen
                  </UncontrolledTooltip>
                  

                  {item1.bill_status == 'True'
                    ? <>
                    <Button
                    style={{ marginTop: '-5px' }}
                    className="btn-icon"
                    color="danger"
                    id={"tooltip366246649" + item1.hosp_id}
                    size="sm"
                    type="button"
                    onClick={() => this.handleStopClick({ hospid: item1.hosp_id })}
                  //onClick={this.deleteHandle.bind(this, item1.user_id)}
                  >
                  <i class="fa fa-lock" aria-hidden="true"></i>
                  </Button>{" "}
                  <UncontrolledTooltip
                    delay={0}
                    target={"tooltip366246649" + item1.hosp_id}
                  >
                  Rechnungen Pausieren
                  </UncontrolledTooltip>
                  </>
                    : 
                    <>
                   <Button
                    style={{ marginTop: '-5px' }}
                    className="btn-icon"
                    color="success"
                    id={"tooltip366246623" + item1.hosp_id}
                    size="sm"
                    type="button"
                    onClick={() => this.handleStartClick({ hospid: item1.hosp_id })}
                  //onClick={this.deleteHandle.bind(this, item1.user_id)}
                  >
                  <i class="fa fa-unlock" aria-hidden="true"></i>
                  </Button>{" "}
                  <UncontrolledTooltip
                    delay={0}
                    target={"tooltip366246623" + item1.hosp_id}
                  >
                   
                   Rechnungen Starten
                  </UncontrolledTooltip>
                    </>
                  }

                  <center> <Link><i class="fas fa-chevron-circle-down" style={{ fontSize: '26px', color: '#4d72d0' }}
                    id={`toggler${item1.main_user_id}`}


                  ></i></Link>  </center>
                </Col>


              </Row>
              <div>




                <UncontrolledCollapse toggler={`toggler${item1.main_user_id}`}
                >

                  {allHospitalSub
                    .filter((hosp) => hosp.user_id === item1.main_user_id)
                    .map((hosp) => (
                      <Card style={{ backgroundColor: 'white' }} >
                        <CardBody>
                          <Row>
                            <Col md='2'>
                              {hosp.hosp_logo === null || hosp.hosp_logo === " " ? null : <img style={{ width: '100px', height: '100px' }}
                                alt="..."
                                className="avatar border-gray"
                                src={`https://unternehmen.hello-doctor.de/uploads/${hosp.hosp_logo}`}


                              />}
                            </Col>

                            <Col md='5'><h7 className="text-left"   >
                              <Link to={`/admin/institution-details/${hosp.hosp_id}`}>
                                {hosp.hosp_name}
                              </Link>
                              <br></br>

                              <i class="fas fa-map-marker-alt"></i>&nbsp;
                              <h7 className="text-left" style={{ fontSize: '13px' }}>{hosp.adress} {hosp.haus_number}, {hosp.zip_code}
                                &nbsp;{hosp.city}<br></br>&nbsp;&nbsp;&nbsp;&nbsp;{hosp.state}, {hosp.country}</h7>
                              <br></br>

                            </h7>
                            </Col>
                            <Col md='3'>
                              {/* <h7 className="text-left" style={{fontSize:'11px'}}><b>Registrierung:</b> {item1.created_date}</h7><br></br>
                          <h7 className="text-left" style={{fontSize:'11px'}}><b>Profile aktualisiert:</b> {item1.last_update}</h7><br></br> 
                           <h7 className="text-left" style={{fontSize:'11px'}}><b>Eingeloggt zuletzt:</b> {item1.last_login_date}</h7><br></br> 
                          {item1.status === 'aktiv' ? <h7 className="text-left" style={{ fontSize: '11px', color: '#28a745' }}><b>Konto Status:</b> {item1.status} </h7> :
                        item1.status === "Unternehmen gelöscht" ? <><h7 className="text-left" style={{ fontSize: '11px', color: '#ff0800' }}><p>Konto Status: Konto wurde am {item1.account_del} vom {item1.status}</p>  </h7></> :
                         <h7 className="text-left" style={{ fontSize: '11px', color: '#ff0800' }}><b>Konto Status:</b> {item1.status} </h7>   
                      } */}
                            </Col>
                            <Col md='2' className="text-right" style={{ marginTop: '-30px', marginBottom: '-20px' }}>
                              <br></br>
                              <Button style={{ marginRight: '-3px' }}
                                className="btn-icon"
                                color="primary"
                                id={"tooltip366246639" + hosp.hosp_id}
                                size="sm"
                                type="button"
                                onClick={this.acceptHandle.bind(this, hosp.hosp_id)}

                              >
                                <i class="fa fa-unlock" aria-hidden="true"></i>
                              </Button>{" "}
                              <UncontrolledTooltip
                                delay={0}
                                target={"tooltip366246639" + hosp.hosp_id}

                              >
                                Aktivieren
                              </UncontrolledTooltip>

                              <Button
                                className="btn-icon"
                                color="danger"
                                id={"tooltip366246640" + hosp.hosp_id}
                                size="sm"
                                type="button"
                                onClick={this.rejectHandle.bind(this, hosp.hosp_id)}
                              >
                                <i className="fa fa-toggle-off" />
                              </Button>{" "}
                              <UncontrolledTooltip
                                delay={0}
                                target={"tooltip366246640" + hosp.hosp_id}
                              >
                                Deaktivieren
                              </UncontrolledTooltip>
                              <br></br>


                              {/* <Link to={`/admin/CreateJob/${hosp.hosp_id}`}>
                                <Button
                                  style={{ marginTop: '-5px' }}
                                  className="btn-icon"
                                  color="success"
                                  id={"tooltip476609784" + hosp.hosp_id}
                                  size="sm"
                                  type="button"
                                >
                                  <i className="fa fa-plus-square" />
                                </Button>{" "}
                              </Link>
                              <UncontrolledTooltip
                                delay={0}
                                target={"tooltip476609784" + hosp.hosp_id}
                              >
                                Job Anlegen
                              </UncontrolledTooltip> */}

                              <Button
                                style={{ marginTop: '-5px' }}
                                className="btn-icon"
                                color="danger"
                                id={"tooltip366246642" + hosp.hosp_id}
                                size="sm"
                                type="button"
                                onClick={() => this.setState({ hospid: hosp.hosp_id }, this.toggle)}
                              //onClick={this.deleteHandle.bind(this, item1.user_id)}
                              >
                                <i className="fa fa-trash" />
                              </Button>{" "}
                              <UncontrolledTooltip
                                delay={0}
                                target={"tooltip366246642" + hosp.hosp_id}
                              >
                                Löschen
                              </UncontrolledTooltip>

                            </Col>


                          </Row>

                        </CardBody>

                      </Card>

                    ))}


                  {/* 
                  <br></br>
                  <h7><b>{item.f_name}</b> hat sich auf diese Stellenanzeige beworben.</h7>
                  <Card style={{ marginTop: '15px' }}>
                    <CardBody>
                      <Row>
                        <Col md='2'>
                          {item.logo === null || item.logo === " " ? null :
                            <img src={`https://unternehmen.hello-doctor.de/uploads/${item.logo}`} alt='' height='80' width='auto' />}
                        </Col>

                        <Col md='8'><h7 className="text-left"     >
                          <h7 style={{ color: '#4287f5', fontSize: '13px' }}><Link to={`/admin/kontaktpersondetails/${item.hosp_id}`} target="_blank" rel="noopener noreferrer">{item.company_name}</Link></h7>
                          <br></br>
                          <Link to={`/admin/job-profile/${item.job_id}/${item.app_id}`} target="_blank" rel="noopener noreferrer">{item.job_function} (m/w/d) {item.profession == 'undefined' ? null : item.profession}

                          </Link>
                          <br></br>
                          <i class="fas fa-map-marker-alt">

                          </i> <h7 className="text-left" style={{ fontSize: '13px' }}>{item.address} {item.haus_no},&nbsp;
                            {item.zip_code}  {item.city}, {item.state}</h7>
                        </h7>
                          <h5 className="text-left" >

                          </h5></Col>
                        <Col md='2' className="text-right" >
                          <label className="text-ubold">
                            <Badge className="mr-1" color="success">{item.hospital_status}</Badge><br></br>
                            <center><b>{item.applied_date}</b></center>
                          </label>
                        </Col>
                      </Row>
                      <br></br>
                      <Row style={{ backgroundColor: '#f5f5f5' }}>

                        <Col md="3">
                          <Time />&nbsp;&nbsp;
                          <h7 style={{ fontSize: '13px' }}>{item.job_worktime} {item.job_worktime1 !== null ? ',' + item.job_worktime1 : null}</h7>
                        </Col>
                        <Col md="3">
                          <i class="fas fa-hourglass-half"></i>
                          &nbsp;&nbsp;
                          <h7 style={{ fontSize: '13px' }}>{item.job_ternure} {item.job_ternure1 !== null ? ',' + item.job_ternure1 : null} </h7>
                        </Col>
                        <Col md="3">
                          <i class="far fa-calendar-check"></i>&nbsp;&nbsp;
                          <h7 style={{ fontSize: '13px' }}> Online seit: {item.created_date}</h7>
                        </Col>
                        <Col md="3">
                          <i class="far fa-calendar-alt"></i>  &nbsp;&nbsp;
                          <h7 style={{ fontSize: '13px', color: '#28a745' }}>Online bis: {item.valid_till} </h7>
                        </Col>
                      </Row>

                    </CardBody>

                  </Card>

 */}



                </UncontrolledCollapse>

              </div>

            </CardBody>

          </Card>

        </React.Fragment>)
        this.setState({ state3: true })
        localStorage.setItem('allHospitals', JSON.stringify(this.state.allHospitals));
        console.log(allHospitals);
        this.setState({
          pageCount: Math.ceil(allHospitals.length / this.state.perPage),

          postData
        })

        //state : {user : this.state.user}

        //this.setState({redirect : true})
      })
      .catch(error => {
        console.log(error.response);
        // const msg = error.response.data.Message;
        // this.setState({msg});
      })
  }
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState({
      currentPage: selectedPage,
      offset: offset
    }, () => {
      this.componentDidMount()
    });

  };
  collapsesToggle = collapse => {
    let openedCollapses = this.state.openedCollapses;
    if (openedCollapses.includes(collapse)) {
      this.setState({
        openedCollapses: openedCollapses.filter(item => item !== collapse)
      });
    } else {
      openedCollapses.push(collapse);
      this.setState({
        openedCollapses: openedCollapses
      });
    }
  };
  makePostData1(filter) {
    const slice1 = filter.slice(this.state.offset1, this.state.offset1 + this.state.perPage1)
    const postData1 = slice1.map(item1 => <React.Fragment>

      <Card style={{ backgroundColor: '#f5f5f5' }} >
        <CardBody><Row>
          <Col md='2'>
            {item1.hosp_logo === null || item1.hosp_logo === " " ? null : <img style={{ width: '100px', height: '100px' }}
              alt="..."
              className="avatar border-gray"
              src={`https://unternehmen.hello-doctor.de/uploads/${item1.hosp_logo}`}


            />}
          </Col>

          <Col md='5'><h7 className="text-left"   >
            <Link to={`/admin/institution-details/${item1.hosp_id}/${item1.username}`}>
              {item1.hosp_name}
            </Link>
            <br></br>

            <i class="fas fa-map-marker-alt"></i>&nbsp;
            <h7 className="text-left" style={{ fontSize: '13px' }}>{item1.adress} {item1.haus_number}, {item1.zip_code}
              &nbsp;{item1.city}<br></br>&nbsp;&nbsp;&nbsp;&nbsp;{item1.state}, {item1.country}</h7>
            <br></br>

          </h7>
          </Col>
          <Col md='3'>
            <h7 className="text-left" style={{ fontSize: '11px' }}><b>Registrierung:</b> {item1.created_date}</h7><br></br>
            {/* <h7 className="text-left" style={{fontSize:'11px'}}><b>Profile aktualisiert:</b> {item1.last_update}</h7><br></br> */}
            {/* <h7 className="text-left" style={{fontSize:'11px'}}><b>Eingeloggt zuletzt:</b> {item1.last_login_date}</h7><br></br> */}
            {item1.status === 'aktiv' ? <h7 className="text-left" style={{ fontSize: '11px', color: '#28a745' }}><b>Konto Status:</b> {item1.status} </h7> :
              item1.status === "unternehmen gelöscht" ? <><h7 className="text-left" style={{ fontSize: '11px', color: '#ff0800' }}><p>Konto Status: Konto wurde am {item1.account_del} vom {item1.status}</p>  </h7></> :
                <h7 className="text-left" style={{ fontSize: '11px', color: '#ff0800' }}><b>Konto Status:</b> {item1.status} </h7>
            }
          </Col>
          <Col md='2' className="text-right" style={{ marginTop: '-30px', marginBottom: '-20px' }}>
            <br></br>
            <Button style={{ marginRight: '-3px' }}
              className="btn-icon"
              color="primary"
              id={"tooltip366246639" + item1.hosp_id}
              size="sm"
              type="button"
              onClick={this.acceptHandle.bind(this, item1.user_id)}

            >
              <i class="fa fa-unlock" aria-hidden="true"></i>
            </Button>{" "}
            <UncontrolledTooltip
              delay={0}
              target={"tooltip366246639" + item1.hosp_id}

            >
              Aktivieren
            </UncontrolledTooltip>

            <Button
              className="btn-icon"
              color="danger"
              id={"tooltip366246640" + item1.hosp_id}
              size="sm"
              type="button"
              onClick={this.rejectHandle.bind(this, item1.hosp_id)}
            >
              <i className="fa fa-toggle-off" />
            </Button>{" "}
            <UncontrolledTooltip
              delay={0}
              target={"tooltip366246640" + item1.hosp_id}
            >
              Deaktivieren
            </UncontrolledTooltip>
            <br></br>

{/* 
            <Link to={`/admin/CreateJob/${item1.hosp_id}`}>
              <Button
                style={{ marginTop: '-5px' }}
                className="btn-icon"
                color="success"
                id={"tooltip476609784" + item1.hosp_id}
                size="sm"
                type="button"
              >
                <i className="fa fa-plus-square
" />
              </Button>{" "}
            </Link>
            <UncontrolledTooltip
              delay={0}
              target={"tooltip476609784" + item1.hosp_id}
            >
              Job Anlegen
            </UncontrolledTooltip> */}

            <Button
              style={{ marginTop: '-5px' }}
              className="btn-icon"
              color="danger"
              id={"tooltip366246642" + item1.hosp_id}
              size="sm"
              type="button"
              onClick={() => this.setState({ hospid: item1.hosp_id }, this.toggle)}
            >
              <i className="fa fa-trash" />
            </Button>{" "}
            <UncontrolledTooltip
              delay={0}
              target={"tooltip366246642" + item1.hosp_id}
            >
              Löschen
            </UncontrolledTooltip>

          </Col>


        </Row>

        </CardBody>

      </Card>
    </React.Fragment>)
    this.setState({ state2: true })
    this.setState({
      pageCount1: Math.ceil(filter.length / this.state.perPage1),
      postData1
    });
  }
  handlePageClick1 = (e) => {
    const selectedPage1 = e.selected;
    const offset1 = selectedPage1 * this.state.perPage1;

    this.setState({
      currentPage1: selectedPage1,
      offset1: offset1
    }, () => {
      this.makePostData1(this.state.filter);
    });

  };

  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <Row>
                    <Col md="6">
                      <h2>
                        Unternehmen
                      </h2>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="12">
                      <Row className="justify-content-center ml-auto mr-auto">
                        <Col md="8">
                          <Card>

                            <CardBody>
                              <CardTitle tag="h4" className="">Filter</CardTitle>
                              <Badge color="danger">{this.state.errmsg}</Badge>
                              <Badge color="danger">{this.state.msg}</Badge>
                              <Badge color="danger">{this.state.err1}</Badge>
                              <Row className="section-bottom-20 section-bottom-xs-10">
                                <Col md="6">
                                  <label>Unternehmensname</label>
                                  <FormGroup>
                                    <Input placeholder="" type="text" name="company_name" onChange={this.changeHandler} />
                                  </FormGroup>
                                </Col>
                                <Col md="6">
                                  <label>Kontakt</label>
                                  <FormGroup>
                                    <Input placeholder="" type="text" name="person_name" onChange={this.changeHandler} />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row className="section-bottom-20 section-bottom-xs-10">
                                <Col md="6">
                                  <label>Stadt*</label><Badge color="danger">{this.state.err}</Badge>
                                  <FormGroup>
                                    {/* <Input placeholder="" type="text" name = "company_name" onChange={this.changeHandler}/> */}
                                    <GooglePlacesAutocomplete
                                      onChange={this.changeHandler}
                                      onSelect={({ description }) => (this.setState({ area: description }))}
                                      autocompletionRequest={{
                                        componentRestrictions: {
                                          country: 'de'
                                        },
                                        types: ["(regions)"],
                                        terms: [
                                          'cities'
                                        ]
                                      }}
                                      placeholder='Wo? z.B. Berlin'
                                      inputClassName='form-control'

                                    />
                                  </FormGroup>
                                </Col>
                                <Col md='6'>
                                  <label>Umkreis?</label>
                                  <Select
                                    //isMulti
                                    //closeMenuOnSelect = {false}
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    name="titleSelect"
                                    value={this.state.distanceSelect}
                                    onChange={value =>
                                      this.setState({ distanceSelect: value })
                                    }
                                    options={distance}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col className="text-center">
                                  <Button
                                    className="btn-round center"
                                    size="md"
                                    color="primary"
                                    type="submit"
                                    onClick={this.submitHandler}
                                  >
                                    Suchen
                                  </Button>
                                </Col>
                              </Row>

                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                      <hr />
                      <Card>
                        <CardHeader>
                        </CardHeader>
                        <CardBody>

                          {this.state.state1 ? <>
                            {this.state.postData}
                          </>
                            :
                            <>{this.state.state2 ?
                              <> {this.state.postData1} </>
                              : null}</>
                          }
                          <br></br>
                          <br></br>
                          {this.state.state1 ?
                            <span>
                              {this.state.state3 ?
                                <ReactPaginate
                                  previousLabel={"<"}
                                  nextLabel={">"}
                                  breakLabel={"..."}
                                  breakClassName={"break-me"}
                                  pageCount={this.state.pageCount}
                                  marginPagesDisplayed={2}
                                  pageRangeDisplayed={5}
                                  onPageChange={this.handlePageClick}
                                  containerClassName={"pagination"}
                                  subContainerClassName={"pages pagination"}
                                  activeClassName={"active"} />
                                : null}</span>
                            :
                            <span> {this.state.state2 ? <ReactPaginate
                              previousLabel={"<"}
                              nextLabel={">"}
                              breakLabel={"..."}
                              breakClassName={"break-me"}
                              pageCount={this.state.pageCount1}
                              marginPagesDisplayed={2}
                              pageRangeDisplayed={5}
                              onPageChange={this.handlePageClick1}
                              containerClassName={"pagination"}
                              subContainerClassName={"pages pagination"}
                              activeClassName={"active"} /> : <Badge color="danger">No Unternehmen For this Result</Badge>}</span>
                          }
                          <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}
                          >
                            <ModalHeader toggle={this.toggle}></ModalHeader>
                            <ModalBody>
                              <h4 ><b>Unternehmen dauerhaft löschen</b></h4>
                              <Badge color="warning">{this.state.reply}</Badge><br />
                              Sind Sie sicher, dass Sie diesen Unternehmen löschen möchten?<br></br>
                              <br></br>
                              <center><Button
                                onClick={this.toggle}
                                style={{
                                  width: 'auto',
                                  height: '32px',
                                  fontSize: '9px',
                                  borderRadius: '32px',
                                  boxShadow: '4.5px 7.794px 13px 0px #afc1ec',
                                  fontWeight: '400',
                                  border: 'none !important',
                                  padding: '9px 27px',
                                  // background: '#dc3545',
                                  marginBottom: '1rem'
                                }}>Abbrechen</Button>
                                <Button
                                  color="primary"
                                  style={{
                                    width: 'auto',
                                    height: '32px',
                                    fontSize: '9px',
                                    borderRadius: '32px',
                                    boxShadow: '4.5px 7.794px 13px 0px #afc1ec',
                                    fontWeight: '400',
                                    border: 'none !important',
                                    padding: '10px 27px',
                                    background: '#dc3545'
                                  }}
                                  onClick={this.deleteHandle}
                                >

                                  Bestätigen </Button></center>

                            </ModalBody>
                          </Modal>
                        </CardBody>



                      </Card>

                    </Col>
                  </Row>
                  {/* <Message /> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default withRouter(InstitutionList);
