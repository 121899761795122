import React from "react";
import axios from 'axios';
import { Link } from "react-router-dom"
import "./style1.css";
import ReactPaginate from 'react-paginate';
import { jsPDF } from "jspdf";
import Time from "@material-ui/icons/Timer";
import html2pdf from "html2pdf.js";
import DOMPurify from "dompurify";
import * as ReactDOMServer from 'react-dom/server';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  Table,
  Input,
  Badge,
  UncontrolledCollapse
} from "reactstrap";
class Imprint extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      salutationSelect: null,
      titleSelect: null,
      hosp: [],
      offset: 0,
      perPage: 20,
      currentPage: 0,
      state1: false,
      hosp_sign: null,
      hosp_sign_city: null,
      hosp_sign_date: null,
      file: null,
      doc_title: '',
      value: ''
      //file : null
    };
  }


  async JsPdfGenerator(U1, U2, U3, U4, U5, K2, K3, K4, K5, K6, S1, S2, S3, C1) {
    var doc = new jsPDF();
    doc.setFont("times new roman", "normal");
    doc.setFontSize(10);
    doc.text("Gül GmbH, An der Trift 40, 66123 Saarbrücken, Deutschland", 20, 15);
    doc.text("AG Saarbrücken, HRB 100749, USt.IdNr: DE286297909, GF: Deniz Gül", 20, 20);
    doc.text("T: +49 (681) 68 63 53 74, E: kunden@guel.gmbh,  W: www.hello-doctor.de ", 20, 25);
    doc.addImage("/images/logo_color.png", "JPEG", 140, 5, 50, 20);
    doc.setFont("times bew roman", "bold");
    doc.setFontSize(15);
    doc.text("VERMITTLUNGSVEREINBARUNG", 105, 40, null, null, "center");
    doc.setFont("times new roman", "normal");
    doc.setFontSize(12);
    doc.text("Zwischen der Gül GmbH, vertreten durch den Geschäftsführer Deniz Gül, An der Trift 40,  ", 20, 55);
    doc.text("66123 Saarbrücken", 20, 60);
    doc.text("- nachfolgend: (Vermittlungsagentur) bzw. (VA) -", 20, 70);
    doc.text("und ", 20, 80);
    doc.text(`der Einrichtung ${U1}, ${U2} ${U3}, ${U4} ${U5}`, 20, 90);
    doc.text("- nachfolgend: (Vermittlungsnehmer) bzw. (VN) -", 20, 100);
    doc.text("wird nachfolgende Vereinbarung zum Zwecke der Vermittlung einer Festanstellung von ", 20, 115);
    doc.text(`${K2} ${K3}, ${K4}, ${K5} ${K6}`, 20, 120);
    doc.text("getroffen: ", 20, 125);
    doc.setFont("times bew roman", "bold");
    doc.setFontSize(12);
    doc.text("Präambel ", 20, 140);
    doc.setFont("times new roman", "normal");
    doc.setFontSize(12);
    doc.text("I) Die Vermittlungsagentur (VA) agiert auf dem Gebiet der Zusammenführung vom Personal im ", 20, 145);
    doc.text("Gesundheitswesen bzw. Vermittlungssuchenden (VS) und Interessenten bzw.  ", 20, 150);
    doc.text("Vermittlungsnehmern (VN) in Deutschland. Die  Vereinbarung gilt sowohl für einzelne  ", 20, 155);
    doc.text("natürliche Personen, als auch auf Personenmehrheiten und deren Mitglieder sowie auf   ", 20, 160);
    doc.text("juristische Personen und deren Leitungsorgane gleichermaßen.  ", 20, 165);
    doc.text("Die VA bemüht sich nach Erteilung eines entsprechenden Auftrags um die Vermittlung von ", 20, 175);
    doc.text("Anstellungen, gleich welcher fachlichen Ausrichtung sowie unabhängig davon, ob die zu  ", 20, 180);
    doc.text("vermittelnde Tätigkeit befristet, unbefristet, in Vollzeit oder Teilzeit oder auf sonstige Weise   ", 20, 185);
    doc.text("erfolgen soll.  ", 20, 190);

    doc.text("II) Vermittlungssuchende sind insbesondere: ", 20, 200);
    doc.text("-      Krankenpflege (Anästhesieschwester, Anästhesietechnische Assistentin (ATA), ", 20, 205);
    doc.text("       Fachkrankenschwester, Gesundheitspflegehelfer Krankenpflegehelfer, ", 20, 210);
    doc.text("       Gesundheitspfleger und Krankenpfleger, Hebamme, Heilerziehungspfleger,  ", 20, 215);
    doc.text("       Kinderkrankenschwester, OP-Schwester, Operationstechnische Assistentin (OTA), ", 20, 220);
    doc.text("       Pflegeassistent, Pflegedienstleitung, Stationsleitung, Study Nurse)", 20, 225);
    doc.text("-      Altenpflege (Altenpflegehelfer, Altenpfleger, Betreuungskraft, Fachaltenpfleger , ", 20, 235);
    doc.text("       Hauswirtschafterin, Pflegedienstleitung)", 20, 240);
    doc.text("-      Medizintechnik (Medizininformatiker, Medizinisch-technische ", 20, 250);
    doc.text("       Laboratoriumsassistentin (MTLA), Medizintechnik-Ingenieur, Medizintechniker, ", 20, 255);
    doc.text("       MTA Funktionsdiagnostik (MTAF), MTA Radiologie (MTRA), Zahntechniker)", 20, 260);
    doc.text("-      Praxispersonal (Medizinische Fachangestellte, Praxismanager, Zahnmedizinische ", 20, 270);
    doc.text("       Fachangestellte, Tiermedizinische Fachangestellte)", 20, 275);
    doc.text("-      Rettungsdienst (Rettungsassistent, Rettungshelfer, Rettungssanitäter)", 20, 285);

    doc.addPage("l");
    doc.setFont("times new roman", "normal");
    doc.setFontSize(12);
    doc.text("-      Therapeut (Ergotherapeut, Erzieher, Heilpraktiker, Kinderpfleger, Kunsttherapeut, ", 20, 20);
    doc.text("       Logopäde, Masseur/medizinischer Bademeister, Motopäde, Musiktherapeut, ", 20, 25);
    doc.text("       Orthoptist, Podologe, Physiotherapeut, Psychotherapeut, Psychologe, Sozialpädagoge)", 20, 30);
    doc.text("-      Pharmazie (Apotheker, Pharmakant, Pharmareferent, Pharmazeutisch-kaufmännische ", 20, 40);
    doc.text("       Angestellte (PKA), Pharmazeutisch-technische Assistentin (PTA) )", 20, 45);
    doc.text("-      u.ä. medizinische Stellungen und Tätigkeiten.", 20, 55);
    doc.text("III) Vermittlungsnehmer sind insbesondere:", 20, 65);
    doc.text("öffentliche und private: Krankenhäuser, Kliniken, Medizinische Versorgungszentren, Kur- und", 20, 70);
    doc.text("Rehabilitationsanstalten, Sanatorien, Heilanstalten, Pflegestätten, Praxen, ärztliche ", 20, 75);
    doc.text("Niederlassungen und ähnliche medizinische Einrichtungen.", 20, 80);
    // doc.text("IV) Als Festanstellungen gelten grundsätzlich alle sozialversicherungspflichtigen ", 20, 90);
    // doc.text("Beschäftigungsverhältnisse, unabhängig von deren zeitlichen Ausgestaltung und Umfang, ", 20, 95);
    // doc.text("auch Aushilfstätigkeiten und/oder geringfügige Tätigkeiten.", 20, 100);
    // doc.text("V) Die für Vermittlungssuchende und Vermittlungsnehmer verwandten Kurzformen „VS“ und ", 20, 110);
    // doc.text("„VN“ erstrecken sich im Rahmen der vorliegenden Vereinbarung sowie innerhalb der gemäß ", 20, 115);
    // doc.text("Ziffer 9 einbezogenen Allgemeinen Geschäftsbedingungen sowohl auf einzelne natürliche ", 20, 120);
    // doc.text("Personen, als auch auf Personenmehrheiten und deren Mitglieder sowie auf juristische ", 20, 125);
    // doc.text("Personen und deren Leitungsorgane gleichermaßen.", 20, 130);
    doc.setFontSize(12);
    doc.setFont("times bew roman", "bold");

    doc.text("1. Vertragsgegenstand", 20, 90);
    doc.setFont("times new roman", "normal");
    doc.text("Der VN erteilt der VA den Auftrag, im Sinne der zur Kenntnis genommenen Allgemeinen", 20, 95);
    doc.text("Geschäftsbedingungen der VA insoweit tätig zu werden, als dass sich die VA auftrags- bzw. ", 20, 100);
    doc.text("bestimmungsgemäß um die Suche passender VS bemüht.", 20, 105);
    doc.setFont("times bew roman", "bold");
    doc.setFontSize(12);

    doc.text("2. Dienstleistung ", 20, 115);
    doc.setFont("times new roman", "normal");
    doc.setFontSize(12);
    doc.text("Der VN erklärt sich ausdrücklich damit einverstanden, dass es sich bei der Tätigkeit der VA ", 20, 120);
    doc.text("um eine Dienstleistung handelt, d.h., dass die VA keinen Vermittlungserfolg schuldet, sondern ", 20, 125);
    doc.text("sich lediglich um die Unterbreitung von Vermittlungsvorschlägen auf Basis bestmöglicher ", 20, 130);
    doc.text("Übereinstimmung von Angebot des VN und Gesuch des VS bemüht. Ein Anspruch auf die ", 20, 135);
    doc.text("Unterbreitung von Vermittlungsvorschlägen besteht nicht.", 20, 140);

    doc.setFont("times bew roman", "bold");
    doc.setFontSize(12);
    doc.text("3. Vergütung", 20, 150);
    doc.setFont("times new roman", "normal");
    doc.setFontSize(12);
    doc.text("Im Falle des Zustandekommens eines rechtswirksamen eines Vertragsverhältnisses zwischen VN und ", 20, 155);
    doc.text("VS entsteht ein Vergütungsanspruch der VA gegenüber dem VN nach folgenden Maßgaben:", 20, 160);
    doc.text("3.1. Bei Zustandekommen einer unbefristeten Festanstellung oder einer auf mindestens ein Jahr ", 20, 170);
    doc.text("befristeten Anstellung entsteht ein Vergütungsanspruch der VA in Höhe 8000,- Euro zzgl. gesetzl. ", 20, 175);
    doc.text("MwSt. Bei einer kürzeren als der einjährigen Befristung der Anstellung verringert sich der ", 20, 180);
    doc.text("Vergütungsanspruch entsprechend.", 20, 185);
    doc.text("3.2 Der Vergütungsanspruch der VA ist mit Arbeitsbeginn  des VS sofort fällig und in Form eines ", 20, 195);
    doc.text("Einmalbetrages zahlbar.", 20, 200);

    doc.text("3.3 Der Vergütungsanspruch der VA besteht unabhängig von dem Fortbestand des zustande ", 20, 210);
    doc.text("gekommenen Vermittlungsverhältnisses. Im Falle einer Auflösung, Kündigung oder sonstigen ", 20, 215);
    doc.text("Beendigung der vermittelten Vertragsbeziehung innerhalb von 6 Monaten erklärt sich die VA jedoch ", 20, 220);
    doc.text("bereit, dem VN die gezahlte Vergütung anteilig nach eigenem Ermessen zu erstatten, sofern das ", 20, 225);
    doc.text("Vertragsverhältnis aus vom VS zu vertretenden Gründen beendet wird und der VN den VS nicht durch ", 20, 230);
    doc.text("ein vertragswidriges Verhalten zur Kündigung des VS veranlasst hat. Im Falle eines ", 20, 235);
    doc.text("Aufhebungsvertrages erhält der VN keine anteilige Rückerstattung. Die Höhe der anteiligen ", 20, 240);
    doc.text("Erstattung variiert je nach Dauer der vermittelten Vertragsbeziehung und beträgt, bezogen auf die ", 20, 245);
    doc.text("gezahlte Vergütung. Bei Beendigung in den ersten 3 Monaten beträgt die Erstattung 50 % der ", 20, 250);
    doc.text("ursprünglich vereinbarten Vergütung.", 20, 255);
    doc.addPage("l");
    doc.text("3.4 Ein Vergütungsanspruch der VA nach Maßgabe von Ziffer 3.2 entsteht auch, wenn und soweit ein ", 20, 20);
    doc.text("Vertragsverhältnis zwischen VN und VS innerhalb von 24 Monaten nach Beendigung der vorliegenden ", 20, 25);
    doc.text("Vereinbarung zustande kommen sollte, es sei denn, dass der VN nachweisen kann, dass die vertragliche ", 20, 30);
    doc.text("Beziehung ohne jegliches Zutun der VA zustande gekommen ist. Ziffer 3.3 findet in diesem Fall keine ", 20, 35);
    doc.text("Anwendung.", 20, 40);

    doc.setFont("times new roman", "bold");
    doc.setFontSize(12);
    doc.text("4. Eignung des VN / Haftung", 20, 50);
    doc.setFont("times new roman", "normal");
    doc.setFontSize(12);
    doc.text("4.1 Der VN ist für seine individuelle Eignung zur Entgegennahme der zu vermittelnden ", 20, 55);
    doc.text("Tätigkeit gegenüber dem VS in allen Belangen selbst verantwortlich. Die VA überprüft  ", 20, 60);
    doc.text("insoweit nicht die behördlichen, wirtschaftlichen, versicherungsrechtlichen, steuerrechtlichen  ", 20, 65);
    doc.text("o.ä. Voraussetzungen des VN.", 20, 70);
    doc.text("4.2 Die VA übernimmt keine Haftung für den Inhalt und die Richtigkeit jeglicher Angaben ", 20, 80);
    doc.text("des VN. ", 20, 85);
    doc.text("4.3 Der VN bestätigt im Rahmen der vorliegenden Vereinbarung ausdrücklich, dass eine ", 20, 95);
    doc.text("Überprüfung seiner entsprechenden Eignung keinesfalls Gegenstand des vorliegenden   ", 20, 100);
    doc.text("Vertrages ist. Insofern besteht auch keinerlei Auskunftspflicht auf Seiten des VN gegenüber ", 20, 105);
    doc.text("der VA.", 20, 110);
    doc.setFont("times new roman", "bold");
    doc.setFontSize(12);
    doc.text("5. Eignung des VS / Haftung", 20, 120);
    doc.setFont("times new roman", "normal");
    doc.setFontSize(12);
    doc.text("5.1 Die VA hat gegenüber dem VN keinerlei Überprüfungs- und ", 20, 125);
    doc.text("Nachforschungsverpflichtung hinsichtlich der jeweiligen Angaben des VS. Insbesondere ", 20, 130);
    doc.text("überprüft und garantiert die VA nicht das Vorliegen der zur Ausübung der zu vermittelnden ", 20, 135);
    doc.text("Tätigkeit persönlichen Voraussetzungen auf Seiten des VS.", 20, 140);
    doc.text("5.2 Die VA übernimmt keine Haftung für die Richtigkeit jeglicher Angaben des VS. ", 20, 150);
    doc.text("Insbesondere übernimmt die VA keine Haftung für die Echtheit und inhaltliche Richtigkeit ", 20, 155);
    doc.text("der von dem VS in Hinblick auf seine persönliche Eignung zur Verfügung gestellten ", 20, 160);
    doc.text("Urkunden und sonstigen Unterlagen.", 20, 165);
    doc.text("5.3 Der VN bestätigt im Rahmen der vorliegenden Vereinbarung ausdrücklich, dass eine ", 20, 175);
    doc.text("Überprüfung der persönlichen Eignung und der entsprechenden Voraussetzungen auf Seiten ", 20, 180);
    doc.text("des VS nicht Gegenstand der Beauftragung der VA ist.", 20, 185);

    doc.setFont("times new roman", "bold");
    doc.setFontSize(12);
    doc.text("6. Vertrags- und Vermittlungsabschluss", 20, 195);
    doc.setFont("times new roman", "normal");
    doc.setFontSize(12);
    doc.text("Der VN verpflichtet sich ausdrücklich, die VA unverzüglich und vollständig über den Abschluss eines ", 20, 200);
    doc.text("Vertrages mit einem von der VA vorgeschlagenen bzw. vermittelten VS in Kenntnis zu setzen. Dies ", 20, 205);
    doc.text("gilt auch, wenn und soweit eine vertragliche Beziehung zwischen dem VN und dem durch die VA ", 20, 210);
    doc.text("erstmals vermittelten VS innerhalb eines Zeitraumes von 24 Monaten nach Beendigung des Vertrages ", 20, 215);
    doc.text("i.S.d. Ziffer 8 zustande kommen sollte.", 20, 220);


    doc.setFont("times new roman", "bold");
    doc.setFontSize(12);
    doc.text("7. Störung / Beendigung / Nichtzustandekommen des Vermittlungserfolges", 20, 230);
    doc.setFont("times new roman", "normal");
    doc.setFontSize(12);
    doc.text("7.1 Für eine Störung oder Verletzung vertraglicher Pflichten in dem Vertragsverhältnis  ", 20, 235);
    doc.text("zwischen VS und VN übernimmt die VA keinerlei Haftung, ebenso wenig für eine Auflösung, ", 20, 240);
    doc.text("Kündigung oder sonstige Beendigung der vermittelten Vertragsbeziehung. Dieser", 20, 245);
    doc.text("Haftungsausschluss gilt auch für eventuelle Schäden und Aufwendungen, die dem VS und/oder ", 20, 250);
    doc.text("dem VN im Zusammenhang mit Vertragsstörungen oder der Vertragsbeendigung", 20, 255);
    doc.text("entstehen.", 20, 260);
    doc.addPage("l");
    doc.setFont("times new roman", "normal");
    doc.setFontSize(12);
    doc.text("7.2 Ebenso wenig übernimmt die VA die Haftung für ein Nichtzustandekommen eines ", 20, 20);
    doc.text("Vermittlungserfolges und der sich daraus eventuell zu Lasten des VS oder des VN ", 20, 25);
    doc.text("ergebenden Schäden und/oder nutzlosen Aufwendungen, es sei denn, das ", 20, 30);
    doc.text("Nichtzustandekommen des Vermittlungserfolges ist auf ein vorsätzliches oder grob ", 20, 35);
    doc.text("fahrlässiges Verhalten der VA zurückzuführen.", 20, 40);
    doc.setFont("times new roman", "bold");
    doc.setFontSize(12);
    doc.text("8. Beendigung des Vermittlungsvertrages", 20, 50);
    doc.setFont("times new roman", "normal");
    doc.setFontSize(12);
    doc.text("8.1 Der Vermittlungsvertrag endet spätestens mit dem Zustandekommen einer vertraglichen ", 20, 55);
    doc.text("Vereinbarung zwischen VS und VN und zwar unabhängig davon, wie diese Vereinbarung  ", 20, 60);
    doc.text("inhaltlich oder zeitlich ausgestaltet ist und auch unabhängig davon, ob die Vereinbarung ", 20, 65);
    doc.text("einer aufschiebenden oder auflösenden Bedingung oder Befristung unterworfen ", 20, 70);
    doc.text("ist.", 20, 75);
    doc.text("8.2 Zuvor können der VN wie auch die VA den Vermittlungsvertrag jederzeit unter ", 20, 85);
    doc.text("Einhaltung einer Frist von einem Monat zum Monatsende ordentlich kündigen. Die ", 20, 90);
    doc.text("beiderseitige Möglichkeit zur außerordentlichen Kündigung unter den entsprechenden ", 20, 95);
    doc.text("gesetzlichen Voraussetzungen bleibt unberührt. Eine Kündigung bedarf in jedem Fall der ", 20, 100);
    doc.text("Textform.", 20, 105);
    doc.text("8.3 Die Vertragsbeendigung durch Eintreten des Vermittlungserfolges oder Kündigung hat ", 20, 115);
    doc.text("keinerlei Einfluss auf einen in diesem Zusammenhang entstandenen oder noch entstehenden ", 20, 120);
    doc.text("Vergütungsanspruch der VA gegenüber dem VN. Dies gilt auch im Falle einer wirksamen ", 20, 125);
    doc.text("Schuld- bzw. Verpflichtungsübernahme durch den VS.", 20, 130);
    doc.setFont("times new roman", "bold");
    doc.setFontSize(12);
    doc.text("9. AGB", 20, 140);
    doc.setFont("times new roman", "normal");
    doc.setFontSize(12);
    doc.text("In die vorliegende Vermittlungsvereinbarung werden ergänzend die Allgemeinen ", 20, 145);
    doc.text("Geschäftsbedingungen der VA ausdrücklich einbezogen. Diese sind dem VN zur ", 20, 150);
    doc.text("Kenntnisnahme in ihrer aktuellen Fassung zur Verfügung gestellt worden und der VN ", 20, 155);
    doc.text("bestätigt sein Einverständnis mit deren Geltung durch seine Unterschrift unter diese ", 20, 160);
    doc.text("Vereinbarung.", 20, 165);

    doc.text(`Saarbrücken, ${C1}                                                                ${S1}, ${S2}`, 20, 185);
    doc.text("Ort, Datum                                                                                      Ort, Datum", 20, 195);
    doc.addImage("/sign.png", "JPEG", 20, 200, 40, 20);
    doc.addImage(`https://unternehmen.hello-doctor.de/uploads/${S3}`, "JPEG", 140, 200, 40, 20);
    doc.addImage("/stamp.png", "JPEG", 20, 220, 40, 20);

    doc.text("Unterschrift VA                                                                               Unterschrift VN", 20, 250);
    doc.text("                                                                                                                   Stand: 24.10.2022", 20, 285);
    doc.save("Vertrag.pdf");
  }
  componentWillMount() {
    const user_id = localStorage.getItem("user_id")
    //  console.log(user_id)
    this.setState({ user_id })
  }
  PDFGenerate =async (company_name, address, job_house, zip_code, hospital_city,
    f_name, l_name, applicant_house, post_code, city,
    hosp_sign_city, hosp_sign_date, hosp_sign, contract_date_hosp, contract_id) => {

      const toDataUrl = async function (url, callback) {
        //Convert to base64
        return new Promise((resolve, reject) => {
          var xhr = new XMLHttpRequest();
          xhr.onload = function () {
            var reader = new FileReader();
            reader.onloadend = function () {
              resolve(reader.result);
            };
            reader.readAsDataURL(xhr.response);
          };
          xhr.onerror = () => {
            reject({
              status: this.status,
              statusText: xhr.statusText,
            });
          };
          xhr.open("GET", url);
          xhr.responseType = "blob";
          xhr.send();
        });
      };
      let urlBase64 = await toDataUrl(hosp_sign);
   // console.log("first")
   //console.log(urlBase64)
    axios
      .post('/api2/GetEditorContractCompany_single_download', { ...this.state, contract_id })
      .then(response => {
        //console.log(response)
        const value = response.data.result[0].EditorDesc;
        // console.log(value)
        this.setState({ value })
        //this.setState({adduser_err:undefined})
        //   var adduser_succ = response.data.msg;
        //   this.setState({ adduser_succ })



        //  console.log(this.state.value)
        console.log("second")
        const Contract = ReactDOMServer.renderToString(<div style={{ fontFamily: 'Arial', color: "black" }}>
          <Row>
            <Col md="9">   <p style={{ fontFamily: 'Arial', fontSize: '12px', color: 'black', marginBottom: '30px' }}>
              Gül GmbH, An der Trift 40, 66123 Saarbrücken, Deutschland<br></br>
              AG Saarbrücken, HRB 100749, USt.IdNr: DE286297909, GF: Deniz Gül<br></br>
              T: +49 (681) 68 63 53 74, E: kunden@guel.gmbh, W: www.hello-doctor.de<br></br>
            </p></Col>
            <Col md="3">
              <img src="/images/logo_color.png" width="150" style={{ marginTop: '0px' }} />
            </Col>
          </Row>
          <b><center>VERMITTLUNGSVEREINBARUNG</center></b>


          <br></br>
          Zwischen der Gül GmbH, vertreten durch den Geschäftsführer Deniz Gül, An der Trift 40, 66123 Saarbrücken <br></br>
          <br></br>
          - nachfolgend: „Vermittlungsagentur“ bzw. „VA“ -
          <br></br>
          <br></br>
          und
          <br></br>
          <br></br>
          der Einrichtung <b>{company_name}, {address} {job_house}, {zip_code} {hospital_city}.</b>
          <br>
          </br> <br></br>- nachfolgend: „Vermittlungsnehmer“ bzw. „VN“ -
          <br></br>
          <br></br>
          <br></br>
          wird nachfolgende Vereinbarung zum Zwecke der Vermittlung einer Festanstellung von  <b>
            {/* {this.state.salutation}  */}
            {f_name} {l_name}, {applicant_house}, {post_code} {city}</b> getroffen:
          <br></br> <br></br>
          <div
            style={{ fontFamily: 'times new roman', color: "black" }}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(value),
            }}
          >
          </div>
          <Row>
            <Col md='8'><b style={{ fontSize: '14px' }}>Saarbrücken, {contract_date_hosp}</b>
            </Col>
            <Col md='4'><b style={{ fontSize: '14px' }}>{hosp_sign_city}, {hosp_sign_date}</b>
            </Col>
          </Row>
          <Row>
            <Col md='8'> <u style={{ fontSize: '14px' }}>Ort, Datum</u>	</Col>
            <Col md='4'> <u style={{ fontSize: '14px' }}>Ort, Datum</u>	</Col>

          </Row>
          <Row>
            <Col md="8"> <img src="/sign.png" width="150" /></Col>
            <Col md="4"> <img src={urlBase64} width="150" /></Col>
          </Row>
          <Row>
            <Col md="8"><img src="/stamp.png" width="150" /></Col>
          </Row>
          {/* <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
        <img src="/sign.png" width="150" />
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
        <img src="/stamp.png" width="150" />
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <img src="/sign.png" width="150" />
      </div> */}
          <Row>
            <Col md='8'> <u style={{ fontSize: '14px' }}>Unterschrift VA</u>	</Col>
            <Col md='4'> <u style={{ fontSize: '14px' }}>Unterschrift VN</u>	</Col>

          </Row>
        </div>)


        var opt = {
          margin: 0.5,
          filename: f_name + '_vertrag.pdf',
          image: { type: 'jpeg', quality: 0.98 },
          html2canvas: { scale: 2 },
          jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
          pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }
        };
        //html2pdf(element);
        html2pdf().set(opt).from(Contract).save();

        // var doc = new jsPDF("p", "pt", "a4");
        // doc.html(document.querySelector("#print_element"), {
        //   callback: function (pdf) {
        //     pdf.save("mypdf.pdf");
        //   }
        // })
      })
      .catch(error => {
        console.log(error.response);
        //   this.setState({adduser_succ:undefined})
        //   var adduser_err = error.response.data.msg
        //   this.setState({ adduser_err })
      })
  }


  componentDidMount() {
    axios
      .get('/api2/hospitalcontract')
      .then(response => {
       // console.log(response)
        const hosp = response.data;
        const slice = hosp.slice(this.state.offset, this.state.offset + this.state.perPage)
        const postData = slice.map(item => <React.Fragment>
          <Card style={{ backgroundColor: '#f5f5f5' }} key={item.job_id}>

            <CardBody>
              <Row>
                <Col md='2'>
                  {item.picture === null || item.picture === " " ? null : <img style={{ width: '100px', height: '100px' }}
                    alt="..."
                    className="avatar border-gray"
                    src={`https://hello-doctor.de/uploads/${item.picture}`}


                  />}

                </Col>

                <Col md='4'><h7 className="text-left"   >

                  <Link to={`/admin/detail-kandidate/${item.app_id}`} target="_blank" rel="noopener noreferrer"> 
                  {/* {item.salutation}  */}
                  {item.f_name} {item.l_name}
                  </Link>
                  <br></br>
                  <i class="fas fa-map-marker-alt">

                  </i> <h7 className="text-left" style={{ fontSize: '13px' }}>{item.applicant_house} , {item.post_code} {item.applicant_city}, &nbsp; {item.country}</h7>
                </h7>
                  <br></br>
                  <h7 className="text-left" >
                    <Input
                      type="file"
                      size='sm'
                      name="file"
                      onChange={this.fileHandler}
                    >
                    </Input>

                    <Button
                      size='sm'

                      type="submit"

                      color="primary"
                      onClick={this.deleteHandle.bind(this, item.applyjob_id)}
                    >
                      hochladen
                    </Button>
                  </h7>


                </Col>
                <Col md='4'>
                  <h7 className="text-left">{item.hosp_contract}<br />
                    {item.contract_date_hosp}</h7>    </Col>
                <Col md='2'>
                  <td className="text-left" style={{ fontSize: '12px' }}>
                    {item.hosp_contract === 'Vertrag erstellt für Unternehmen' ?
                      <Button size="md" disabled >
                        Vertrag Erstellen
                      </Button>
                      :
                      <span>
                        {item.contract !== null ? <Button size='sm' > <a href={`/uploads/${item.contract}`} download style={{ color: '#F8F8FF' }}>
                          {item.contract}
                        </a>
                        </Button> : <span>{item.hosp_sign === null ? <Link to={`/admin/ContractPage/${item.applyjob_id}`} >

                          <Button
                            color="success"
                            size="md"
                          >
                            Vertrag Erstellen
                          </Button>
                        {/* </Link> : <Button size='sm' color="primary" onClick={() => this.JsPdfGenerator(
                          item.company_name, item.address, item.haus_no, item.zip_code, item.city,
                           item.f_name, item.l_name, item.applicant_house, item.post_code, item.applicant_city,
                          item.hosp_sign_city, item.hosp_sign_date, item.hosp_sign, item.contract_date_hosp
                        )}>Vertrag Herunterladen</Button>}</span>} */}
                                  </Link> : <Button size='sm' color="primary" onClick={() => this.PDFGenerate(
                          item.company_name, item.address, item.haus_no, item.zip_code, item.city,
                           item.f_name, item.l_name, item.applicant_house, item.post_code, item.applicant_city,
                          item.hosp_sign_city, item.hosp_sign_date, `https://unternehmen.hello-doctor.de/uploads/${item.hosp_sign}`, item.contract_date_hosp,item.contract_id
                        )}>Vertrag Herunterladen</Button>}</span>}
                        <br></br>
                        <br></br>
                      </span>
                    }

                    <center> <Link><i class="fas fa-chevron-circle-down" style={{ fontSize: '26px', color: '#4d72d0' }} id={`toggler${item.job_id}` + `${item.app_id}`}

                    ></i></Link>  </center> </td>
                </Col>
              </Row>

              <Row style={{ backgroundColor: '#ffffff' }}>



                <Col md="6">

                  {item.commision_date === null ? null :
                    <> <i class="far fa-calendar-check"></i> &nbsp;&nbsp;   <h7 style={{ fontSize: '13px', color: '#336699' }}>  Provision erhalten : {item.commision_date} </h7> </>}
                </Col>
                <Col md="6" className="text-right">
                  {item.joining_date === null ? null : <>  {item.remaining_months === 6 || item.remaining_months === 5 || item.remaining_months === 4 ?
                    <h7 style={{ fontSize: '13px', color: '#28a745' }}> <i class="far fa-calendar-alt"></i>  &nbsp;&nbsp; Arbeitsbeginn : {item.joining_date}</h7>
                    : item.remaining_months === 3 ? <h7 style={{ fontSize: '13px', color: '#FF6600' }}> <i class="far fa-calendar-alt"></i>  &nbsp;&nbsp; Arbeitsbeginn : {item.joining_date}</h7>
                      : item.remaining_months === 2 ? <h7 style={{ fontSize: '13px', color: '#FFCC00' }}><i class="far fa-calendar-alt"></i>  &nbsp;&nbsp; Arbeitsbeginn : {item.joining_date}</h7> :
                        item.remaining_months === 1 ? <h7 style={{ fontSize: '13px', color: '#336699' }}><i class="far fa-calendar-alt"></i>  &nbsp;&nbsp; Arbeitsbeginn : {item.joining_date}</h7> :
                          item.remaining_months === 0 ? <h7 style={{ fontSize: '13px', color: '#336699' }}><i class="far fa-calendar-alt"></i>  &nbsp;&nbsp; Arbeitsbeginn : {item.joining_date}</h7> :
                            <h7 style={{ fontSize: '13px', color: '#28a745' }}><i class="far fa-calendar-alt"></i>  &nbsp;&nbsp; Arbeitsbeginn : {item.joining_date}</h7>
                  } </>}
                </Col>
              </Row>

              <div>




                <UncontrolledCollapse toggler={`#toggler${item.job_id}` + `${item.app_id}`}
                >

                  <br></br>
                  <h7><b>{item.f_name}</b> hat sich auf diese Stellenanzeige beworben.</h7>
                  <Card style={{ marginTop: '15px' }}>
                    <CardBody>
                      <Row>
                        <Col md='2'>
                          {item.logo === null || item.logo === " " ? null :
                            <img src={`https://unternehmen.hello-doctor.de/uploads/${item.logo}`} alt='' height='80' width='auto' />}
                        </Col>

                        <Col md='8'><h7 className="text-left"     >
                          <h7 style={{ color: '#4287f5', fontSize: '13px' }}><Link to={`/admin/kontaktpersondetails/${item.hosp_id}`} target="_blank" rel="noopener noreferrer">{item.company_name}</Link></h7>
                          <br></br>
                          <Link to={`/admin/job-profile/${item.job_id}/${item.app_id}`} target="_blank" rel="noopener noreferrer">{item.job_function} (m/w/d) {item.profession == 'undefined' ? null : item.profession}

                          </Link>
                          <br></br>
                          <i class="fas fa-map-marker-alt">

                          </i> <h7 className="text-left" style={{ fontSize: '13px' }}>{item.address} {item.haus_no},&nbsp;
                            {item.zip_code}  {item.city}, {item.state}</h7>
                        </h7>
                          <h5 className="text-left" >

                          </h5></Col>
                        <Col md='2' className="text-right" >
                          <label className="text-ubold">
                            <Badge className="mr-1" color="success">{item.hospital_status}</Badge><br></br>
                            <center><b>{item.applied_date}</b></center>
                          </label>
                        </Col>
                      </Row>
                      <br></br>
                      <Row style={{ backgroundColor: '#f5f5f5' }}>

                        <Col md="3">
                          <Time />&nbsp;&nbsp;
                          <h7 style={{ fontSize: '13px' }}>{item.job_worktime} {item.job_worktime1 !== null ? ',' + item.job_worktime1 : null}</h7>
                        </Col>
                        <Col md="3">
                          <i class="fas fa-hourglass-half"></i>
                          &nbsp;&nbsp;
                          <h7 style={{ fontSize: '13px' }}>{item.job_ternure} {item.job_ternure1 !== null ? ',' + item.job_ternure1 : null} </h7>
                        </Col>
                        <Col md="3">
                          <i class="far fa-calendar-check"></i>&nbsp;&nbsp;
                          <h7 style={{ fontSize: '13px' }}> Online seit: {item.created_date}</h7>
                        </Col>
                        <Col md="3">
                          <i class="far fa-calendar-alt"></i>  &nbsp;&nbsp;
                          <h7 style={{ fontSize: '13px', color: '#28a745' }}>Online bis: {item.valid_till} </h7>
                        </Col>
                      </Row>

                    </CardBody>

                  </Card>





                </UncontrolledCollapse>
              </div>
            </CardBody>
          </Card>

          {/* <tr>
                             <td>
                             <label className="text-ubold">
                           {item.hospital_status}<br></br>
                           {item.applied_date}
                                    </label>
                            </td> 
                            <td className="text-left">
                           
                            <label className="text-ubold">
                         
                            <Link to = {`/admin/kontaktpersondetails/${item.hosp_id}`} target="_blank" rel="noopener noreferrer"> 
                            <b>{item.company_name}  </b> <br></br>
                        {item.address} {item.haus_no}, {item.zip_code}<br></br>
                        {item.city}
                          </Link>
 
                                     </label>
                        </td>
                        <td className="text-left">
                        <label className="text-ubold">
                        <Link to = {`/admin/job-profile/${item.job_id}/${item.app_id}`} target="_blank" rel="noopener noreferrer">    {item.job_field} <br></br> 
                       {item.job_field} {item.job_function} <br></br> {item.profession} </Link>   
                                   </label>
                                    </td>

                                    <td className="text-left">
                                    <label className="text-ubold">
                                    <Link to = {`/admin/detail-kandidate/${item.app_id}`} target="_blank" rel="noopener noreferrer"> 
                                    {item.andre} {item.f_name} {item.l_name} <br></br>
                                    {item.applicant_house}, {item.post_code}<br></br>
                                    {item.applicant_city} <br></br>
                                    {item.applicant_contact}</Link>     
                                  </label>
                        </td>
                        <Badge className="mr-1" color="danger" pill>{this.state.msg}</Badge>
                        <td>
                         
                       
                        
                         <Input 
                      type = "file"
                      // className="btn-round mb-3"
                      size = 'sm'
                      // color="primary"
                      name = "file"
                      onChange={this.fileHandler}  
                    >
                    </Input>
                    <br></br>
                    <Button 
                      size = 'sm'
                      // className="cstm-btn-card-4"
                      type = "submit"
                      // className="btn-round mb-3"
                      color="primary"
                      onClick = {this.deleteHandle.bind(this, item.applyjob_id)}
                      >
                      hochladen                     
                      </Button>    

                        </td>
                        <td>

                        <td>{item.hosp_contract}<br />
                        {item.contract_date_hosp}</td>
                        </td>
                        <td className="text-right">
                        {item.hosp_contract === 'Vertrag erstellt für Unternehmen' ?
                        <Button  size = "md" disabled >
                          Vertrag Erstellen
                        </Button>
                        :
                        <span>
                          {item.contract !== null ? <Button size = 'sm' > <a href = {`/uploads/${item.contract}`} download style={{color: '#F8F8FF'}}>
                         {item.contract}
                            </a>
                        </Button>:  <span>{item.hosp_sign === null ? <Link to = {`/admin/ContractPage/${item.applyjob_id}`} >
                        
                        <Button  
                            color="success"
                            size = "md"
                          >   
                          Vertrag Erstellen
                          </Button>
                         </Link> : <Button size = 'sm' color = "primary"  onClick={ () => this.JsPdfGenerator(
                             item.company_name,item.address,item.haus_no,item.zip_code,item.city,
                             item.andre,item.f_name,item.l_name,item.applicant_house,item.post_code,item.city,
                             item.hosp_sign_city,item.hosp_sign_date,item.hosp_sign,item.contract_date_hosp
                         )}>Vertrag Herunterladen</Button>}</span> }
                         <br></br>
                         <br></br>
                        </span>
                      } 
          </td>
          <td>{item.joining_date === null && item.commision_date === null ? null :
            <><small>Commision date : {item.commision_date}</small><br></br>
              {item.remaining_months === 6 || item.remaining_months === 5 || item.remaining_months === 4 ? <p style={{ fontSize: '13px', color: '#ff0000' }}>Joining date : {item.joining_date}</p>
                 :item.remaining_months === 3 ? <p style={{fontSize: '13px', color : '#FF6600'}}>Joining date : {item.joining_date}</p>
                    : item.remaining_months === 2 ? <p style={{ fontSize: '13px', color: '#FFCC00' }}>Joining date : {item.joining_date}</p> :
                      item.remaining_months === 1 ? <p style={{ fontSize: '13px', color: '#336699' }}>Joining date : {item.joining_date}</p> :
                        item.remaining_months === 0 ? <p style={{ fontSize: '13px', color: '#336699' }}>Joining date : {item.joining_date}</p> :
                   <p style={{fontSize: '13px', color : '#28a745'}}>Joining date : {item.joining_date}</p>
              }</>
          }</td>
                        </tr>
      */}
        </React.Fragment>)
        localStorage.setItem('hosp', JSON.stringify(this.state.hosp));
       // console.log(hosp);
       // console.log(hosp.length)
        this.setState({ state1: true })
        this.setState({
          pageCount: Math.ceil(hosp.length / this.state.perPage),
          postData
        })
      //  const hospContract = response.data[0].hosp_contract;
      //  this.setState({ hospContract })
       // console.log(hospContract);

      })
      .catch(error => {
        console.log(error);
      })
  }
  changeHandler = e => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }
  fileHandler = e => {
    this.setState({
      file: e.target.files[0],
      loaded: 0,
      //[e.target.name]: e.target.value 
    })
  }
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;

    this.setState({
      currentPage: selectedPage,
      offset: offset
    }, () => {
      this.componentDidMount()
    });

  };
  deleteHandle(applyjob_id, e) {
    //alert(id);
    e.preventDefault();
    //const { file, doc_title} = this.state
    if (this.state.file === null) {
      const msg = "Bitte wählen Sie zuerst die Datei aus";
      this.setState({ msg })
      console.log("File err")
    } else {
      console.log(this.state);
      console.log(applyjob_id);
      this.setState({ applyjob_id });
      const data = new FormData()
      console.log(applyjob_id, this.state.doc_title, this.state.file)
      data.set('doc_title', this.state.doc_title)
      data.set('applyjob_id', applyjob_id)
      data.append('file', this.state.file)
      console.log(data)
      console.log(this.state)
      axios.post("/api2/uploaddoc", data)
        .then(response => { // then print response status
          // const msg = res.data.Message;
          // this.setState({msg});
          console.log(response)
          const msg1 = response.data.message;
          this.setState({ msg1 })
          window.location.reload();
        })
        .catch(error => {
          console.log(error);
        })
    }
  }
  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h5 className="cstm-heading-backend"><b>Unternehmen Vertrag</b></h5>
                  <Badge className="mr-1" color="danger" pill>{this.state.msg}</Badge>
                  <hr></hr>
                </CardHeader>
                <CardBody>



                  {this.state.postData}

                  <br></br>
                  {this.state.state1 ? <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={this.state.pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={this.handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"} /> : null}

                </CardBody>
              </Card>


            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Imprint;
