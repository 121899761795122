import React from "react";
import { Link } from "react-router-dom"
// import Tooltip from '@material-ui/core/Tooltip';
// import Fade from '@material-ui/core/Fade';
// reactstrap components
import {
  Button,
  UncontrolledTooltip,
} from "reactstrap";
// import { Link } from "@material-ui/core";

const TableContactLists = ({item}) => {
    return (
      <>
                        <td className="text-left">
                          <div className="avatar text-right">
                            <img
                              alt="..."
                              className="img-circle img-no-padding img-responsive"
                              src={require("assets/img/faces/joe-gardner-2.jpg")}
                            />
                        <br/>
                            </div>
                            {item.institution.map((i) => {
                              return (
                                <>
                                {i.label} <br/>
                                </>
                              )
                            })}
                        </td>
                        <td className="text-left">
                        {item.title} {item.firstName} {item.lastName}
                        </td>
                        <td className="text-left">
                          {item.email}
                          <br/>
                          <small>
                              <i className="nc-icon nc-mobile" /> :{" "} {item.telefon}
                              </small>
                        </td>
                        <td className="text-left">
                        {item.status === "aktiv" ? "Registrierung bestätigt" : "Registrierung nicht bestätigt"}
                        </td>
                        <td className="text-right">
                          <Link to={"/admin-message/message"}>
                          <Button
                            className="btn-icon"
                            color="success"
                            id="tooltip366246641"
                            size="sm"
                            type="button"
                          >
                            <i className="fa fa-edit" />
                          </Button>{" "}
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip366246641"
                          >
                            Nachricht
                          </UncontrolledTooltip>
                          </Link>
                          <Link to={"/admin/institution-details"}>
                          <Button
                            className="btn-icon"
                            color="info"
                            id="tooltip476609784"
                            size="sm"
                            type="button"
                          >
                            <i className="fa fa-search" />
                          </Button>{" "}
                          </Link>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip476609784"
                          >
                            Details
                          </UncontrolledTooltip>
                        </td>
      </>
    );
}

export default TableContactLists;
