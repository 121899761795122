import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, Card,CardHeader,CardBody, Button, FormGroup, Label, Input, Form, FormFeedback, Col, Row, Spinner } from 'reactstrap';
import axios from 'axios';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'; //
import './style.css'
import Select from 'react-select';
import { skills } from './Skills_data';
import { toast , ToastContainer} from 'react-toastify';

const EditKollegeModel = (props) => {
  const [contactInfo, setContactInfo] = useState({
    email: '',
    firstName: '',
    lastName: '',
    position: '',
    telephone: '',
    assignedCompanies: [],
    assignedFachgebiete: [],
  });
  const user_id = props.match.params.user_id;
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [allHospitals, setAllHospitals] = useState([]);
  const [KollegeData, setKollegeData] = useState([]);

  //console.log(getKollegeData)

  const fetchUser = async () => {
    // API call to fetch hospitals
    // const user_id = JSON.parse(localStorage.getItem("user_id"));
    //console.log(user_id)
    var getKollegeData;
    try {
      const kollegenDetailsResponse = await axios.post("/api2/KollegenDetails", {
        user_id: user_id,
      });
      //  console.log(kollegenDetailsResponse)
      setKollegeData(kollegenDetailsResponse.data.kollegeDetails);

      getKollegeData = kollegenDetailsResponse.data.kollegeDetails;
      // console.log(getKollegeData)

      const assignedCompanies = (getKollegeData.hospitalAssignments).map(company => ({
        value: company.hosp_id,
        label: company.hosp_name
      }));

      const assignedFachgebiete = (getKollegeData.berufsgruppe).map(fachgebiet => ({
        value: fachgebiet.beruf_id,
        label: fachgebiet.beruf_name
      }));
      setContactInfo(prevState => ({
        ...prevState,
        firstName: getKollegeData.f_name || '',
        lastName: getKollegeData.l_name || '',
        email: getKollegeData.username || '',
        telephone: getKollegeData.telephone || '',
        position: getKollegeData.position || '',
        // Assuming getKollegeData provides arrays for assigned companies and fachgebiete
        assignedCompanies,
        assignedFachgebiete,
      }));

      // API call to fetch hospitals
      //const user_id = JSON.parse(localStorage.getItem("user_id"));



    } catch (error) {
      console.error("Failed to fetch hospitals:", error);
    }

    try {
      console.log(getKollegeData.Admin_id)
      const Companyresponse = await axios.post("/api2/getAllHospital", {
        user_id: getKollegeData.Admin_id,
      });
      setAllHospitals(Companyresponse.data.hospital);
      console.log(Companyresponse)

    } catch (error) {
      console.error("Failed to fetch hospitals:", error);
    }

  };



  useEffect(() => {

    fetchUser()
    //fetchHospitals();


    // if (isOpen) {
    //   fetchHospitals();
    // }
    // if (isOpen && getKollegeData) {

    // }
    // if (!isOpen) {
    //   setContactInfo({
    //     email: '',
    //     firstName: '',
    //     lastName: '',
    //     position: '',
    //     telephone: '',
    //     assignedCompanies: [],
    //     assignedFachgebiete: [],
    //   })
    //   setErrors({})
    //   setAllHospitals([])
    // }
  }, []);


  const validationRules = {
    email: value => value ? null : 'E-mail ist erforderlich.',
    position: value => value ? null : 'Position ist erforderlich.',
    firstName: value => value ? null : 'Vorname ist erforderlich.',
    lastName: value => value ? null : 'Nachname ist erforderlich.',
    telephone: value => value ? null : 'Telefon ist erforderlich.',
    assignedCompanies: value => value.length ? null : 'Zugewiesene Unternehmen ist erforderlich.',
    assignedFachgebiete: value => value.length ? null : 'Zugewiesene Fachgebiete ist erforderlich.',
  };

  const validateForm = () => {
    const newErrors = {};

    // Check each field against its validation rule
    Object.keys(contactInfo).forEach(key => {
      const validation = validationRules[key];
      const value = contactInfo[key];
      const error = validation ? validation(value) : null;

      // If there's an error, set it in the errors object
      if (error) {
        newErrors[key] = error;
      }
    });

    // Update state with the new errors
    setErrors(newErrors);

    // Return true if there are no errors, false otherwise
    return Object.keys(newErrors).length === 0;
  };

  // Add "Select All" option
  const selectAllOption = {
    value: 'selectAll',
    label: 'Alle auswählen'
  };

  const handleMultiSelectChange = (selectedOption, actionMeta) => {
    if (selectedOption.find(option => option.value === 'selectAll')) {
      if (actionMeta.name === 'assignedCompanies') {
        setContactInfo(prevState => ({
          ...prevState,
          [actionMeta.name]: allHospitals.map(hospital => ({ value: hospital.hosp_id, label: hospital.hosp_name }))
        }));
      } else if (actionMeta.name === 'assignedFachgebiete') {
        setContactInfo(prevState => ({
          ...prevState,
          [actionMeta.name]: skills
        }));
      }
    } else {
      setContactInfo(prevState => ({
        ...prevState,
        [actionMeta.name]: selectedOption
      }));
    }
    // Clear the validation error for the updated field
    setErrors(prevErrors => ({
      ...prevErrors,
      [actionMeta.name]: '' // Clear the error message for this field
    }));
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setContactInfo(prevState => ({ ...prevState, [name]: value }));
    setErrors({ ...errors, [name]: '' });

  };


  const handlePhoneChange = (value) => {
    setContactInfo({ ...contactInfo, telephone: value });
    // Optionally clear errors for the telephone field
    setErrors({ ...errors, telephone: '' });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      console.log('Validation failed');
      console.log(errors)
      toast.error('Bitte korrigieren Sie die Fehler, bevor Sie speichern.');
      return;
    }

    setLoading(true);
    //const Admin_user_id = JSON.parse(localStorage.getItem("user_id"));

    const submissionData = {
      // ...getKollegeData,
      // "user_id": user_id,
      "Admin_user_id": user_id,
      "f_name": contactInfo.firstName,
      "l_name": contactInfo.lastName,
      "position": contactInfo.position,
      "username": contactInfo.email,
      "telephone": contactInfo.telephone,
      "berufsgruppe": contactInfo.assignedFachgebiete,
      "hospitalAssignments": contactInfo.assignedCompanies,

    }

    try {
      let response;
      // Check if adding a new Kollege or updating an existing one
      if (Object.keys(KollegeData).length === 0) {
        // Add new Kollege
        response = await axios.post('/api2/AddAndAssignKollege', submissionData);
      } else {
        // Update existing Kollege
        const updateSubmission = { ...KollegeData, ...submissionData };
        response = await axios.post('/api2/UpdateKollegen', updateSubmission);
      }
      toast.success("Profil erfolgreich aktualisiert!");

      // console.log('Success:', response.data);
      //  onSuccess(); // Handle success actions, e.g., showing a success message or refreshing data
      // toggle(); // Close the modal

    } catch (error) {
      // console.error('Error:', error.response ? error.response.data : error);
      toast.error(error.response.data.message)
      // Optionally show error feedback in the UI
    } finally {
      setLoading(false);
    }

  };


  return (
    // <Modal isOpen={isOpen} toggle={toggle} className="edit-contact-modal">
    <div className="content">
      <ToastContainer/>

      <Card>
        <CardHeader>
          {KollegeData.length === 0 ? <span>Teammitglied Hinzufügen</span> : <span>Profil Bearbeiten</span>}
        </CardHeader>
        <CardBody>
          <Form onSubmit={handleSubmit}>
            {/* <ModalHeader className="modal-header"> */}
            {/* <Button close onClick={toggle} className="close-button" /> */}
            {/* </ModalHeader> */}
            {/* <ModalBody className="modal-body"> */}
            <Row>
              <Col sm={12}>
                <FormGroup>
                  <Label for="email" className="form-label">E-mail</Label>
                  <Input type="text" name="email" id="email" className="form-control" value={contactInfo.email} onChange={handleInputChange} invalid={!!errors.email} />
                  {errors.email && <FormFeedback>{errors.email}</FormFeedback>}
                </FormGroup>
              </Col>

            </Row>


            <Row>
              <Col sm={12} md={6}>
                <FormGroup>
                  <Label for="firstName" className="form-label">Vorname</Label>
                  <Input type="text" name="firstName" id="firstName" className="form-control" value={contactInfo.firstName} onChange={handleInputChange} invalid={!!errors.firstName} />
                  {errors.firstName && <FormFeedback>{errors.firstName}</FormFeedback>}
                </FormGroup>
              </Col>
              <Col sm={12} md={6}>

                <FormGroup>
                  <Label for="lastName" className="form-label">Nachname</Label>
                  <Input type="text" name="lastName" id="lastName" className="form-control" value={contactInfo.lastName} onChange={handleInputChange} invalid={!!errors.lastName} />
                  {errors.lastName && <FormFeedback>{errors.lastName}</FormFeedback>}
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col sm={12} md={6}>
                <FormGroup>
                  <Label for="position" className="form-label">Position</Label>
                  <Input type="text" name="position" id="position" className="form-control" value={contactInfo.position} onChange={handleInputChange} invalid={!!errors.position} />
                  {errors.position && <FormFeedback>{errors.position}</FormFeedback>}
                </FormGroup>
              </Col>
              <Col sm={12} md={6}>
                <FormGroup>
                  <Label for="telephone" className="form-label">Telefon</Label>
                  <PhoneInput
                    country={'de'} // Default country
                    value={contactInfo.telephone}
                    onChange={(value) => setContactInfo(prevState => ({ ...prevState, telephone: value }))}
                    inputClass="form-control"
                    inputStyle={{ paddingLeft: '45px', width: '100%' }}
                  />
                  {errors.telephone && <div className="text-danger" style={{ fontSize: '13px', marginTop: '4px' }}>{errors.telephone}</div>}

                </FormGroup>
              </Col>
            </Row>
            <Row>

            </Row>
            <Row>

              <Col sm={12} md={6}>
                <FormGroup>
                  <Label className="form-label">Zugewiesene Unternehmen</Label>
                  <Select
                    isMulti
                    name="assignedCompanies"
                    options={[selectAllOption, ...allHospitals.map(hospital => ({ value: hospital.hosp_id, label: hospital.hosp_name }))]}
                    classNamePrefix="select"
                    value={contactInfo.assignedCompanies}
                    onChange={(selectedOptions) => handleMultiSelectChange(selectedOptions, { name: 'assignedCompanies' })}
                  />
                  {errors.assignedCompanies && <div className="text-danger" style={{ fontSize: '13px', marginTop: '4px' }}>{errors.assignedCompanies}</div>}

                </FormGroup>
              </Col>
              <Col sm={12} md={6}>
                <FormGroup>
                  <Label className="form-label">Zugewiesene Fachgebiete</Label>
                  <Select
                    isMulti
                    name="assignedFachgebiete"
                    options={[selectAllOption, ...skills]} // Assuming 'skills' contains objects with { value, label }
                    className="basic-multi-select"
                    classNamePrefix="select"
                    value={contactInfo.assignedFachgebiete}
                    onChange={(selectedOptions) => handleMultiSelectChange(selectedOptions, { name: 'assignedFachgebiete' })}
                  />
                  {errors.assignedFachgebiete && <div className="text-danger" style={{ fontSize: '13px', marginTop: '4px' }}>{errors.assignedFachgebiete}</div>}

                </FormGroup>
              </Col>
            </Row>
            {/* </ModalBody> */}
            {/* <ModalFooter className="modal-footer"> */}
            {/* <Button color="secondary" className="close-btn" onClick={toggle}>Abbrechen</Button> */}
            <Button color="primary" className="save-btn" type="submit" disabled={loading}>
              {loading ? <Spinner size="sm" color="light" /> : 'Speichern'}
            </Button>
            {/* </ModalFooter> */}
          </Form>


        </CardBody>
      </Card>


      {/* </Modal> */}
    </div>

  );

};

export default EditKollegeModel;
