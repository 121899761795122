import React from "react";
////import {Link} from "react-router-dom"
// reactstrap components
import {
  Card,
  CardBody,
  Row,
  Col,

} from "reactstrap";

//import data
import { candidate } from "variables/general.jsx"

//import JobCard from "building_block/job_card_list"
import TabsProfileCandidate from "building_block/sections_candidate_profile_tabs"
//import ButtonFollowWithNotif from "building_block/notifications/button_follow"


class CandidateProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openedCollapses: [""],
      buttonToRender: 4,
      horizontalTabs: "home",
    };
  }
  handleMoreClick = event => {
    this.setState({ 
      buttonToRender: (this.state.buttonToRender + 2)
    });
  };
  // with this function we create an array with the opened collapses
  // it is like a toggle function for all collapses from this page
  collapsesToggle = collapse => {
    let openedCollapses = this.state.openedCollapses;
    if (openedCollapses.includes(collapse)) {
      this.setState({
        openedCollapses: openedCollapses.filter(item => item !== collapse)
      });
    } else {
      openedCollapses.push(collapse);
      this.setState({
        openedCollapses: openedCollapses
      });
    }
  };
  render() {
    return (
      <div className="content">
        {candidate.map((user) => {
          return (
            <Row className="ml-auto mr-auto justify-content-center">
            <Col md="12">
              <Card className="card-user">
                <div className="image">
                  <img
                    alt="..."
                    src={require("assets/img/bg/damir-bosnjak.jpg")}
                  />
                </div>
                <CardBody>
                  <div className="author">
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      <img
                        alt="..."
                        className="avatar border-gray"
                        src={require("assets/img/mike.jpg")}
                      />
                      <h5 className="title"> {user.title} {user.firstName} {user.lastName} </h5>
                    </a>
                    <p className="description"> {user.jobType} </p>
                  </div>
                  <p className="description text-center">
                    {user.profileHighlight}
                  </p>
                  <p className="description text-center text-italic">
                    "{user.workPermit}"
                  </p>
                  <TabsProfileCandidate/>
                </CardBody>
                  {/* <CardFooter>
                  <hr />
                  <div className="button-container">
                    <Row>
                      <Col className="ml-auto" lg="3" md="6" xs="6">
                        <h5>
                          ID <br />
                          <small>{user.internalId}</small>
                        </h5>
                      </Col>
                      <Col className="ml-auto mr-auto" lg="4" md="6" xs="6">
                        <h5>
                        E-Mail <br />
                          <small> {user.email} </small>
                        </h5>
                      </Col>
                      <Col className="mr-auto" lg="3">
                        <h5>
                        Telefonnummer <br />
                          <small> {user.mobileNummer} </small>
                        </h5>
                      </Col>
                    </Row>
                  </div> <br/> <br/>
                  <Row className="justify-content-center">  
                    <Col className="text-center" md="4">
                  <div className="">
                  <Link to={"/admin-message/message"}>
                  <Button className="btn-round" color="primary" outline>
                    Nachricht
                  </Button>
                  </Link><br/> <br/>
                  <Button href="/sample.txt" className="btn-round" color="primary" outline download>
                    Lebenslauf drucken
                  </Button> 
                  <br/> <br/>
                  <UncontrolledDropdown>
                            <DropdownToggle
                              caret
                              className="btn-round"
                              color="info"
                              data-toggle="dropdown"
                              type="button"
                            >
                              Bearbeiten
                            </DropdownToggle>
                            <DropdownMenu>
                              {user.isBlocked ? 
                              <DropdownItem
                              href="#pablo"
                              onClick={e => e.preventDefault()}
                            >
                              Freigeben
                            </DropdownItem>
                                :
                                <DropdownItem
                                href="#pablo"
                                onClick={e => e.preventDefault()}
                              >
                                Sperren
                              </DropdownItem>
                                }
                                {user.handleJob === 0 ? 
                                <DropdownItem
                                href="#pablo"
                                onClick={e => e.preventDefault()}
                              >
                                Löschen
                              </DropdownItem>
                                : 
                                ""
                                }
                                {user.status.map((s) => {
                                  return (
                                    <>
                                    {s.ragistration !== "aktiv" ? 
                                      <DropdownItem
                                      href="#pablo"
                                      onClick={e => e.preventDefault()}
                                    >
                                      Registrierungs-Email
                                    </DropdownItem>
                                      :
                                      ""
                                      }
                                    </>
                                  )
                                })}
                                
                            </DropdownMenu>
                          </UncontrolledDropdown>
                  {/* <ButtonFollowWithNotif/> {" "} 
                  </div>
                    </Col>
                  </Row>
                </CardFooter> */}
              </Card>
              {/* <Card>
                <CardBody>
                  <div
                    aria-multiselectable={true}
                    className="card-collapse"
                    id="accordion"
                    role="tablist"
                  > */}
                    {/* <Card className="card-plain">
                      <CardHeader role="tab">
                        <h4>
                        <a
                          aria-expanded={this.state.openedCollapses.includes(
                            "collapseOne"
                          )}
                          href="#pablo"
                          data-parent="#accordion"
                          data-toggle="collapse"
                          onClick={() => this.collapsesToggle("collapseOne")}
                        >
                          Bewerbungen{" "}
                          <i className="nc-icon nc-minimal-down" />
                        </a>
                        </h4>
                      </CardHeader>
                      <Collapse
                        role="tabpanel"
                        isOpen={this.state.openedCollapses.includes(
                          "collapseOne"
                        )}
                      >
                        <CardBody>
                          <Row>
<Col md="12">
              <Card>
                <CardBody>
                  <div className="nav-tabs-navigation">
                    <div className="nav-tabs-wrapper">
                      <Nav id="tabs" role="tablist" tabs>
                        <NavItem>
                          <NavLink
                            aria-expanded={this.state.horizontalTabs === "home"}
                            data-toggle="tab"
                            href="#pablo"
                            role="tab"
                            className={
                              this.state.horizontalTabs === "home"
                                ? "active"
                                : ""
                            }
                            onClick={() =>
                              this.setState({ horizontalTabs: "home" })
                            }
                          >
                            Hello Match
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            aria-expanded={
                              this.state.horizontalTabs === "profile"
                            }
                            data-toggle="tab"
                            href="#pablo"
                            role="tab"
                            className={
                              this.state.horizontalTabs === "profile"
                                ? "active"
                                : ""
                            }
                            onClick={() =>
                              this.setState({ horizontalTabs: "profile" })
                            }
                          >
                            Direktbewerbung
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </div>
                  </div>
                  <TabContent
                    className="text-center"
                    id="my-tab-content"
                    activeTab={this.state.horizontalTabs}
                  >
                    <TabPane tabId="home" role="tabpanel" className="text-left">
                    <Row>
{jobsSample.slice(0, this.state.buttonToRender).map((js) => {
  return (
    <>
    <Col md="6">
    <JobCard item={js} />
    </Col>
    <br/>
    </>
  )
})}
<br/>
                      </Row>
                      <Row>
                              <Col className="text-center">
                              {jobsSample !== "" && jobsSample.length > 4 ? 
                                <Button
                        className="btn-round"
                        color="primary"
                        outline
                        size="md"
                        onClick={this.handleMoreClick}
                        >
                          <span>
                            Mehr
                          </span>
                        </Button> : ""} 
                              </Col>
                            </Row>
                    </TabPane>
                    <TabPane tabId="profile" role="tabpanel" className="text-left">
                      <Row>
{jobsSample.slice(0, this.state.buttonToRender).map((js) => {
  return (
    <>
    <Col md="6">
    <JobCard item={js} />
    </Col>
    <br/>
    </>
  )
})}
<br/>
                      </Row>
                    <Row>
                              <Col className="text-center">
                              {jobsSample !== "" && jobsSample.length > 4 ? 
                                <Button
                        className="btn-round"
                        color="primary"
                        outline
                        size="md"
                        onClick={this.handleMoreClick}
                        >
                          <span>
                            Mehr
                          </span>
                        </Button> : ""} 
                              </Col>
                            </Row>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
                          </Row>
                          <Row className="justify-content-center ml-auto mr-auto">
                            
                          </Row>
                        </CardBody>
                      </Collapse>
                    </Card> */}
                    {/* <Card className="card-plain">
                      <CardHeader role="tab">
                        <a
                          aria-expanded={this.state.openedCollapses.includes(
                            "collapseTwo"
                          )}
                          href="#pablo"
                          data-parent="#accordion"
                          data-toggle="collapse"
                          onClick={() => this.collapsesToggle("collapseTwo")}
                        >
                          Collapsible Group Item #2{" "}
                          <i className="nc-icon nc-minimal-down" />
                        </a>
                      </CardHeader>
                      <Collapse
                        role="tabpanel"
                        isOpen={this.state.openedCollapses.includes(
                          "collapseTwo"
                        )}
                      >
                        <CardBody>
                          Anim pariatur cliche reprehenderit, enim eiusmod high
                          life accusamus terry richardson ad squid. 3 wolf moon
                          officia aute, non cupidatat skateboard dolor brunch.
                          Food truck quinoa nesciunt laborum eiusmod. Brunch 3
                          wolf moon tempor, sunt aliqua put a bird on it squid
                          single-origin coffee nulla assumenda shoreditch et.
                          Nihil anim keffiyeh helvetica, craft beer labore wes
                          anderson cred nesciunt sapiente ea proident. Ad vegan
                          excepteur butcher vice lomo. Leggings occaecat craft
                          beer farm-to-table, raw denim aesthetic synth nesciunt
                          you probably haven't heard of them accusamus labore
                          sustainable VHS.
                        </CardBody>
                      </Collapse>
                    </Card>
                    <Card className="card-plain">
                      <CardHeader role="tab">
                        <a
                          aria-expanded={this.state.openedCollapses.includes(
                            "collapseThree"
                          )}
                          href="#pablo"
                          data-parent="#accordion"
                          data-toggle="collapse"
                          onClick={() => this.collapsesToggle("collapseThree")}
                        >
                          Collapsible Group Item #3{" "}
                          <i className="nc-icon nc-minimal-down" />
                        </a>
                      </CardHeader>
                      <Collapse
                        role="tabpanel"
                        isOpen={this.state.openedCollapses.includes(
                          "collapseThree"
                        )}
                      >
                        <CardBody>
                          Anim pariatur cliche reprehenderit, enim eiusmod high
                          life accusamus terry richardson ad squid. 3 wolf moon
                          officia aute, non cupidatat skateboard dolor brunch.
                          Food truck quinoa nesciunt laborum eiusmod. Brunch 3
                          wolf moon tempor, sunt aliqua put a bird on it squid
                          single-origin coffee nulla assumenda shoreditch et.
                          Nihil anim keffiyeh helvetica, craft beer labore wes
                          anderson cred nesciunt sapiente ea proident. Ad vegan
                          excepteur butcher vice lomo. Leggings occaecat craft
                          beer farm-to-table, raw denim aesthetic synth nesciunt
                          you probably haven't heard of them accusamus labore
                          sustainable VHS.
                        </CardBody>
                      </Collapse>
                    </Card> */}
                  {/* </div>
                </CardBody>
              </Card> */}
            </Col>
          </Row>
          )
           
        })}
      </div>
    );
  }
}

export default CandidateProfile;
