import React, { Component } from 'react'
import ReactQuill from "react-quill";
import {Link} from 'react-router-dom';
import "react-quill/dist/quill.snow.css";
import axios from "axios";
import Button from 'reactstrap/lib/Button';
import {
    Row,
    Col,
} from 'reactstrap'
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File

export default class ContractEditor extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: '',
        };
    }
    modules = {
        toolbar: [
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image', 'color'],
            ['clean']
        ],
    }

    formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image'
    ]
    submitHandler = e => {
        axios
            .post('/api2/EditorContractCompanyNew', this.state)
            .then(response => {
                //   console.log(response)
                //   this.setState({adduser_err:undefined})
                //   var adduser_succ = response.data.msg;
                //   this.setState({ adduser_succ })
                this.intervalID = setTimeout(this.componentDidMount.bind(this), 100);
            })
            .catch(error => {
                console.log(error.response);
                //   this.setState({adduser_succ:undefined})
                //   var adduser_err = error.response.data.msg
                //   this.setState({ adduser_err })
            })
        //}

    }


    componentDidMount() {

        axios
            .post('/api2/GetEditorContractCompany', this.state)
            .then(response => {
                //console.log(response)
                const value = response.data.result[0].EditorDesc;
                const Contract_Company_id = response.data.result[0].Contract_Company_id
                //console.log(AGB_Company_id)
                this.setState({Contract_Company_id})
                this.setState({ value })
                //this.setState({adduser_err:undefined})
                //   var adduser_succ = response.data.msg;
                //   this.setState({ adduser_succ })
            })
            .catch(error => {
                console.log(error.response);
                //   this.setState({adduser_succ:undefined})
                //   var adduser_err = error.response.data.msg
                //   this.setState({ adduser_err })
            })

 
    }

    componentWillMount() {
        const user_id = localStorage.getItem("user_id")
        //  console.log(user_id)
        this.setState({ user_id })
    }

    onChange = (value) => {
     //   console.log(value)
        this.setState({ value: value });
    }


    render() {
        return (
            <div className="content">
                {/* <div style={{ background: 'white' }}>
                    <Row>
                        <Col md="8">
                            <h4>Vertrag Editor Unternehmen</h4>
                        </Col>
                        <Col md="4" >
                            <Link to="/admin/AGBEditor_New_Version_Unternehmen">
                            <Button
                            style={{marginTop:'25px'}}
                                color="primary"
                            >

                                Add New (Unternehmen) </Button>
                            </Link>
                           
                        </Col>
                    </Row>

                    <ReactQuill
                        className="editor"
                        theme="snow"
                        value={this.state.value}
                        onChange={this.onChange}
                        modules={this.modules}
                        formats={this.formats}
                    />
                    <div style={{ marginBottom: '70px' }}>
                        <Button
                            style={{ float: 'right' }}
                            color="primary"
                            onClick={this.submitHandler}
                        >

                            Save </Button>
                    </div>

                </div>

                <div style={{ background: 'white' }}>
   
                    <Row>
                        <Col md="8">
                        <h4 style={{ textAlign: "center" }}>Vertrag Editor Kandidaten</h4>
                  
                        </Col>
                        <Col md="4" >
                            <Link to="/admin/AGBEditor_New_Version_Kandidaten">
                            <Button
                            style={{marginTop:'25px'}}
                                color="primary"
                            >

                                Add New (Kandidaten) </Button>
                            </Link>
                           
                        </Col>
                    </Row>

                    <ReactQuill
                        className="editor"
                        theme="snow"
                        value={this.state.value1}
                        onChange={this.onChange1}
                        modules={this.modules}
                        formats={this.formats}
                    />
                    <Button
                        style={{ float: 'right' }}
                        color="primary"
                        onClick={this.submitHandler1}
                    >

                        Save </Button>
                        
                </div>
               */}
                 <Row>
                        <Col md="8">
                            <h4>Vertrag Unternehmen (New Version)</h4>
                        </Col>
                     
                    </Row>
                <br></br>  <br></br>
                <div>
                <SunEditor 
                
               setContents={this.state.value}
                onChange={this.onChange}
                // onClick={this.submitHandler1}
                lang="en" 
                width="100%" 
                height="100%" 
         
                setOptions={{
                    buttonList: [
                        ['undo', 'redo'],
                        ['font', 'fontSize', 'formatBlock'],
                        ['paragraphStyle', 'blockquote'],
                        ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                        ['fontColor', 'hiliteColor', 'textStyle'],
                        ['removeFormat'],
                        '/', // Line break
                        ['outdent', 'indent'],
                        ['align', 'horizontalRule', 'list', 'lineHeight'],
                        ['table', 'link', /** 'image', 'video', 'audio' ,'math' */], // You must add the 'katex' library at options to use the 'math' plugin.
                        /** ['imageGallery'] */ // You must add the "imageGalleryUrl".
                        ['fullScreen', 'showBlocks', 'codeView'],
                        ['preview', 'print'],
                        ['save', 'template'],
                        /** ['dir', 'dir_ltr', 'dir_rtl'] */ // "dir": Toggle text direction, "dir_ltr": Right to Left, "dir_rtl": Left to Right
                    ]
                }}
                />
                 <Button
                        style={{ float: 'right' }}
                        color="primary"
                        onClick={this.submitHandler}
                    >

                        Save </Button>
                </div>


            </div>
        )
    }
}


